<template>
  <div   class="pageCon" :class="{ blured: $store.state.blured }">
    <div class="notfound text-center position-relative m-auto">
       <img src="../assets/imgs/not-found2.svg" class="notfound__img d-block w-100 "/>

       <router-link :to="$store.state.userObject.isAppAdmin ? '/cPanel' : '/Dashboard'" class='btn notfound__btn mt-2'><i class='fas fa-angle-left'></i> BACK TO Dashboard</router-link>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .notfound {

    padding:40px;
      width:600px;
      @include query(maxM) {
        width:100%;
      }
    &__btn {
      background:rgb(228,4,100);
      color:color(white);
      @include radius(40px);
      font-size:14px;
      &:hover {
        background: color(gray);
        color:color(white)

      }
    }
  }
</style>
<script>
export default {
  name: "notfound",
};
</script>
