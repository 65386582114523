<template>
  <div class="settings pageCon" :class="{ blured: $store.state.blured }">
    <h1 class="text-center text-uppercase settings__h1">account management</h1>
    <p class="text-center text-uppercase">customize your experience</p>

    <div class="settings__wrap mt-5">
      <div class="row">
        <div class="col-md-4 text-center mb-5 mb-md-0">
          <div class="d-inline-block">
            <img class="setting__img rounded-circle" :src="img" />
            <h2 class="settings__h2 my-3 ml-2 font-weight-bold">
              {{ name }}
            </h2>
            <div>
              <input
                :disabled="spinner"
                @change="changePic()"
                ref="upImg"
                type="file"
                hidden
                id="upImg"
              />
              <label class="settings__label mb-0" for="upImg"
                >change profile picture
                <div
                  class="spinner-border text-secondary d-inline-block"
                  role="status"
                  style="width: 1rem; height: 1rem"
                  v-if="spinner"
                >
                  <span class="sr-only"></span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="custom-alert" v-if="alert">
            Has Been saved <i class="fas fa-check text-success"></i>
          </div>
          <div class="form-group">
            <label class="d-flex justify-content-between">
              <p>Email</p>
            </label>
            <input
              type="text"
              :value="email"
              class="form-control settings__input"
              disabled
            />
          </div>
          <div class="form-group">
            <label>Reset password</label>
            <input
              type="password"
              class="form-control settings__input"
              :class="{ 'settings__input--valid': oldValid }"
              placeholder="current password"
              v-model="change.oldPassword"
            />
            <span v-if="oldValid" class="text-danger">{{ oldText }}</span>
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control settings__input"
              placeholder="new password"
              v-model="change.newPassword"
              @focus="passVad = true"
              :class="{ 'settings__input--valid': newValid }"
            />
            <div class="settings__passValid mt-1" v-if="passVad">
              <ul class="list-unstyled mt-2">
                <li class="mb-1">
                  <span
                    :class="[
                      /(?=.*[a-z])/.test(change.newPassword)
                        ? 'text-success'
                        : 'text-danger',
                    ]"
                  >
                    <span v-show="/(?=.*[a-z])/.test(change.newPassword)"
                      ><i class="fas fa-check-circle"></i
                    ></span>
                    <span v-show="!/(?=.*[a-z])/.test(change.newPassword)"
                      ><i class="fas fa-times-circle"></i
                    ></span>
                    1 small character at least
                  </span>
                </li>

                <li class="mb-1">
                  <span
                    :class="[
                      /(?=.*[A-Z])/.test(change.newPassword)
                        ? 'text-success'
                        : 'text-danger',
                    ]"
                  >
                    <span v-show="/(?=.*[A-Z])/.test(change.newPassword)"
                      ><i class="fas fa-check-circle"></i
                    ></span>
                    <span v-show="!/(?=.*[A-Z])/.test(change.newPassword)"
                      ><i class="fas fa-times-circle"></i
                    ></span>
                    1 capital character at least
                  </span>
                </li>

                <li class="mb-1">
                  <span
                    :class="[
                      /(?=.*\d)/.test(change.newPassword)
                        ? 'text-success'
                        : 'text-danger',
                    ]"
                  >
                    <span v-show="/(?=.*\d)/.test(change.newPassword)"
                      ><i class="fas fa-check-circle"></i
                    ></span>
                    <span v-show="!/(?=.*\d)/.test(change.newPassword)"
                      ><i class="fas fa-times-circle"></i
                    ></span>
                    one number at least
                  </span>
                </li>

                <li class="mb-1">
                  <span
                    :class="[
                      /\W/g.test(change.newPassword)
                        ? 'text-success'
                        : 'text-danger',
                    ]"
                  >
                    <span v-show="/\W/g.test(change.newPassword)"
                      ><i class="fas fa-check-circle"></i
                    ></span>
                    <span v-show="!/\W/g.test(change.newPassword)"
                      ><i class="fas fa-times-circle"></i
                    ></span>
                    one Symbol
                  </span>
                </li>

                <li class="mb-1">
                  <span
                    :class="[
                      regPass.test(change.newPassword)
                        ? 'text-success'
                        : 'text-danger',
                    ]"
                  >
                    <span v-show="regPass.test(change.newPassword)"
                      ><i class="fas fa-check-circle"></i
                    ></span>
                    <span v-show="!regPass.test(change.newPassword)"
                      ><i class="fas fa-times-circle"></i
                    ></span>
                    should contain at least 8 from the mentioned Characters
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control settings__input"
              placeholder="confirm new password"
              v-model="change.confirmNewPassword"
              :class="{ 'settings__input--valid': conValid }"
            />
            <span v-if="conValid" class="text-danger"
              >Password does not match</span
            >
          </div>
          <div class="form-group m-0 text-right">
            <button :disabled="spinnerPass" class="btn btn-p settings__btn" @click="changePassword()">
              <div
                class="spinner-border text-secondary d-inline-block"
                role="status"
                style="width: 1rem; height: 1rem"
                v-if="spinnerPass"
              >
                <span class="sr-only">Loading...</span>
              </div>
              Save Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.settings {
  &__btn:disabled{
      background-color:#333
  }
  &__passValid {
    font-size: 13px;
  }
  &__h1 {
    font-size: 25px;
  }
  &__h2 {
    font-size: 20px;
    white-space: pre-wrap;
  }
  &__wrap {
    background: color(white);
    padding: 40px;
    @include shadow(cardShadow);
    @include radius(10px);
    @include query(maxS) {
      padding: 20px;
    }
  }
  &__label {
    color: color(primary);
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 22px;
  }
  input[type="file"] + label {
    cursor: pointer;
  }
  input[type="file"]:disabled + label {
    color: color(gray10) !important;
    cursor: no-drop;
  }
  &__input {
    background: color(body);
    &--valid {
      border: 1px solid color(primary);
    }
  }
}
</style>
<script>
export default {
  name: "Settings",
  data() {
    return {
      passVad: false,
      regPass:
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
      img: this.$store.state.userObject.userImage,
      email: this.$store.state.userObject.email,
      alert: false,
      name: this.$store.state.userObject.displayName,
      oldValid: false,
      newValid: false,
      conValid: false,
      oldText: "",
      spinner: false,
      spinnerPass: false,
      change: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },
  methods: {
    changePassword() {
      if (this.change.oldPassword == "") {
        this.oldValid = true;
        this.oldText = "Can't be empty";
      } else {
        this.oldValid = false;
        this.oldText = "";
      }
      if (!this.regPass.test(this.change.newPassword)) {
        this.newValid = true;
      } else {
        this.newValid = false;
        if (this.change.newPassword != this.change.confirmNewPassword) {
          this.conValid = true;
        } else {
          this.conValid = false;
        }
      }
      if (!this.oldValid && !this.newValid && !this.conValid) {
        this.spinnerPass = true
        this.axios
            .post("api/Accounts/ChangePassword", this.change, {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          })
          .then((res) => {
            if (res.status == 202) {
              this.alert = true;
              this.change.oldPassword = "";
              this.change.newPassword = "";
              this.change.confirmNewPassword = "";
              this.passVad = false;
               this.spinnerPass = false;
              setTimeout(() => {
                this.alert = false;
              }, 2000);
            }
          })
          .catch((err) => {
            if (err) {
              this.oldText = "The password is incorrect";
              this.oldValid = true;
            }
          });
      }
    },
    changePic() {
      this.spinner = true;
      let formData = new FormData();
      formData.append("userID", this.$store.state.userObject.userID);
      formData.append("image", this.$refs["upImg"].files[0]);
      this.axios
        .post("/api/Accounts/ChangeImage", formData, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
          this.$store.state.userObject.userImage = res.data.data;
          this.img = res.data.data;
          localStorage.setItem(
            "userObject",
            JSON.stringify(this.$store.state.userObject)
          );
          this.spinner = false;
        });
    },
  },
};
</script>
