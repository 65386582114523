<template>
  <div class="about pageCon" :class="{ blured: $store.state.blured }">
    <div class="about__wrapper     animate__animated animate__fadeIn">
      <h2 class="font-weight-bold text-uppercase mb-4">About us</h2>
      <ul class="list-unstyled mb-0">
        <li>
         * SACO Shipping GMBH opened its branch in Egypt since February 2017 with
          the aim to serve all the Freight Forwarders with a global perspective
          by providing them with a higher standard of shipping and logistics
          services
        </li>
        <li>
          * SACO Shipping EGYPT offers a portfolio of more than 200 direct
          services complemented by another 480 further services in transshipment
          every week.
        </li>
        <li>
         * As a neutral NVOCC service provider with a worldwide network of
          agents, SACO Shipping unites countries and markets, meeting your
          requirements across all key methods of transportation.
        </li>
        <li>
       *   SACO Shipping office premises are equipped to the most modern
          technical standards and software especially designed for SACO ensures
          a speedy & accurate handling of your cargo.
        </li>
        <li>
        *  SACO's excellent reputation is based on its qualified and highly
          motivated staff and is enhanced by its progressive and innovative
          information systems.
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.about {
  h2 {
    background: color(primary);
    padding: 10px 40px;
    color: color(white);
  }
  &__wrapper {
    @include shadow(cardShadow);
    background: color(white);
    @include radius(10px);
    overflow: hidden;
    ul {
      padding: 40px;
      @include query(maxS) {
        padding: 20px;
      }
      li:not(:last-of-type) {
        margin-bottom: 34px;
        word-break: keep-all;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
<script>
export default {
  name: "About",
};
</script>
