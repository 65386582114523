<template>
  <section class="popup">
    <main class="popup__main">
      <p class="popup__main__title">title</p>
      <p class="popup__main__body">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </p>
      <span class="popup__main__badge"></span>
    </main>
    <footer class="popup__footer">
      <a href="#" class="popup__footer__btn">Show</a>
      <a href="#" class="popup__footer__btn">Close</a>
    </footer>
  </section>
</template>

<style lang="scss" scoped>
.popup {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 30rem;
  &__main {
    padding: 15px;
    text-transform: uppercase;
    font-weight: bold;
    &__badge {
      position: absolute;
      top: -9px;
      right: -6px;
      padding: 8px 8px;
      background-color: #e40464;
      border-radius: 50%;
    }
    &__title {
      color: #aaa;
      margin: 5px 0px;
      padding: 0;
    }

    &__body {
      margin: 3px 0px 5px 0px;
      padding: 0;
    }
  }
  &__footer {
    padding: 15px;
    background-color: #eee;
    display: flex;
    gap: 3rem;
    &__btn {
      text-decoration: none;
      color: blue;
      font-weight: bold;
    }
  }
}
</style>

<script>
export default {
  name: "notificationPopUP",
  props: {
    title: "",
    body: "",
    urls: [],
    rise: false,
  },
  data() {
    return {};
  },
  methods: {
    riseNotification() {},
    markRead() {},
  },
  watch: {
    rise() {},
  },
};
</script>