<template>
  <div
    class="booking overflow-hidden pageCon"
    :class="{ blured: $store.state.blured }"
  >
    <h1 class="booking__h1 text-uppercase text-center">lcl booking</h1>
    <p class="booking__p text-uppercase text-center">
      fill out this form we will contact you asap
    </p>

    <reqSent @back="back()" v-if="reqSent"></reqSent>

    <div class="wrap mt-5" v-if="!reqSent">
        <section class="booking__body form-row">

            <!--Guest Email -->
            <div class="form-group mb-4 col-12"
                 v-if="!this.$store.getters.loggedin">
                <input v-model.lazy="obj.requesterEmail"
                       type="text"
                       class="booking__field form-control"
                       placeholder="Email"
                       @blur="emptyVal($event)" />
            </div>


            <!--label-->
            <label class="booking__label col-12 mb-4">shipments details</label>
            <!--col import -->
            <div class="form-group mb-4 col-md-6">
                <select @blur="emptyVal($event)" v-model.lazy="obj.import" class="custom-select booking__field">
                    <option value="import">IMPORT</option>
                    <option value="export">EXPORT</option>
                </select>
            </div>
            <!--col DELIVERY TERMS -->
            <div class="form-group mb-4 col-md-6">
                <select v-model="obj.deliveryTerms"
                        class="custom-select quote__field"
                        @blur="emptyVal($event)">
                    <option value="" disabled selected>DELIVERY TERMS</option>
                    <option value="FOB">FOB</option>
                    <option value="CIF">CIF</option>
                    <option value="Ex-Works">Ex Works</option>
                    <option value="Door-To-Door">Door to Door</option>
                    <option value="DAP">DAP</option>
                </select>
            </div>

            <div class="form-group mb-4 col-md-6">
                <input @blur="emptyVal($event)"
                       list="ports"
                       id="browser"
                       placeholder="POL"
                       class="form-control quote__field"
                       v-model="polPortName" />
                <datalist id="ports">
                    <option v-for="(a, i) in ports"
                            :key="i"
                            :value="a.portTitle"></option>
                </datalist>
            </div>

            <div class="form-group mb-4 col-md-6">
                <input @blur="emptyVal($event)"
                       list="ports"
                       id="browser"
                       placeholder="POD"
                       class="form-control quote__field"
                       v-model="podPortName" />
                <datalist id="ports">
                    <option v-for="(a, i) in ports"
                            :key="i"
                            :value="a.portTitle"></option>
                </datalist>
            </div>

            <div class="form-group mb-4 col-12"  v-if="obj.deliveryTerms === 'Ex-Works'">
                <input v-model="obj.pickupAddrees"
                       type="text"
                       class="quote__field form-control"
                       placeholder="pick-up address"
                       @blur="emptyVal($event)" />
            </div>

            <div class="form-group mb-4 col-12">
                <input v-model="obj.emailSubject"
                       type="text"
                       class="form-control"
                       placeholder="Email Subject"
                       @blur="emptyVal($event)" />
            </div>
            <!--col label-->
            <label class="booking__label col-12 my-3">cargo details</label>

            <!--col cargo WEIGHT-->
            <div class="form-group mb-4 col-md-6">
                <input type="number"
                       class="booking__field form-control"
                       placeholder="WEIGHT"
                       v-model.number.lazy="obj.weight"
                       @blur="emptyVal($event)" />
            </div>

            <!--col dimensions-->
            <div class="form-group mb-4 col-md-6">
                <input type="number"
                       class="booking__field form-control"
                       placeholder="cbm dimensions"
                       @blur="emptyVal($event)"
                       v-model.number.lazy="obj.cbmDimensions" />
            </div>

            <!--col HEIGHT-->
            <div class="form-group mb-4 col-4">
                <div class="input-group">
                    <label class="input-group-prepend d-flex align-items-center pt-1 mr-3">HEIGHT</label>
                    <input type="text"
                           class=" form-control"
                           placeholder="HEIGHT"
                           v-model.number.lazy="obj.height"
                           @blur="emptyVal($event)" />
                </div>
            </div>
            <!--col length-->
            <div class="form-group mb-4 col-4">
                <div class="input-group">
                    <label class="input-group-prepend d-flex align-items-center pt-1 mr-3">length</label>
                    <input type="number"
                           class=" form-control"
                           placeholder="length"
                           v-model.number.lazy="obj.length"
                           @blur="emptyVal($event)" />
                </div>
            </div>

            <!--col width -->
            <div class="form-group mb-4 col-4">
                <div class="input-group">
                    <label class="input-group-prepend d-flex align-items-center pt-1 mr-3">width</label>
                    <input type="text"
                           class=" form-control"
                           placeholder="width"
                           v-model.number.lazy="obj.width"
                           @blur="emptyVal($event)" />
                </div>
            </div>

            <!--col COMMODITY-->
            <label class="booking__label col-12 my-3">COMMODITY</label>

            <!--col cargo COMMODITY-->
            <div class="form-group mb-0 col-12">
                <input type="text"
                       class="booking__field form-control"
                       placeholder="COMMODITY"
                       v-model.lazy="obj.commodity"
                       @blur="emptyVal($event)" />
            </div>

            <!--col checkbox -->
            <div class="form-group col-12 mt-4 mb-1">
                <div class="custom-control custom-checkbox">
                    <input v-model="obj.dangerousCargo"
                           type="checkbox"
                           class="custom-control-input"
                           id="lclCheck" />
                    <label class="custom-control-label pl-4 pt-2" for="lclCheck">Dangerous cargo</label>
                </div>
            </div>
            <!--col checkbox -->
            <div class="form-group col-12 mb-3">
                <div class="custom-control custom-checkbox">
                    <input v-model="obj.temperatureControlled"
                           type="checkbox"
                           class="custom-control-input"
                           id="temp" />
                    <label class="custom-control-label pl-4 pt-2" for="temp">temperature Controlled</label>
                </div>
            </div>

            <div class="form-group col-12 text-center text-md-right">
                <button :disabled="spinner"
                        @click="sendBooking()"
                        class="btn btn-p booking__btn"
                        @blur="emptyVal($event)">
                    <div class="spinner-border text-secondary d-inline-block"
                         role="status"
                         style="width: 1rem; height: 1rem"
                         v-if="spinner">
                        <span class="sr-only">Loading...</span>
                    </div>
                    Request your booking
                </button>
            </div>
        </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.booking {
  &__h1 {
    font-size: 26px;
    font-weight: bold;
  }

  .wrap {
    border: 15px solid color(white);
    @include query(maxS) {
      border-width: 8px;
    }
  }
  &__header {
    padding: 10px;
    background: color(white);
    @include query(maxS) {
      padding: 0;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    @include query(maxL) {
      font-size: 17px;
    }
  }
  &__hint {
    text-transform: uppercase;
    color: color(gray10);
    font-weight: bold;
    @include query(maxL) {
      font-size: 12px;
    }
  }
  &__label {
    font-size: 18px;
    color: color(gray8);
  }
  &__body {
    padding: 30px 30px 0;
    @include query(maxM) {
      padding: 20px 20px 0;
    }
    @include query(maxS) {
      padding: 15px 15px 0;
    }
  }
  &__field {
    color: color(gray10);
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid color(gray3);
    &::placeholder {
      font-size: 17px;
    }
    @include query(maxM) {
      width: 100% !important;
    }
    &--require {
      border: 1px solid color(primary) !important;
    }
  }
  &__btn {
    i,
    svg {
      color: color(green) !important;
    }
  }
  &__btn:disabled {
    background: color(gray10) !important;
  }
}

.custom-control-label {
  color: color(gray8);
  font-weight: bold;
  padding-top: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png");
}
</style>
<script>
    import reqSent from "../../components/clients/Request_sent.vue";
    export default {
        name: "Booking",
        data() {
            return {
                reqSent: false,
                spinner: false,
                ports: [],
                obj: {
                    requesterEmail: this.$store.getters.loggedin
                        ? this.$store.state.userObject.email
                        : "",

                    requesterUsername: this.$store.getters.loggedin
                        ? this.$store.state.userObject.username
                        : "guest",

                    requesterPhone: this.$store.getters.loggedin
                        ? this.$store.state.userObject.landLine == null
                            ? "000000000"
                            : this.$store.state.userObject.landLine
                        : "000000000",

                    requesterLandline: this.$store.getters.loggedin
                        ? this.$store.state.userObject.landLine == null
                            ? "000000000"
                            : this.$store.state.userObject.landLine
                        : "000000000",

                    import: "import",
                    deliveryTerms: "",
                    pickupAddrees: '',
                    podPortName: this.podPortName,
                    polPortName: this.polPortName,
                    emailSubject:'',
                    weight: "",
                    height: "",
                    length: "",
                    width: "",
                    cbmDimensions: "",
                    commodity: "",

                    dangerousCargo: false,

                    temperatureControlled: false,
                },
                podPortName: "",
                polPortName: "",
            };
        },
        methods: {
            compareVal(ev, type) {
                let userPort = "";
                for (let i = 0; i < this.ports.length; i++) {
                    if (this.ports[i].portTitle == ev.currentTarget.value) {
                        userPort = this.ports[i].portID;
                    }
                }
                if (type == "pol") {
                    this.obj.polPortName = ev.currentTarget.value;
                    this.obj.polPortID = userPort;
                } else {
                    this.obj.podPortName = ev.currentTarget.value;
                    this.obj.PodPortID = userPort;
                }
            },
            emptyVal(ev) {
                if (ev.currentTarget.value == "") {
                    ev.currentTarget.classList.add("booking__field--require");
                } else {
                    ev.currentTarget.classList.remove("booking__field--require");
                }
            },
            sendBooking() {
                let obj = this.obj;
                obj.podPortName = this.podPortName;
                obj.polPortName = this.polPortName;
                for (
                    let i = 0;
                    i < document.getElementsByClassName("booking__field").length;
                    i++
                ) {
                    if (document.getElementsByClassName("booking__field")[i].value == "") {
                        document
                            .getElementsByClassName("booking__field")
                        [i].classList.add("booking__field--require");
                    } else {
                        document
                            .getElementsByClassName("booking__field")
                        [i].classList.remove("booking__field--require");
                    }
                }

                if (
                    document.getElementsByClassName("booking__field--require").length == 0
                ) {
                    this.spinner = true;
                    this.axios.post("/api/Quotations/BookingLCL", obj).then((res) => {
                        this.reqSent = true;
                        this.spinner = false;
                    });
                }
            },
            back() {
                this.reqSent = false;
                Object.entries(this.obj).forEach((el, i) => {
                    if (i > 3 && i < 16) {
                        this.obj[el[0]] = "";
                    }
                });
            },
            getPorts(port) {
                this.axios.get(`api/appadmin/settings/GetPorts?portname=${port}`)
                    .then(res => {
                        this.ports = res.data;
                    })
                    .catch(err => {

                    });
            }
        },
        components: {
            reqSent,
        },
        watch: {
            polPortName(val) {
                this.getPorts(val);
            },
            podPortName(val) {
                this.getPorts(val);
            }
        },
    };
</script>
