<template>
  <div class="track pageCon" :class="{ blured: $store.state.blured }">
    <div class="track__wrapper position-relative">
      <div v-if="loading" class="track__loading position-absolute text-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <iframe
        allowfullscreen="true"
        loading="eager"
        title="iframe loading"
        border="0"
        src="https://www.wwalliance.com/tracking/track-shipment"
        width="400"
      ></iframe>
    </div>
  </div>
</template>

<style lang="scss">
.track {
  &__wrapper {
    background: color(white);
    padding: 20px;
    @include radius(10px);
    @include shadow(cardShadow);
    overflow: hidden;
  }
  &__loading {
    line-height: 550px;
  }
  iframe {
    border: none;
    width: 100%;
    height: calc(100vh - 80px);
  }
  &__loading {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: color(white);
  }
}
</style>
<script>
export default {
  name: "trackTrace",
  data() {
    return {
      loading: true,
    };
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
};
</script>
