<template>
  <div class="docs pageCon" :class="{ blured: $store.state.blured }">
    <div class="docs__wrapper">
      <!-- <p class="docs__id mb-3">ID 8789321564798</p> -->

      <div class="custom-alert mt-3" v-if="alert">
        Your request has been sent <i class="fas fa-check text-succes"></i>
      </div>
      <form class="mt-3">
        <div class="form-group">
          <label class="docs__label pl-0 d-block"
            >shipment B/L <span class="text-danger">*</span></label
          >
          <span class="text-danger" v-if="shipBl">Can't be empty</span>
          <input
            type="text"
            class="docs__field form-control"
            :class="{ 'docs__field--danger': shipBl }"
            v-model="obj.shipmentBL"
          />
        </div>
        <div class="form-group" v-if="!$store.getters.loggedin">
          <label class="d-block docs__label pl-0">consignee name</label>
          <span class="text-danger" v-if="conName">Can't be empty</span>
          <input
            :class="{ 'docs__field--danger': conName }"
            v-model="obj.username"
            type="text"
            class="form-control docs__field"
          />
        </div>
        <label class="docs__label pl-0">Document needed</label>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="face1"
              class="custom-control-input"
              @change="docName('Thc Invoice', $event)"
            /><label for="face1" class="custom-control-label pl-4 pt-2"
              >Thc Invoice</label
            >
          </div>
          <span class="text-danger" v-if="file">Choose one file at least</span>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="face2"
              class="custom-control-input"
              @change="docName('Original House Bill of Lading', $event)"
            /><label for="face2" class="custom-control-label pl-4 pt-2"
              >Original House Bill of Lading</label
            >
          </div>
          <span class="text-danger" v-if="file">Choose one file at least</span>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="face3"
              class="custom-control-input"
              @change="docName('Ocean Freight', $event)"
            /><label for="face3" class="custom-control-label pl-4 pt-2"
              >Ocean Freight</label
            >
          </div>
          <span class="text-danger" v-if="file">Choose one file at least</span>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="face4"
              class="custom-control-input"
              @change="docName('Storage Invoice', $event)"
            /><label for="face4" class="custom-control-label pl-4 pt-2"
              >Storage Invoice</label
            >
          </div>
          <span class="text-danger" v-if="file">Choose one file at least</span>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="face5"
              class="custom-control-input"
              @change="
                docName(
                  'Euro one, Commercial Invoice, Packing List, Legal Docs',
                  $event
                )
              "
            /><label for="face5" class="custom-control-label pl-4 pt-2"
              >Euro one, Commercial Invoice, Packing List, Legal Docs</label
            >
          </div>
          <span class="text-danger" v-if="file">Choose one file at least</span>
        </div>

        <div class="form-group">
          <label class="docs__label pl-0 d-block">Date of visit</label>
          <span class="text-danger" v-if="date">Please Select Date</span>
          <input
            type="date"
            class="docs__input form-control"
            :class="{ 'docs__input--danger': date }"
            v-model="obj.appointmentDate"
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control docs__area"
            rows="5"
            placeholder="NOTE"
            v-model="obj.notes"
          ></textarea>
        </div>
        <div class="form-group text-right mb-0">
          <button
            :disabled="spinner"
            class="docs__btn btn btn-p"
            @click.prevent="sendDoc()"
          >
            <div
              class="spinner-border text-secondary d-inline-block"
              role="status"
              style="width: 1rem; height: 1rem"
              v-if="spinner"
            >
              <span class="sr-only">Loading...</span>
            </div>
            REQUEST
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.docs {
  &__wrapper {
    background: color(white);
    @include radius(10px);
    padding: 40px;
    @include shadow(cardShadow);
    @include query(maxS) {
      padding: 20px;
    }
  }
  &__id {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
  }

  &__bl {
    color: color(gray10);
    font-size: 29px;
    span {
      color: color(primary);
    }
  }
  &__btn:disabled {
    background: #333;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    background-color: color(body) !important;
    border-radius: 6px !important;
  }
  &__input {
    width: 250px;

    font-size: 22px;
    font-weight: bold;
    @include radius(5px!important);
    border: 1px solid color(body) !important;
    @include query(maxS) {
      width: 100%;
    }
    &--danger {
      border: 1px solid color(primary) !important;
    }
    &--ship {
      font-size: 16px;
      color: color(text);
    }
  }
  &__field {
    background: color(body);
    &--danger {
      border: 1px solid color(primary);
    }
  }
  &__area {
    background: color(body);
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png");
}
</style>
<script>
export default {
  name: "DocumentRequest",
  data() {
    return {
      file: false,
      date: false,
      spinner: false,
      alert: false,
      shipBl: false,
      conName: false,
      regMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      obj: {
        username: this.$store.getters.loggedin
          ? this.$store.state.userObject.username
          : "",
        email: this.$store.getters.loggedin
          ? this.$store.state.userObject.email
          : "guest@mail.com",
        shipmentBL: "",
        appointmentDate: "",
        documents: [],
        notes: "",
      },
    };
  },
  methods: {
    docName(ev, el) {
      if (el.currentTarget.checked) {
        this.obj.documents.push(ev);
      } else {
        this.obj.documents.splice(this.obj.documents.indexOf(ev), 1);
      }
    },
    sendDoc() {
      this.obj.documents.length == 0 ? (this.file = true) : (this.file = false);
      this.obj.appointmentDate == "" ? (this.date = true) : (this.date = false);
      this.obj.shipmentBL == "" ? (this.shipBl = true) : (this.shipBl = false);
      this.obj.username == "" ? (this.conName = true) : (this.conName = false);
      if (
        this.obj.documents.length != 0 &&
        this.obj.appointmentDate != "" &&
        this.obj.username != "" &&
        this.obj.shipmentBL != ""
      ) {
        this.spinner = true;
        this.axios.post("api/Documents/SubmitRequest", this.obj).then((res) => {
          this.spinner = false;
          this.alert = true;
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        });
      }
    },
  },
};
</script>
