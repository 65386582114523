<template>
  <div
    class="aside position-fixed"
    :class="{ 'aside--open': $store.state.asideOpen }"
  >
    <!--aside header  with COMPONEY LOGO-->
    <header style="padding-bottom: 19px" class="aside__header text-center">
      <router-link to="/">
        <img src="../assets/imgs/logo.svg" width="116" />
      </router-link>
    </header>

    <!--ASIDE USERS with profile picture & Name -->
    <div class="aside__user p-3 text-center">
      <!--profile picture -->
      <router-link v-if="$store.getters.loggedin" to="Account_management">
        <img
          class="rounded-circle"
          width="60"
          :src="
            this.$store.getters.loggedin
              ? this.$store.state.userObject.userImage
              : ''
          "
        />
      </router-link>
      <img
        v-if="!$store.getters.loggedin"
        width="60"
        src="../assets/imgs/guest.png"
      />

      <!--name-->
      <p class="aside__name mt-2" v-if="$store.getters.loggedin">
        {{ name }}
      </p>
      <p class="aside__name mt-2" v-if="!$store.getters.loggedin">Guest</p>
    </div>

    <!-- ==========  start admin navgations ==========  -->
    <ul
      class="list-unstyled mt-3"
      v-if="$store.getters.loggedin && $store.getters.roles"
    >
      <li class="aside__item">
        <router-link to="/Cpanel" exact class="aside__link">
          <img src="../assets/imgs/menuIcons/Home.png" width="28" />
          Dashboard
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Pending_accounts" class="aside__link">
          <img src="../assets/imgs/menuIcons/pending-account.png" width="28" />
          Pending Accounts
        </router-link>
      </li>

      <li class="aside__item">
        <router-link to="/ContactCp" class="aside__link">
          <img src="../assets/imgs/menuIcons/contacts.png" width="28" />
          Contacts
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/EventsCp" class="aside__link">
          <img src="../assets/imgs/menuIcons/events.png" width="28" />
          Events
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Account_management" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/account-management.png"
            width="28"
          />
          Account Management
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Settings" class="aside__link">
          <img src="../assets/imgs/menuIcons/setting.png" width="28" />
          Settings
        </router-link>
      </li>

      <li class="aside__item" v-if="$store.getters.loggedin" @click="dest()">
        <router-link to="#" class="aside__link">
          <img src="../assets/imgs/menuIcons/Log Out.png" width="28" />
          Logout
        </router-link>
      </li>

      <!-- <div class="divider"></div> -->
    </ul>

    <!-- ========== End admin navgations ==========  -->

    <!-- ==========  start Client navgations ==========  -->
    <ul
      class="list-unstyled mt-3"
      v-if="$store.getters.loggedin && !$store.getters.roles"
    >
      <li class="aside__item">
        <router-link to="/Dashboard" exact class="aside__link">
          <img src="../assets/imgs/menuIcons/Home.png" width="28" />
          Dashboard
        </router-link>
      </li>
      <li class="aside__item" v-if="$store.state.userObject.shipments">
        <router-link to="/Shipments" class="aside__link">
          <img src="../assets/imgs/menuIcons/Shipments.png" width="28" />
          Shipments
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Trace_trace" class="aside__link">
          <img src="../assets/imgs/menuIcons/track-and-trace.png" width="28" />
          Track and Trace
        </router-link>
      </li>

      <li class="aside__item" v-if="$store.state.userObject.payment">
        <router-link to="/Payments" class="aside__link">
          <img src="../assets/imgs/menuIcons/Payments.png" width="28" />
          Payments
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Docs_Request" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/documents-request.png"
            width="28"
          />
          Documents Request
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/events" class="aside__link row">
          <div>
            <img src="../assets/imgs/menuIcons/events.png" width="28" />
            Events
          </div>
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Request_quote" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/request-quotations.png"
            width="28"
          />
          Request Quotations
        </router-link>
      </li>
      <li class="aside__item" v-if="$store.state.userObject.booking">
        <router-link to="/Booking" class="aside__link">
          <img src="../assets/imgs/menuIcons/Booking.png" width="28" />
          Booking
        </router-link>
      </li>
      <li class="aside__item" v-if="$store.state.userObject.isClientAdmin">
        <router-link to="/Admin_Panel" class="aside__link">
          <img src="../assets/imgs/menuIcons/admin-panel.png" width="28" />
          Admin Panel
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/About" class="aside__link">
          <img src="../assets/imgs/menuIcons/about-us.png" width="28" />
          About Us
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/contact" class="aside__link">
          <img src="../assets/imgs/menuIcons/contact-us.png" width="28" />
          Contact Us
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Account_management" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/account-management.png"
            width="28"
          />
          Account Management
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Terms-policies" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/policies-and-terms.png"
            width="28"
          />
          Policies & Terms
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/help" class="aside__link">
          <img src="../assets/imgs/menuIcons/Help.png" width="28" />
          Help
        </router-link>
      </li>
      <li class="aside__item" v-if="$store.getters.loggedin" @click="dest()">
        <router-link to="#" class="aside__link">
          <img src="../assets/imgs/menuIcons/Log Out.png" width="28" />
          Logout
        </router-link>
      </li>
    </ul>
    <!-- ========== End Client navgations ==========  -->

    <!-- ========== Start  guset navgations ==========  -->
    <ul class="list-unstyled mt-3" v-if="!$store.getters.loggedin">
      <li class="aside__item">
        <router-link to="/Dashboard" exact class="aside__link">
          <img src="../assets/imgs/menuIcons/Home.png" width="28" />
          Home
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Request_quote" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/request-quotations.png"
            width="28"
          />
          Request Quotations
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Trace_trace" class="aside__link">
          <img src="../assets/imgs/menuIcons/track-and-trace.png" width="28" />
          Track and Trace
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/events" class="aside__link">
          <img src="../assets/imgs/menuIcons/events.png" width="28" />
          Events
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/About" class="aside__link">
          <img src="../assets/imgs/menuIcons/about-us.png" width="28" />
          About
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/contact" class="aside__link">
          <img src="../assets/imgs/menuIcons/contact-us.png" width="28" />
          Contacts
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/Terms-policies" class="aside__link">
          <img
            src="../assets/imgs/menuIcons/policies-and-terms.png"
            width="28"
          />
          Policies & Terms
        </router-link>
      </li>
      <li class="aside__item">
        <router-link to="/help" class="aside__link">
          <img src="../assets/imgs/menuIcons/Help.png" width="28" />
          Help
        </router-link>
      </li>
    </ul>
    <!-- ========== End guset  navgations ==========  -->
  </div>
</template>

<style lang="scss" scoped>
.aside {
  background: color(white);
  width: 250px;
  left: 0;
  top: 0;
  z-index: 33;
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  direction: rtl;
  @include transition(left 0.4s ease);
  @include shadow(blockShadow);
  @include query(maxL) {
    left: -260px;
  }
  &--open {
    left: 0;
  }
  &__header {
    padding-top: 16px;
    padding-bottom: 2px;
    border-bottom: 1px solid color(gray4);
  }
  .profilePic {
    width: 80px;
    height: 80px;
    background: url("../assets/imgs/profile.png") no-repeat center;
    background-size: 100% 100%;
    &:hover {
      .upload__label {
        top: 50%;
      }
    }
  }
  .upload {
    &__label {
      width: 100%;
      height: 100%;
      background: color(gray10);
      text-align: center;
      top: 100%;
      cursor: pointer;
      @include normalTrans;
      svg {
        color: color(gray4);
        position: relative;
        top: 5px;
      }
    }
  }
  &__name {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
  }
  &__link {
    padding: 0.54rem 1.7rem;
    display: block;
    direction: ltr;
    //   color:color(text);
    &.router-link-exact-active {
      background: color(gray10);
      color: color(white);
      &:hover {
        background-color: color(gray10);
      }
    }
    &:hover {
      background-color: color(gray4);
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;
}
.event-circle {
  color: white;
  padding: 3px 6px;
  background-color: #e40464;
  border-radius: 50%;
}
</style>

<script>
export default {
  name: "Aside",
  data() {
    return {
      eventsCount: "0",
      bodyH: 0,
      name: this.$store.getters.loggedin
        ? this.$store.state.userObject.displayName
        : "",
    };
  },
  methods: {
    dest() {
      this.$store.dispatch("destLogin").then((res) => {
        this.$store.state.loading = true;
        this.$router.push({ name: "Home" });
      });
    },
    onUnseeEventsChanged() {
      this.eventsCount++;
      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "info",
        title: "Signed in successfully",
      });
    },
  },
  watch: {
    $route() {
      this.$store.state.asideOpen = false;
      this.$store.state.blured = false;
    },
  },
  mounted() {
    // this.axios.get("/api/AppAdmin/Events/Get").then((res) => {
    //   let count = res.data.data.length;
    //   if (count >= 9) {
    //     this.eventsCount = 9 + "+";
    //   } else {
    //     this.eventsCount = "0" + count.toString();
    //   }
    // });
  },
  created() {},
};
</script>
