<template>
    <div class="settings pageCon" :class="{ blured: $store.state.blured }">
        <h1 class="text-uppercase mb-2 settings__h1 text-center font-weight-bold">
            settings
        </h1>
        <Tabs>
            <TabItem name="Companies" active>
                <div class="companies-tab">
                    <Companies :comps="comps"></Companies>
                </div>
            </TabItem>

            <TabItem name="Files">
                <div class="settings-files p-4">
                    <!--/ settings card -->
                    <!--settings card (guide ) -->
                    <div class="settings__card mb-3" v-if="!loadData">
                        <div class="row align-items-center">
                            <div class="col-md col-8">
                                <p class="settings__p text-uppercase">website guide</p>
                            </div>
                            <div class="
              col-md-7 col-4
              d-flex
              justify-content-end
              align-items-center
              flex-wrap
            ">
                                <span>{{ filesNames.guide }}</span>
                                <label for="upWeb" class="text-p mr-2 mb-0 settings__label">upload</label>
                                <div class="spinner-border text-secondary"
                                     style="width: 1rem; height: 1rem; top: -5px; position: relative"
                                     role="status"
                                     v-if="upload.guide_Spiner">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <span v-if="upload.guide_Done">
                                    <i class="fas fa-check text-success"></i>
                                </span>
                                <input @change="uploadFile($event, 'guide')"
                                       type="file"
                                       id="upWeb"
                                       hidden />
                            </div>
                            <div v-if="upload.guide_Err"
                                 class="col-12 text-warning text-right settings__err">
                                File size should be min 1kB and max 5MB
                            </div>
                        </div>
                    </div>
                    <!--/ settings card -->
                    <!--settings card  (company profile )-->
                    <div class="settings__card mb-3" v-if="!loadData">
                        <div class="row align-items-center">
                            <div class="col-md col-8">
                                <p class="settings__p text-uppercase">company profile</p>
                            </div>
                            <div class="
              col-md-7 col-4
              d-flex
              align-items-center
              justify-content-end
              flex-wrap
            ">
                                <span>{{ filesNames.company }}</span>
                                <label for="profileSet" class="text-p mr-2 mb-0 settings__label">upload</label>
                                <div class="spinner-border text-secondary"
                                     style="width: 1rem; height: 1rem; top: -5px; position: relative"
                                     role="status"
                                     v-if="upload.company_profile_Spiner">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <span v-if="upload.company_profile_Done">
                                    <i class="fas fa-check text-success"></i>
                                </span>

                                <input @change="uploadFile($event, 'company_profile')"
                                       type="file"
                                       id="profileSet"
                                       hidden />
                            </div>
                            <div v-if="upload.company_profile_Err"
                                 class="col-12 text-warning text-right settings__err">
                                File size should be min 1kB and max 5MB
                            </div>
                        </div>
                    </div>
                    <!--/ settings card -->
                    <!--settings card -->
                    <div class="settings__card mb-3" v-if="!loadData">
                        <div class="row align-items-center">
                            <div class="col-md col-8">
                                <p class="settings__p text-uppercase">import sailing schedule</p>
                            </div>
                            <div class="
              col-md-7 col-4
              d-flex
              align-items-center
              justify-content-end
              flex-wrap
            ">
                                <span>{{ filesNames.import }}</span>
                                <label for="importSa" class="text-p mr-2 mb-0 settings__label">upload</label>
                                <div class="spinner-border text-secondary"
                                     style="width: 1rem; height: 1rem; top: -5px; position: relative"
                                     role="status"
                                     v-if="upload.import_sailing_Spiner">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <span v-if="upload.import_sailing_Done">
                                    <i class="fas fa-check text-success"></i>
                                </span>

                                <input @change="uploadFile($event, 'import_sailing')"
                                       type="file"
                                       id="importSa"
                                       hidden />
                            </div>
                            <div v-if="upload.import_sailing_Err"
                                 class="col-12 text-warning text-right settings__err">
                                File size should be min 1kB and max 5MB
                            </div>
                        </div>
                    </div>
                    <!--/ settings card -->
                    <!--settings card -->
                    <div class="settings__card mb-3" v-if="!loadData">
                        <div class="row align-items-center">
                            <div class="col-md col-8">
                                <p class="settings__p text-uppercase">export sailing schedule</p>
                            </div>
                            <div class="
              col-md-7 col-4
              d-flex
              align-items-center
              justify-content-end
              flex-wrap
            ">
                                <span>{{ filesNames.export }}</span>
                                <label for="exportSal" class="text-p mr-2 mb-0 settings__label">upload</label>
                                <div class="spinner-border text-secondary"
                                     style="width: 1rem; height: 1rem; top: -5px; position: relative"
                                     role="status"
                                     v-if="upload.export_sailing_Spiner">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <span v-if="upload.export_sailing_Done">
                                    <i class="fas fa-check text-success"></i>
                                </span>

                                <input @change="uploadFile($event, 'export_sailing')"
                                       type="file"
                                       id="exportSal"
                                       hidden />
                            </div>
                            <div v-if="upload.export_sailing_Err"
                                 class="col-12 text-warning text-right settings__err">
                                File size should be min 1kB and max 5MB
                            </div>
                        </div>
                    </div>
                    <!--/ settings card -->
                </div>
            </TabItem>

            <TabItem name="Ports" disabled>
                <div class="first-tab"></div>
            </TabItem>
        </Tabs>
       
    </div>
</template>

<style lang="scss" scoped>
.settings {
  &__h1 {
    font-size: 25px;
  }
  &__err {
    font-size: 13px;
    position: absolute;
    bottom: 4px;
    right: 15px;
  }
  &__p {
    font-size: 15px;
    @include query(maxS) {
      font-size: 12px;
    }
  }
  span {
    font-size: 15px;
    color: color(gray10);
    @include query(maxS) {
      font-size: 12px;
    }
  }
  &__card {
    padding: 20px;
    background: color(white);
    @include radius(10px);
    @include shadow(cardShadow);
    font-size: 22px;
    font-weight: bold;
    position: relative;
  }
  &__btn {
    font-size: 18px;
    @include query(maxS) {
      font-size: 14px;
    }
  }
  &__label {
    font-size: 18px;
    cursor: pointer;
    @include query(maxS) {
      font-size: 14px;
    }
  }
}
</style>
<script>
import Companies from "../../components/admin/Compaines.vue";
export default {
  name: "cSettings",
  data() {
    return {
      loadData: true,
      guide: "",
      compProfile: "",
      imporTsailing: "",
      exporTsailing: "",
      spinner: false,
      viewCom: false,
      filesNames: {
        guide: "",
        company: "",
        import: "",
        export: "",
      },
      upload: {
        guide_Spiner: false,
        guide_Done: false,
        guide_Err: false,

        company_profile_Spiner: false,
        company_profile_Done: false,
        company_profile_Err: false,

        import_sailing_Spiner: false,
        import_sailing_Done: false,
        import_sailing_Err: false,

        export_sailing_Spiner: false,
        export_sailing_Done: false,
        export_sailing_Err: false,
      },
      comps: [],
    };
  },
  methods: {
    getFiles() {
      let names = [
        "guide",
        "company_profile",
        "import_sailing",
        "export_sailing",
      ];
      for (let i = 0; i < names.length; i++) {
        this.axios
          .get(`/api/AppAdmin/settings/ServerFile/?filename=${names[i]}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          })
          .then((res) => {
            if (
              res.data.data.fileLink
                .slice(
                  res.data.data.fileLink.lastIndexOf("/") + 1,
                  res.data.data.fileLink.length
                )
                .includes("guide")
            ) {
              this.filesNames.guide = res.data.data.fileLink.slice(
                res.data.data.fileLink.lastIndexOf("/") + 1,
                res.data.data.fileLink.length
              );
            } else if (
              res.data.data.fileLink
                .slice(
                  res.data.data.fileLink.lastIndexOf("/") + 1,
                  res.data.data.fileLink.length
                )
                .includes("company_profile")
            ) {
              this.filesNames.company = res.data.data.fileLink.slice(
                res.data.data.fileLink.lastIndexOf("/") + 1,
                res.data.data.fileLink.length
              );
            } else if (
              res.data.data.fileLink
                .slice(
                  res.data.data.fileLink.lastIndexOf("/") + 1,
                  res.data.data.fileLink.length
                )
                .includes("import_sailing")
            ) {
              this.filesNames.import = res.data.data.fileLink.slice(
                res.data.data.fileLink.lastIndexOf("/") + 1,
                res.data.data.fileLink.length
              );
            } else {
              this.filesNames.export = res.data.data.fileLink.slice(
                res.data.data.fileLink.lastIndexOf("/") + 1,
                res.data.data.fileLink.length
              );
            }
            setTimeout(() => {
              this.loadData = false;
            }, 200);
          })
          .catch((err) => {
            if (err) {
              this.loadData = false;
            }
          });
      }
    },
    uploadFile(a, name) {
      let form = new FormData();
      let file = a.currentTarget.files[0];
      form.append("File", file);
      form.append("FileDisplayName", name);

      // start upload and showing the spinner
      this.upload[`${name}_Spiner`] = true;

      this.axios
        .post("api/AppAdmin/settings/ServerFile", form, {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((res) => {
          this.upload[`${name}_Spiner`] = false;
          this.upload[`${name}_Err`] = false;
          this.upload[`${name}_Done`] = true;
          if (res.status == 200) {
            setTimeout(() => {
              this.upload[`${name}_Done`] = false;
            }, 2000);
          }
        })
        .catch((err) => {
          this.upload[`${name}_Spiner`] = false;
          this.upload[`${name}_Err`] = true;
        });
    },
   
  },
  components: {
    Companies,
  },
  created() {
    this.getFiles();
  },
};
</script>
