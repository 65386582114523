<template>
  <div class="help pageCon" :class="{ blured: $store.state.blured }">
    <div class="help__wrapper animate__animated animate__fadeIn">
      <h1 class="help__title text-uppercase">website guide</h1>
      <p class="help__text text-uppercase mt-2">
        learn how to use our customer portal
      </p>
      <a download :href="guideLink" class="btn btn-p mt-3 mb-5"
        >download guide</a
      >
      <div class="custom-alert" v-if="alert">
        Message has been sent <i class='fas fa-check text-success'></i>
      </div>
      <h2 class="help__title text-uppercase mb-3">something missing ?</h2>
      <span v-if="mail" class="text-danger">This Email not valid</span>
      <input
        v-if="!this.$store.getters.loggedin"
        type="text"
        :class="{ 'help__area--danger': mail }"
        class="form-control help__area mb-3"
        placeholder="Email"
        v-model="obj.requesterEmail"
      />
      <span v-if="area" class="text-danger">Message can't be empty</span>
      <textarea
        rows="5"
        class="form-control help__area text-uppercase"
        :class="{ 'help__area--danger': area }"
        placeholder="write down this details"
        v-model="obj.requestDescription"
      ></textarea>

      <div class="text-right mt-2">
        <button @click="sendHelp()" :disabled="spinner" class="btn btn-p help__btn">
          Send
          <div
            class="spinner-border text-secondary d-inline-block"
            role="status"
            style="width: 1rem; height: 1rem"
            v-if="spinner"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.help {
  &__wrapper {
    background: color(white);
    @include radius(10px);
    padding: 40px;
    @include shadow(cardShadow);
    @include query(maxS) {
      padding: 20px;
    }
  }
  &__title {
    font-size: 22px;
    font-weight: bold;
  }

  &__text {
    font-size: 15px;
  }
  &__area {
    border: 1px solid color(gray4) !important;
    &--danger {
      border: 1px solid color(primary) !important;
    }
    &::placeholder {
      color: color(gray10);
    }
  }
  &__btn:disabled {
    background-color:#333!important;
  }
}
</style>

<script>
export default {
  name: "Help",
  data() {
    return {
      guideLink: "",
      spinner: false,
      area: false,
      mail:false,
      alert:false,
      regMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      obj: {
        requesterEmail: this.$store.getters.loggedin
          ? this.$store.state.userObject.email
          : "",
        requesterUsername: this.$store.getters.loggedin
          ? this.$store.state.userObject.username
          : "guest",
        requesterPhone: this.$store.getters.loggedin
          ? this.$store.state.userObject.landLine == null
            ? "000000000"
            : this.$store.state.userObject.landLine
          : "000000000",
        requesterLandline: this.$store.getters.loggedin
          ? this.$store.state.userObject.landLine == null
            ? "000000000"
            : this.$store.state.userObject.landLine
          : "000000000",
        requestDescription: "",
      },
    };
  },
  methods: {
    sendHelp() {
      if (this.obj.requestDescription == "") {
        this.area = true;
      } else {
        this.area = false;
      }
      if (!this.regMail.test(this.obj.requesterEmail)) {
        this.mail = true;
      }else {
        this.mail = false;
      }

      if (this.regMail.test(this.obj.requesterEmail) && this.obj.requestDescription != "") {
        this.mail = false;
        this.area = false;
        this.spinner = true;
        this.axios.post("api/Home/ClientHelp", this.obj).then((res) => {
          this.spinner = false;
          this.alert = true;
          setTimeout(() => {
              this.alert = false;
              this.obj.requesterEmail = this.$store.getters.loggedin ? this.$store.state.userObject.email : "";
              this.obj.requestDescription = "";
          }, 2000);
        });
      }
    },
  },
  created() {
    this.axios
      .get(`/api/AppAdmin/settings/ServerFile?filename=guide`)
      .then((res) => {
        this.guideLink = res.data.data.fileLink;
      });
  },
};
</script>
