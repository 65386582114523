<template>
  <div
    class="panel overflow-hidden pageCon"
    :class="{ blured: $store.state.blured }"
  >
    <h1 class="panel__title text-center">Admin panel</h1>
    <p class="panel__text text-center">manage user permession</p>
    <div class="custom-alert" v-if="addAlert">
      This user has been created successfully
      <i class="fas fa-check text-success"></i>
    </div>

    <div class="text-right mt-4">
      <button
        v-if="!popup && !load"
        @click="(popup = true), (blured = true), (type = 'create')"
        class="btn panel__btn btn-p-outline text-uppercase"
      >
        add new user <i class="fas fa-plus"></i>
      </button>
    </div>

    <div v-if="load">
      <div
        class="load mb-2"
        v-for="(v, i) in new Array(5)"
        :key="i"
        style="height: 50px"
      ></div>
    </div>

    <div class="panel__wrapper mt-4" v-if="!popup && !load">
      <!--table -->
      <table class="table table-striped text-left">
        <thead class="table__thead">
          <tr>
            <th class="table__th">NAME/ details</th>
            <th class="table__th">full access</th>
            <th class="table__th">view shipments</th>
            <th class="table__th">payments</th>
            <th class="table__th">booking</th>
            <th class="table__th">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, i) in users" :key="i">
            <td class="table__td">
              <button @click="addUserObj(v)" class="btn">
                {{ v.email }} <span class="badge badge-pill badge-secondary ml-2" v-if="v.admin">Admin</span>
              </button>
            </td>
            <td class="table__td text-center">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  :id="v.userID + 'D' + i"
                  class="custom-control-input"
                  @click="fullAccess(i)"
                  v-model="v.fullAccess"
                  :data-id="v.userID"
                /><label
                  :for="v.userID + 'D' + i"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </td>
            <td class="table__td text-center">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  :id="v.userID + 'A' + i"
                  class="custom-control-input"
                  v-model="v.accessShipments"
                  :data-id="v.userID"
                  @click="mangeFullAccess(i)"
                /><label
                  :for="v.userID + 'A' + i"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </td>
            <td class="table__td text-center">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  :id="v.userID + 'B' + i"
                  class="custom-control-input"
                  v-model="v.accessPayment"
                  :data-id="v.userID"
                  @click="mangeFullAccess(i)"
                /><label
                  :for="v.userID + 'B' + i"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </td>
            <td class="table__td text-center">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  :id="v.userID + 'C' + i"
                  v-model="v.accessBooking"
                  class="custom-control-input"
                  :data-id="v.userID"
                  @click="mangeFullAccess(i)"
                /><label
                  :for="v.userID + 'C' + i"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </td>

            <td class="table__td text-center" v-if="!v.isCurrent">
              <button @click="deleteUser(v.userID)" class="btn">
                <i class="text-danger fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <!--/tabler -->
    </div>
    <button
      v-if="!popup && !load"
      @click="saveEdit()"
      class="btn btn-p float-right mt-3"
    >
      SAVE
    </button>
    <div class="clearfix"></div>

    <userPermations
      :type="type"
      :user="userObj"
      @close="userAdded($event)"
      v-if="popup"
    ></userPermations>
  </div>
</template>

<style lang="scss" scoped>
// @import  "../../styles/buttons";

.panel {
  &__title {
    font-size: 28px;
    font-weight: bold;
  }
  &__text {
    text-transform: capitalize;
    font-size: 18px;
  }
  &__wrapper {
    overflow: hidden;
    background: color(white);
    @include radius(10px);
    @include shadow(cardShadow);
    @include query(maxL) {
      overflow-x: auto;
    }
  }

  .table {
    text-align: center;
    border-collapse: separate;
    border-spacing: 0em 1em;
    background: color(white);
    padding: 40px;
    @include query(maxL) {
      padding: 10px;
    }
    &__td,
    &__th {
      white-space: nowrap;
      padding: 0rem 0.5rem;
      border: none;
      text-transform: uppercase;
      vertical-align: middle;
      button {
        text-transform: uppercase;
        font-weight: bold;
        color: color(primary);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
        @include query(maxS) {
          font-size: 12px;
        }
      }
      @include query(maxS) {
        font-size: 12px;
      }
    }
    &__th {
      padding-bottom: 20px;
    }
  }
}
.custom-control-label {
  position: relative;
  top: -5px;
  @include query(maxS) {
    top: 0;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}
</style>

<script>
    import userPermations from "../../components/clients/Admin_panel.vue";

export default {
  name: "AdminPanel",
  data() {
    return {
      addAlert: false,
      popup: false,
      blured: false,
      type: "",
      users: [],
      userObj: null,
      load: true,
    };
  },
  methods: {
    addUserObj(e) {
      let obj = e;
      this.userObj = obj;
      this.type = "edit";
      this.popup = true;
      this.blured = true;
    },
    fullAccess(a) {
      setTimeout(() => {
        if (this.users[a].fullAccess) {
          this.users[a].accessShipments = true;
          this.users[a].accessPayment = true;
          this.users[a].accessBooking = true;
        } else {
          this.users[a].accessShipments = false;
          this.users[a].accessPayment = false;
          this.users[a].accessBooking = false;
        }
      }, 0);
    },
    mangeFullAccess(a) {
      setTimeout(() => {
        if (
          this.users[a].accessShipments == true &&
          this.users[a].accessPayment == true &&
          this.users[a].accessBooking == true
        ) {
          this.users[a].fullAccess = true;
        } else {
          this.users[a].fullAccess = false;
        }
      }, 0);
    },
    getUsers() {
      this.axios
        .get("api/AdminPanel", {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
            this.users = res.data.data;
          this.load = false;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch("destLogin").then((res) => {
              this.$store.state.loading = true;
              this.$router.push({ name: "Home" });
            });
          }
        });
    },
    userAdded(ev) {
      this.popup = false;
      if (ev == "userAdded") {
        this.addAlert = true;
        this.getUsers();
        setTimeout(() => {
          this.addAlert = false;
        }, 2000);
      }
    },
    deleteUser(v) {
      let con = confirm("Delete this user ?");
      if (con) {
        this.axios
          .delete(`api/AdminPanel?userid=${v}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          })
          .then((res) => {
            this.getUsers();
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    saveEdit() {
      this.axios
        .put("api​/AdminPanel​/UpdateAll", this.users, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
    },
  },
  components: {
      userPermations
  },
  created() {
    this.getUsers();
  },
};
</script>
