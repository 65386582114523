<template>
  <div class="forget d-flex align-items-center justify-content-center">
    <div
      class="
        forget__wrapper
        text-center
        position-relative
        animate__animated animate__bounceInUp
      "
    >
      <button @click="$emit('close')" class="btn forget__close">
        <i class="fas fa-times font-weight-bold"></i>
      </button>
      <div v-if="!displayOperationResults">
        <h4 class="forget__title mb-3 text-uppercase">
          By deleting this user <span class="text-danger">Permanently</span> all
          his payments , shipments and any other related data will be deleted
        </h4>
        <button
          @click="permanentlyDeleting"
          :disabled="spinner"
          class="btn btn-p mt-3 w-100 forget__btn"
        >
          Permanently Deleting
        </button>
        <button
          @click="delegatingDeleting"
          :disabled="spinner"
          class="btn btn-p mt-3 w-100 forget__btn"
        >
          Deleting and Delegate
        </button>
        <button
          @click="$emit('close')"
          :disabled="spinner"
          class="btn btn-p mt-3 w-100 forget__btn"
        >
          Cancel
        </button>
      </div>
      <div v-if="displayOperationResults">
        <h4 class="forget__title mb-3 text-uppercase">
          {{ operation.message }}
        </h4>
        <p>
          You will be redirect to the dashboard after
          {{ operation.redirectSec }} seconds...
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.forget {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;

  &__input {
    background: color(body);
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
  }
  &__wrapper {
    background: color(white);
    padding: 60px 40px;
    width: 450px;
    @include shadow(blockShadow);
    @include query(maxS) {
      width: 92%;
    }
  }
  &__close {
    right: -10px;
    top: -10px;
    position: absolute;
    background: color(white);
    width: 34px;
    height: 34px;
    color: color(primary);
    @include shadow(blockShadow);
    &:hover {
      background: color(gray7);
    }
  }
  .input-group.notValid {
    border: 1px solid crimson;
  }
  &__btn:disabled {
    background: #333;
  }
}
</style>

<script>
export default {
  name: "DeleteUserPopup",
  data() {
    return {
      spinner: false,
      operation: { message: "", status: false, redirectSec: 0 },
      displayOperationResults: false,
    };
  },
  props: ["userID"],
  methods: {
    permanentlyDeleting() {
      this.spinner = true;
      this.axios
        .delete(
          `/api/AppAdmin/AppAccounts/DeleteAccountPermanently?userid=${this.userID}`
        )
        .then((res) => {
          this.spinner = false;
          this.displayOperationResults = true;
          this.operation.message = res.data.message;
          this.operation.redirectSec = 5;
          setTimeout(() => {
            window.location = "/Cpanel";
          }, 5000);
        });
    },
    delegatingDeleting() {
      this.spinner = true;
      this.axios
        .delete(
          `/api/AppAdmin/AppAccounts/DeleteAndDelegateAccount?userid=${this.userID}`
        )
        .then((res) => {
          this.spinner = false;
          this.displayOperationResults = true;
          this.operation.message = res.data.message;
          this.operation.redirectSec = 5;
          setTimeout(() => {
            window.location = "/Cpanel";
          }, 5000);
        });
    },
  },
  mounted() {},
};
</script>
