<template>
  <div :class="className">
    <div v-if="isEditMode">
      <form @submit="toggleEdit" class="edit-area">
        <textarea
          class="edit-box form-control"
          v-if="multi"
          v-model="text"
          required
          :class="{ 'edit-box-onedit': isEditMode }"
        ></textarea>
        <input
          type="text"
          class="edit-box"
          v-if="!multi"
          v-model="text"
          :required="isRequired"
          :class="{ 'edit-box-onedit': isEditMode }"
        />
        <button class="icon-btn" type="submit">
          <i class="fas fa-check" style="color: green"></i>
        </button>
        <button
          class="icon-btn"
          type="cancel"
          @click="cancelEditing"
          style="color: red"
        >
          <i class="fas fa-times"></i>
        </button>
      </form>
    </div>
    <div class="text-area" v-if="!isEditMode" @dblclick="toggleEdit">
      {{ text ? text : "no value" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableContent",
  props: [
    "content-key",
    "is-required",
    "value",
    "is-edit",
    "class-name",
    "on-change",
    "multi",
  ],
  data() {
    return {
      oldVal: this.value,
      text: this.value,
      isEditMode: this.isEdit,
    };
  },
  methods: {
    cancelEditing() {
      this.isEditMode = false;
      this.text = this.oldVal;
    },
    toggleEdit(e) {
      e.preventDefault();
      this.isEditMode = !this.isEditMode;
      if (!this.isEditMode && typeof this.onChange === "function") {
        this.onChange(this.contentKey, this.text);
        this.cancelEditing();
      }
    },
  },
};
</script>

<style>
.text-area{
    cursor: pointer;
    transition:border .2s linear ;
}
.text-area:hover{
  border-left: 5px solid #e40464;
}
.edit-area {
  display: flex;
}
.edit-box {
  display: block;
  width: 100%;
  cursor: pointer;
}
.edit-box-onedit {
  cursor: text;
  border: 1px solid #e40464;
}
span.float-right {
  cursor: pointer;
}
.icon-btn {
  margin: 0px 3px;
  padding: 8px;
  font-size: 10px;
  border: none;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10%;
}
</style>