<template>
  <div class="eventsForm animate__animated animate__fadeInUp">
    <div class="eventsForm__wrapper mt-4">
      <div class="form-row">
        <!--title -->
        <div class="form-group col-md-6">
          <label class="d-flex justify-content-between">
            <p>Title</p>
            <div>
              maximum(40 characters) <span>{{ obj.eventTitle.length }}</span>
            </div>
          </label>
          <input
            type="text"
            class="form-control eventsForm__input"
            placeholder="Title"
            v-model="obj.eventTitle"
            @focus="title = false"
            :class="{ 'eventsForm__input--err': title }"
            maxlength="40"
          />
          <p class="text-danger" v-if="title">
            can't be empty and should be maximum 40 characters
          </p>
        </div>
        <!--title -->
        <div class="form-group col-md-6">
          <label>Month</label>
          <input
            type="text"
            class="form-control eventsForm__input"
            placeholder="Month"
            v-model="obj.month"
          />
        </div>

        <!--link -->
        <div class="form-group col-md-6">
          <label>Link</label>
          <input
            v-model="obj.eventLink"
            type="text"
            class="form-control eventsForm__input"
            placeholder="Link"
            @focus="evLink = false"
            :class="{ 'eventsForm__input--err': evLink }"
          />
          <p v-if="evLink" class="text-danger">can't be empty</p>
        </div>

        <!--link -->
        <div class="form-group col-md-6">
          <label>Link name</label>
          <input
            v-model="obj.linkDisplayName"
            type="text"
            class="form-control eventsForm__input"
            placeholder="Link name"
            @focus="evLinkName = false"
            :class="{ 'eventsForm__input--err': evLinkName }"
          />
          <p v-if="evLinkName" class="text-danger">can't be empty</p>
        </div>

        <!--Downlaod File -->
        <div class="form-group col-md-6">
          <label class="d-flex justify-content-between align-items-center">
            <p>Download File</p>
          </label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="eventFile"
              @change="handleFileUpload($event,'file')"
            />
            <label class="
                custom-file-label
                eventsForm__input eventsForm__input--label
              "
                   for="eventFile">{{eventFile? `FIle "${eventFile.name}" Loaded` : 'Choose a file'}}</label>
          </div>
        </div>

        <!--title -->
        <div class="form-group col-md-6">
          <label>File name</label>
          <input
            type="text"
            class="form-control eventsForm__input"
            placeholder="file name"
            v-model="obj.fileDisplayName"
          />
        </div>
        <!--Downlaod File -->
        <div class="form-group col-md-6">
          <label class="d-flex justify-content-between align-items-center">
            <p>Event Image</p>
          </label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="eventImage"
              @change="handleFileUpload($event,'image')"
              @focus="image = false"
              accept="image/*"
            />
            <label class="
                custom-file-label
                eventsForm__input eventsForm__input--label
              "
                   for="eventImage"> {{eventImg? `Image "${eventImg.name}" Loaded` : 'Choose an image'}}</label>
          </div>
          <p class="text-danger" v-if="image">Please choose your event image</p>
        </div>
        <!--Description -->
        <div class="form-group col-12 mt-3">
          <label class="d-flex justify-content-between">
            <p>Description</p>
            <div>
              minimum(20)
              <i
                :class="{
                  'text-danger': obj.description.length < 20,
                  'text-success': obj.description.length >= 20,
                }"
                >{{ obj.description.length }}</i
              >/20
            </div>
          </label>
          <textarea
            minlength="20"
            rows="5"
            class="form-control eventsForm__area border-0"
            placeholder="Description"
            v-model="obj.description"
            @focus="desc = false"
            :class="{ 'eventsForm__area--err': desc }"
          ></textarea>
        </div>
        <!--col checkbox -->
        <div class="form-group col-12">
          <div class="custom-control custom-checkbox mb-0">
            <input
              v-model="obj.display"
              type="checkbox"
              class="custom-control-input"
              id="truCheck"
            />
            <label class="custom-control-label pl-1 pt-1" for="truCheck"
              >Display</label
            >
          </div>
        </div>
        <div class="form-group text-right col-12">
          <button class="btn btn-s" @click="$emit('close')">close</button>
          <button
            :disabled="spinner"
            @click="create()"
            class="btn btn-p ml-2 eventsForm__btn"
          >
            Save
            <div
              class="spinner-border text-secondary d-inline-block"
              role="status"
              style="width: 1rem; height: 1rem"
              v-if="spinner"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.eventsForm {
  &__wrapper {
    background-color: color(white);
    padding: 20px;
    @include radius(8px);
    @include shadow(cardShadow);
  }
  &__input {
    background: color(body);
    &--err {
      border: 1px solid color(primary);
    }
    &--label {
      border: none;
      height: 45px;
      line-height: 38px;
      &:before,
      &:after {
        height: 45px;
        line-height: 38px;
      }
    }
  }
  &__area {
    background: color(body);
    &--err {
      border: 1px solid color(primary) !important;
    }
  }
  &__btn:disabled {
    background-color: #333;
  }
}
.custom-control-label::before {
  background-color: color(body) !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}
</style>

<script>
    export default {
        props: ["method", "eventId"],
        name: "eventsForm",
        data() {
            return {
                title: false,
                desc: false,
                image: false,
                spinner: false,
                evLink: false,
                evLinkName: false,
                eventImg: null,
                eventFile: null,
                obj: {
                    eventTitle: "",
                    description: "",
                    month: "",
                    fileDisplayName: "",
                    filePath: "",
                    imagePath: "",
                    display: true,
                    eventLink: "",
                    linkDisplayName: "",
                },
            };
        },
        methods: {
            handleFileUpload(chnage, title) {
                switch (title) {
                    case 'image':
                        this.eventImg = chnage.target.files[0];
                        break;
                    case 'file':
                        this.eventFile = chnage.target.files[0];
                        break;
                }
            },
            create() {
                let url = "";
                let formData = new FormData();

                if (this.method == "create") {
                    url = "/api/AppAdmin/Events/Create";
                } else {
                    url = "/api/AppAdmin/Events/Update";
                    formData.append("EventDataID", this.eventId);
                    formData.append("filePath", this.obj.filePath);
                }
                formData.append("EventTitle", this.obj.eventTitle),
                    formData.append("Description", this.obj.description),
                    formData.append("Month", this.obj.month),
                    formData.append("FileDisplayName", this.obj.fileDisplayName),
                    formData.append("imagePath", this.obj.imagePath),
                    formData.append("display", this.obj.display),
                    formData.append("eventLink", this.obj.eventLink),
                    formData.append("linkDisplayName", this.obj.linkDisplayName),
                    formData.append("file", this.eventFile);
                formData.append("image", this.eventImg);
                if (
                    this.obj.description.length >= 20 &&
                    this.obj.eventTitle != "" &&
                    this.obj.eventTitle.length <= 40 &&
                    this.obj.eventLink != "" &&
                    this.obj.linkDisplayName != ""
                ) {
                    this.spinner = true;
                    this.axios
                        .post(url, formData, {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.token}`,
                            },
                        })
                        .then((res) => {
                            this.desc = false;
                            this.title = false;
                            this.image = false;
                            this.evLink = false;
                            this.evLinkName = false;
                            this.spinner = false;
                            this.$emit("close");
                            this.$emit("done");
                            this.$emit("updateEv");
                        })
                        .catch((err) => {
                            this.$swal({
                                icon: "error",
                                title: "Data Error !",
                                text: JSON.stringify(err.response.data.errors),
                            });
                            this.spinner = false;
                        });
                } else {
                    this.desc = this.obj.description.length < 20;
                    this.title = this.obj.eventTitle > 40 || !this.obj.eventTitle;
                    this.evLink = !this.obj.eventLink;
                    this.evLinkName = !this.obj.linkDisplayName;
                }
            },
        },
        mounted() {
            if (this.method == "edit") {
                this.axios
                    .get(`/api/AppAdmin/Events/${this.eventId}`, {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        this.obj = res.data.data;
                    });
            }
        },
    };
</script>
