<template>
  <div class="manageUser animate__animated animate__fadeInUp">
    <div class="manageUser__wrapper">
      <form>
        <div class="form-group mb-4">
          <label>Email</label>
          <input
            type="text"
            :class="{ 'manageUser__input--danger': emailValid }"
            class="form-control manageUser__input"
            v-model="obj.email"
            placeholder="Will use this name in log in"
          />
          <p v-if="emailValid" class="text-danger">{{ emailText }}</p>
        </div>
        <div class="form-group mb-4">
          <label>password</label>
          <input
            type="password"
            class="form-control manageUser__input"
            placeholder="Will use this password in log in"
            :class="{ 'manageUser__input--danger': passwordValid }"
            v-model="obj.password"
            @focus="passVad = true"
          />
          <div class="manageUser__passValid mt-1" v-if="passVad">
            <ul class="list-unstyled mt-2">
              <li class="mb-1">
                <span
                  :class="[
                    /(?=.*[a-z])/.test(obj.password)
                      ? 'text-success'
                      : 'text-danger',
                  ]"
                >
                  <span v-show="/(?=.*[a-z])/.test(obj.password)"
                    ><i class="fas fa-check-circle"></i
                  ></span>
                  <span v-show="!/(?=.*[a-z])/.test(obj.password)"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  1 small character at least
                </span>
              </li>

              <li class="mb-1">
                <span
                  :class="[
                    /(?=.*[A-Z])/.test(obj.password)
                      ? 'text-success'
                      : 'text-danger',
                  ]"
                >
                  <span v-show="/(?=.*[A-Z])/.test(obj.password)"
                    ><i class="fas fa-check-circle"></i
                  ></span>
                  <span v-show="!/(?=.*[A-Z])/.test(obj.password)"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  1 capital character at least
                </span>
              </li>

              <li class="mb-1">
                <span
                  :class="[
                    /(?=.*\d)/.test(obj.password)
                      ? 'text-success'
                      : 'text-danger',
                  ]"
                >
                  <span v-show="/(?=.*\d)/.test(obj.password)"
                    ><i class="fas fa-check-circle"></i
                  ></span>
                  <span v-show="!/(?=.*\d)/.test(obj.password)"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  one digit at least
                </span>
              </li>

              <li class="mb-1">
                <span
                  :class="[
                    /\W/g.test(obj.password) ? 'text-success' : 'text-danger',
                  ]"
                >
                  <span v-show="/\W/g.test(obj.password)"
                    ><i class="fas fa-check-circle"></i
                  ></span>
                  <span v-show="!/\W/g.test(obj.password)"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  one Symbol
                </span>
              </li>

              <li class="mb-1">
                <span
                  :class="[
                    regPass.test(obj.password) ? 'text-success' : 'text-danger',
                  ]"
                >
                  <span v-show="regPass.test(obj.password)"
                    ><i class="fas fa-check-circle"></i
                  ></span>
                  <span v-show="!regPass.test(obj.password)"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  should contain at least 8 from the mentioned characters
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group mb-4">
            <label>Tracking Companies</label>

            <multiselect :value="userEntities" :options="Companies" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Companies" @select="addOption" @remove="removeOption" label="title" track-by="id" :preselect-first="true">
            </multiselect>
        </div>

        <div class="form-group mb-4">
          <label>user permession</label>
          <div
            class="
              d-flex
              justify-content-between justify-content-md-start
              text-left text-md-center
              mt-2
              flex-wrap
            "
          >
            <div class="mr-md-4 manageUser__checkBox">
              <label for="full" class="manageUser__label">full access</label>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id="full"
                  v-model="obj.fullAccess"
                  class="custom-control-input"
                  @click="fullAccess()"
                /><label
                  for="full"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </div>

            <div class="mr-md-4 manageUser__checkBox">
              <label for="ship" class="manageUser__label">view shipments</label>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id="ship"
                  class="custom-control-input"
                  v-model="obj.accessShipments"
                  @click="manageAccsess()"
                /><label
                  for="ship"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </div>
            <div class="mr-md-4 manageUser__checkBox">
              <label for="pay" class="manageUser__label">payments </label>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id="pay"
                  class="custom-control-input"
                  v-model="obj.accessPayment"
                  @click="manageAccsess()"
                /><label
                  for="pay"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </div>
            <div class="manageUser__checkBox">
              <label for="booking" class="manageUser__label">booking </label>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id="booking"
                  class="custom-control-input"
                  v-model="obj.accessBooking"
                  @click="manageAccsess()"
                /><label
                  for="booking"
                  class="custom-control-label pl-4 pt-2"
                ></label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-4">
          <label>position</label>
          <input
            type="text"
            class="form-control manageUser__input"
            placeholder="position"
            v-model="obj.position"
          />
        </div>

        <div class="form-group text-right m-0">
          <div>
            <button
              class="btn btn-s mr-2"
              @click="$emit('close', 'normalClose')"
            >
              Close
            </button>
            <button
              :disabled="spinner"
              @click.prevent="create()"
              class="btn btn-p manageUser__btn"
            >
              <div
                class="spinner-border text-secondary d-inline-block"
                role="status"
                style="width: 1rem; height: 1rem"
                v-if="spinner"
              >
                <span class="sr-only">Loading...</span>
              </div>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.manageUser {
  &__passValid {
    font-size: 13px;
  }
  &__wrapper {
    width: 800px;
    margin: 50px auto 0;
    background: color(white);
    @include radius(10px);
    padding: 40px;
    @include shadow(cardShadow);
    @include query(maxL) {
      padding: 20px;
      width: 100%;
    }
  }
  &__input {
    background: color(body);
    border: 1px solid color(gray4);
    @include radius(30px);
    &--danger {
      border: 1px solid color(primary);
    }
  }
  &__btn:disabled {
    background-color: #333;
  }
  &__label {
    color: color(gray10);
    padding-left: 0;
  }
  &__checkBox {
    @include query(maxM) {
      width: calc(100% / 4);
    }
    @include query(maxS) {
      width: 50%;
      margin-bottom: 20px;
    }
  }
}
.custom-control-label::before,
.custom-control-label::after {
  background-color: color(body) !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: color(body) !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}
</style>
<script>
    import Vue from 'vue';
    import Multiselect from 'vue-multiselect'
    Vue.component('multiselect', Multiselect)
    export default {
        name: "userPermations",
        props: ["type", "user"],
        data() {
            return {
                Companies: [],
                userEntities: [],
                spinner: false,
                emailValid: false,
                emailText: "",
                regMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                regPass:
                    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                passVad: false,
                passwordValid: false,
                obj: {
                    admin: false,
                    userID: "",
                    email: "",
                    password: "",
                    position: "",
                    accessShipments: false,
                    accessBooking: false,
                    accessPayment: false,
                    fullAccess: false,
                    entities: []
                },
            };
        },
        methods: {
            fullAccess() {
                setTimeout(() => {
                    if (this.obj.fullAccess) {
                        this.obj.accessShipments = true;
                        this.obj.accessBooking = true;
                        this.obj.accessPayment = true;
                    } else {
                        this.obj.accessShipments = false;
                        this.obj.accessBooking = false;
                        this.obj.accessPayment = false;
                    }
                }, 0);
            },
            manageAccsess() {
                setTimeout(() => {
                    if (
                        this.obj.accessBooking &&
                        this.obj.accessShipments &&
                        this.obj.accessPayment
                    ) {
                        this.obj.fullAccess = true;
                    } else {
                        this.obj.fullAccess = false;
                    }
                }, 0);
            },
            create() {
                let method = "";
                if (this.type == "create") {
                    method = "post";
                } else {
                    method = "put";
                }

                if (this.userEntities.length === 0) {
                    alert("A user should be connected to at least one tracking company");
                    return;
                } else {
                    this.obj.entities = this.userEntities.map(e => { return { item1: e.id, item2: e.title } });
                }

                if (!this.regMail.test(this.obj.email)) {
                    this.emailValid = true;
                    this.emailText = "This email is not valid";
                } else {
                    this.emailValid = false;
                    this.emailText = "";
                }
                if (method === "post" && !this.regPass.test(this.obj.password)) {
                    this.passwordValid = true;
                } else {
                    this.passwordValid = false;
                }
                if (
                    !this.obj.accessShipments &&
                    !this.obj.accessBooking &&
                    !this.obj.accessPayment &&
                    !this.obj.fullAccess
                ) {
                    this.obj.fullAccess = true;
                }
                if (
                    this.regMail.test(this.obj.email) &&
                    this.regPass.test(this.obj.password) &&
                    method === "post" ||
                    this.regMail.test(this.obj.email) &&
                    method === "put"
                ) {
                    this.spinner = true;
                    let config = {
                        method: method,
                        url: "api/AdminPanel",
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                        data: this.obj,
                    };
                    this.axios(config).then((res) => {
                        if (res.status == 200) {
                            this.$emit("close", "userAdded");
                        }
                    });

                }
            },
            getEntities() {
                this.axios
                    .get("/api/AdminPanel/GetEntities", {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {

                        const receivedcmps = res.data.data;
                        if (this.userEntities.length === 0) {
                            this.Companies = receivedcmps;
                            return;
                        }
                        const deletedset = new Set(this.userEntities);
                        const filteredCompanies = receivedcmps.filter((cmp) => { !deletedset.has(cmp.id) });
                        this.Companies = filteredCompanies;


                    })
                    .catch((res) => {

                    });
            },
            addOption(Option) {
                this.userEntities.push(Option);
            },
            removeOption(Option) {
                this.userEntities.splice(this.userEntities.indexOf(Option), 1);
                if (!this.Companies.includes(Option))
                    this.Companies.push(Option);
            }
        },
        components: {
            Multiselect
        },
        mounted() {


            if (this.type == "edit") {
                this.obj = this.user;
                this.userEntities = this.user.entities.map(e => { return { id: e.item1, title: e.item2 } });
            }
            this.getEntities();
        },
    };
</script>
