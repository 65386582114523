<template>
  <div
    class="payments overflow-hidden pageCon"
    :class="{ blured: $store.state.blured }"
  >
    <h1 class="payments__title text-center">Payments</h1>
    <p class="payments__text text-center">manage your invoices and payments</p>

    <div class="payments__wrapper mt-4">
      <div class="table__wrapper">
        <div v-if="!loadData" class="p-3">
          <div
            class="load mb-2"
            style="height: 50px; width: 100%"
            v-for="(a, i) in new Array(5)"
            :key="i"
          ></div>
        </div>
        <!--table -->
        <sorted-table :values="invoices" v-if="loadData" class="table table-striped mb-0">
            <thead class="table__thead">
                <tr>
                    <th class="table__th">
                        <sort-link name="workOrderID">Work Order ID</sort-link>
                    </th>
                    <th class="table__th">
                        <sort-link name="invoiceID">Inovice No.</sort-link>
                    </th>
                    <th class="table__th">
                        <sort-link name="bl">b/l num.</sort-link>
                    </th>
                    <th class="table__th">
                        <sort-link name="invoiceDate">invoice date</sort-link>
                    </th>
                    <th class="table__th">
                        <sort-link name="type">type</sort-link>
                    </th>
                    <th class="table__th">
                        Amount
                    </th>
                    <th class="table__th">
                        pay
                    </th>
                </tr>
            </thead>
            <template #body="sort">
                <tbody>
                    <tr v-for="(invoice, i) in sort.values" :key="i">
                        <td class="table__td">
                            <button class="btn text-primary">
                                {{ invoice.workOrderID }}
                            </button>
                        </td>
                        <td class="table__td">
                            {{ invoice.invoiceID }}
                        </td>
                        <td class="table__td">{{ invoice.bl }}</td>
                        <td class="table__td">
                            {{ invoice.invoiceDate | DefaultDate }}
                        </td>
                        <td class="table__td">{{ invoice.type }}</td>
                        <td class="table__td text-primary">{{ invoice.amounts[0] , invoice.amounts[1] ,  invoice.amounts[2] }}</td>
                        <td class="table__td">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox"
                                       :id="'A' + i"
                                       class="custom-control-input"
                                       @click="
                      calc(
                        $event,
                        Math.round(invoice.total),
                        invoice.invoiceID.toString(),
                        invoice
                      )
                    " /><label :for="'A' + i"
                               class="custom-control-label pl-4 pt-2"></label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
                
</sorted-table>
        <!--/tabler -->
      </div>

      <div class="mb-3 px-3" v-if="!loadData" style="direction: rtl">
        <div
          class="load mb-3"
          style="width: 300px; height: 50px; animation-duration: 1s"
        ></div>
        <div
          class="load"
          style="width: 180px; height: 50px; animation-duration: 1s"
        ></div>
      </div>

      <div class="totals" v-if="loadData">
        <div class="totals__wrapper ml-auto text-right">
          <div class="form-group position-relative">
            <label class="totals__label">total amount in l.e</label>
            <input
              type="text"
              readonly
              v-model="order.order.amount"
              class="text-right form-control totals__selected"
            />
          </div>

          <button
            type="button"
            @click.prevent="senDsession()"
            class="btn btn-p payments__btn"
            :disabled="orderObj.length == 0 || spinner"
          >
            pay now

            <div
              class="spinner-border text-secondary d-inline-block"
              role="status"
              style="width: 1rem; height: 1rem"
              v-if="spinner"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
        <hr />
        <router-link class="payments__link text-p" to="/PreveiousPayments"
          >view paid inovices</router-link
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// @import  "../../styles/buttons";

.payments {
  &__title {
    font-size: 28px;
    font-weight: bold;
  }
  &__text {
    text-transform: capitalize;
    font-size: 18px;
  }
  &__wrapper {
    overflow: hidden;
    background: color(white);
    @include radius(10px);
    @include shadow(cardShadow);
  }
  &__btn:disabled {
    background: color(gray10);
  }
  .table {
    text-align: center;
    border-collapse: separate;
    border-spacing: 0em 1em;
    background: color(white);
    padding: 20px;
    @include query(maxL) {
      padding: 10px;
    }
    &__wrapper {
      @include query(maxL) {
        overflow-x: auto;
      }
    }
    &__td,
    &__th {
      white-space: nowrap;
      padding: 0rem 0.5rem;
      border: none;
      text-transform: uppercase;
      vertical-align: middle;
      font-size: 13px;
      button {
        text-transform: uppercase;
        font-weight: bold;
        color: color(primary);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
        @include query(maxS) {
          font-size: 12px;
        }
      }
      @include query(maxS) {
        font-size: 12px;
      }
    }
    &__th {
      padding-bottom: 20px;
    }
  }
  .totals {
    padding: 20px;
    margin-top: 50px;
    hr {
      border-bottom: 2px solid color(borderGray2);
    }
    &__wrapper {
      width: 400px;
      @include query(maxM) {
        width: 50%;
      }
      @include query(maxS) {
        width: 100%;
      }
    }
    &__label {
      position: absolute;
      left: 10px;
      top: 12px;
    }
  }
  &__link {
    text-decoration: underline !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      text-decoration: none !important;
    }
  }
}
.custom-control-label {
  position: relative;
  top: -5px;
  left: 15px;
  @include query(maxS) {
    top: 0;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}
</style>

<script>
export default {
  name: "Payments",
  data() {
    return {
      popup: false,
      blured: false,
      invoices: [],
      loadData: false,
      orderObj: [],
      spinner: false,
      ids: [],
      sessionRes: {
        merchant: "TESTCIB701326",
        result: "SUCCESS",
        session: {
          id: "SESSION0002458209427F99916102F8",
          updateStatus: "SUCCESS",
          version: "f924360d01",
        },
        successIndicator: "456ba6fe843b4b4c",
      },
      order: {
        apiOperation: "CREATE_CHECKOUT_SESSION",
        interaction: {
          operation: "PURCHASE",
        },
        order: {
          amount: 0,
          currency: "EGP",
          description: "Ordered goods",
          id: "",
        },
      },
    };
  },
  methods: {
    calc(e, total, id, invoice) {
      if (e.target.checked) {
        this.order.order.amount += total;
        this.orderObj.push(invoice);
        this.ids.push(id);
      } else {
        this.order.order.amount -= total;
        this.ids.splice(this.ids.indexOf(id), 1);
      }

      this.order.order.id = this.ids.join("-");
    },
    senDsession() {
      let vm = this;
      if (this.orderObj.length != 0) {
        for (let i = 0; i < this.orderObj.length; i++) {
          this.orderObj[i].paymentReference = this.order.order.id;
        }
        this.spinner = true;
        localStorage.setItem("order", JSON.stringify(this.orderObj));
        setTimeout(() => {
          vm.spinner = false;
          window.open(
            "https://portal.eg.sacoshipping.com/api/Payment/GetPaymetPage?Amount=" +
              this.order.order.amount +
              "&Currency=EGP&Description=Order&Id=" +
              this.order.order.id +
              "",
            "_self"
          );
        }, 1000);
      }
    },
  },
  mounted() {
    this.axios
      .get("/api/Payment/GetPayableInvoices", {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`,
        },
      })
      .then((res) => {
        this.invoices = res.data.data;
        this.loadData = true;
      });
  },
};
</script>
