<template>
  <div class="compaines mt2">
      <div class="compaines__wrapper">
          <div class="d-flex justify-content-between align-items-center">
              <h2 v-if="!loadData"
                  class="text-uppercase font-weight-bold mb-3 h4 compaines__h2">
                  ({{ output.length }}) Companies
              </h2>

              <button @click="refreshCompanies()"
                      class="d-block btn btn-primary"
                      :disabled="spinner">
                  Refresh
                  <div v-if="spinner"
                       class="spinner-border text-secondary"
                       style="width: 1rem; height: 1rem; top: -5px; position: relative"
                       role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  <span v-if="refreshed">
                      <i class="fas fa-check text-success"></i>
                  </span>
              </button>
          </div>
        

          <div v-if="loadData"
               class="d-inline-block load"
               style="width: 200px; height: 40px"></div>

          <div v-if="loadData">
              <div v-for="(a, i) in new Array(6)"
                   :key="i"
                   class="load"
                   style="height: 40px; margin-bottom: 10px"></div>
          </div>
          <div v-if="!loadData">
              <input type="search"
                     v-model="searchInp"
                     @keyup="search()"
                     class="form-control my-3"
                     placeholder="search" />
          </div>
          <ul v-if="!loadData" class="list-unstyled ompaines__list">
              <li class="mb-2 pb-2 compaines__item font-weight-bold"
                  v-for="(c, i) in output"
                  :key="i">
                  {{`${i+1} - ${c.companyTitle }`}}
              </li>
          </ul>
      </div>
  </div>
</template>

<style lang="scss">
.compaines {
  left: 0;
  top: 0;
  width: 100%;
  &__h2 {
    display: inline-block;
    border-bottom: 1px solid color(gray10);
  }
  &__wrapper {
    background: color(white);
    padding: 40px;
    @include radius(10px);
    @include shadow(cardShadow);
    margin: 0 auto;
  }
  &__item {
    &:not(:last-of-type) {
      border-bottom: 1px solid color(gray4);
    }
  }
}
</style>
<script>
    export default {
        name: "Companies",
        data() {
            return {
                loadData: true,
                comps: [],
                searchInp: "",
                output: [],
                spinner: false,
                refreshed:false
            };
        },
        methods: {
            refreshCompanies() {
                this.spinner = true;
                this.refreshed = false;
                let config = {
                    method: "put",
                    url: "api/AppAdmin/Companies",
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`,
                    },
                };

                this.axios(config).then((res) => {
                    this.spinner = false;
                    this.refreshed = true;
                    setTimeout(2000, () => {
                        this.refreshed = false
                    });
                });
            },
            search() {
                this.output = [];
                let val = this.searchInp;
                if (val != "") {
                    for (let i = 0; i < this.comps.length; i++) {
                        if (
                            this.comps[i].companyTitle.toLowerCase().includes(val.toLowerCase())) {
                            this.output.push(this.comps[i]);
                        }
                    }
                } else {
                    this.output = this.comps;
                }

            },
            getCompanies() {
                // Get all compaines
                this.axios
                    .get("api/AppAdmin/Companies", {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        this.comps = res.data.data;
                        this.output = res.data.data;
                        this.loadData = false;
                    });

            },
        },
        mounted() {
            this.getCompanies();
        },
    };
</script>
