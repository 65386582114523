<template>
  <div class="pending pageCon" :class="{ blured: $store.state.blured }">
    <h1 class="text-center pending__h1 text-uppercase mb-5 font-weight-bold">
      Pending accounts
    </h1>

    <div v-if="pendingUsers == undefined" class="custom-alert">There are no pending accounts <i class='fas fa-info text-warning'></i></div>
    <pendingDetails
      :id="id"
      @close="details = false"
      v-if="details"
      @updateUser="getUsers()"
    ></pendingDetails>

    <div class="pending__wrapper overflow-hidden" v-if="!details">
      <!--loading  data -->
      <div class="row" v-if="loadData">
        <div
          v-for="(v, i) in new Array(8)"
          :key="i"
          class="col-md-6 col-lg-3 mb-3 load"
          style="height: 180px"
        ></div>
      </div>
      <!--/loading data -->

      <div class="row" v-if="!loadData">
        <div
          class="col-md-6 col-lg-3 mb-3"
          v-for="(user, i) in pendingUsers"
          :key="i"
        >
            <div class="pending__card text-center">
                <h4 class=" mb-4 text-center text-uppercase">
                    {{ user.firstName }} {{ user.lastName }} ({{user.position}})
                </h4>
                <h2 class="pending__title mb-4 text-center text-uppercase">
                    <span class="text-secondary">From :</span> {{ user.companyName }}
                </h2>
                <button v-if="!user.inUser"
                        class="btn btn-p pending__btn mb-2"
                        @click="showDetails(user.requestID)">
                    Details
                </button>
                <button v-if="!user.inUser"
                        class="btn btn-s pending__btn"
                        @click="remove(user.requestID)">
                    Remove
                </button>
                <p class="text-warning text-uppercase font-weight-bold"
                   style="font-size:20px"
                   v-if="user.inUser">Working On...</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pending {
  &__h1 {
    font-size: 29px;
  }
  &__card {
    background: color(white);
    padding: 40px 20px;
    @include shadow(cardShadow);
  }
  &__title {
    font-size: 22px;
    font-weight: bold;
  }
  &__btn {
    width: 140px;
    display: block;
    margin: 0 auto;
  }
}
</style>
<script>
    import pendingDetails from "../../components/admin/Pending_details";
    export default {
        name: "pending",
        data() {
            return {
                details: false,
                pendingUsers: [],
                id: null,
                loadData: true,
                userInfo: {}
            };
        },
        methods: {
            remove(id) {
                let con = confirm("Delete this Request ?");
                if (con) {
                    this.axios.delete(`/api/AppAdmin/PendingUsers/DeleteAccount?id=${id}`, {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        }
                    }).then(res => this.getUsers());
                }
            },
            getUsers() {
                this.loadData = true;
                // get All pending users
                this.axios
                    .get("api/AppAdmin/PendingUsers", {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        this.pendingUsers = res.data.data;
                    }).then(() => this.loadData = false);
            },
            // Open details 
            showDetails(e) {
                this.details = true;
                this.id = e;
            }
        },
        components: {
            pendingDetails,
        },
        mounted() {
            this.getUsers();
        },
    };
</script>
