<template>
  <div class="events pageCon" :class="{ blured: $store.state.blured }">
    <h1 class="text-center text-uppercase mb-5">Events</h1>

    <div class="row" v-if="!loadData">
      <div
        class="col-lg-4 col-sm-6 mb-5"
        v-for="(a, i) in new Array(6)"
        :key="i"
      >
        <div class="load" style="height: 300px"></div>
      </div>
    </div>

    <div class="row" v-if="loadData">
      <div
        class="col-lg-4 col-sm-6 mb-5"
        v-for="(ev, i) in events"
        :key="i"
        v-show="ev.display"
      >
        <div class="events__card position-relative">
          <span
            v-if="ev.month != '' || ev.month != null"
            class="events__month position-absolute d-block rounded-circle"
          >
            {{ ev.month }}
          </span>
          <img :src="ev.imageLink" class="w-100" />
          <div class="events__body">
            <h4 class="events__title font-weight-bold mb-2">{{ ev.eventTitle }}</h4>
            <p class="events__desc">
              {{ ev.description }}
            </p>

            <a
              class="
                text-primary
                font-weight-bold
                text-uppercase
                mt-3
                d-inline-block
              "
              :href="ev.eventLink"
            >
              {{ ev.linkDisplayName }} <i class="fas fa-external-link-alt"></i>
            </a>

            <div class="download mt-2" v-if="ev.fileDisplayName!='null'">
              <span class="download text-uppercase download__text"
                >click here to download</span
              >
              <a
                :href="ev.fileLink"
                class="d-block font-weight-bold download__link text-uppercase"
                download
                >{{ ev.fileDisplayName }}</a
              >
            </div>

            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.events {
  &__card {
    background: color(white);
    border: 1px solid color(gray4);
    @include shadow(cardShadow);
  }
  &__month {
    right: -20px;
    top: -20px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: color(white);
    background-color: color(primary);

    font-size: 16px;
  }
  &__body {
    padding: 20px;
  }
  &__title {
    font-size: 18px;
  }
  &__desc{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition:font-size .3s linear ;
  }
   &__desc:hover{
     cursor: text;
     white-space: normal;
     word-wrap: break-word;
     text-overflow: initial;
     font-size: 20px;
     font-weight: bold;
   }
  .download {
    &__text {
      color: color(gray8);
    }
    &__link {
      font-size: 18px;
      &:hover {
        text-decoration: underline !important;
        color: color(primary);
      }
    }
  }

  &__con {
    font-size: 18px;
    text-decoration: underline !important;
    padding: 20px;
    &:hover {
      color: color(primary);
    }
  }
}
</style>

<script>
export default {
  name: "Events",
  data() {
    return {
      events: [],
      loadData: false,
    };
  },
  methods: {
    getEvents() {
      this.axios.get("/api/AppAdmin/Events/Get").then((res) => {
        this.events = res.data.data;
        this.loadData = true;
      });
    },
  },
  mounted() {this.getEvents();},
  created() {
    this.$newshub.$on("unsee-count-changed", this.getEvents);
  },
};
</script>
