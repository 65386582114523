<template>
  <div class="dashboard pageCon" :class="{ blured: $store.state.blured }">
    <h1 class="text-center dashboard__h1">Welcome back to the dashboard</h1>

    <!-- display before loading data  -->
    <div class="row mt-5" v-if="loadData">
      <div
        class="col-lg-3 col-md-6 mb-4"
        v-for="(a, i) in new Array(8)"
        :key="i"
      >
        <div class="load" style="height: 140px"></div>
      </div>
    </div>
    <!--display after data loading -->
    <div class="row search-bar">
      <div class="col-md-2 mb-3 mb-md-0">
        <label>Search Parameter</label>
        <select class="custom-select" @change="searchParamSelected($event)">
          <option value="A">Any</option>
          <option value="ID">ID</option>
          <option value="EMAIL">Email</option>
          <option value="CMP">Company</option>
          <option value="UN">UserName</option>
          <option value="FN">FirstName</option>
          <option value="FL">lastName</option>
        </select>
      </div>
      <div class="form-group col-md-7">
        <label>Search Value</label>
        <input
          :placeholder="searchPH"
          type="search"
          class="form-control"
          v-model="searchValue"
          @search="performSerch"
        />
      </div>
      <div class="col-md-2 mb-3 mb-md-0">
        <label>Sort By</label>
        <select class="custom-select" @change="sortParamSelected($event)">
          <option value="REGNO" selected>Registration New-Old</option>
          <option value="REGON">Registration Old-New</option>
          <option value="CMPAZ">Companies A-Z</option>
          <option value="EMAILAZ">Emails A-Z</option>
        </select>
      </div>

      <div class="col-md-1 mb-md-0" style="margin-top:32px ;">
        <div class="btn-group btn-block">
          <button
            class="btn btn-primary btn-lg rounded"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-cogs"></i>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="https://portal.eg.sacoshipping.com/api/AppAdmin/AppAccounts/ExportRegisteredUsers">Export Registered Users</a>
            <a class="dropdown-item" href="https://portal.eg.sacoshipping.com/api/AppAdmin/AppAccounts/ExportPendingUsers">Export Pending Accounts</a>
            <a class="dropdown-item" href="https://portal.eg.sacoshipping.com/api/AppAdmin/AppAccounts/ExportWorkingCompanies">Export Working Companies</a>
            <a class="dropdown-item" href="https://portal.eg.sacoshipping.com/api/AppAdmin/AppAccounts/ExportLoadedCompanies">Export Loaded Companies</a>
          </div>
        </div>
      </div>
    </div>
    <!--Quick info-->
    <div class="mt-3">
      <span
        class="mr-3 font-weight-bold badge badge-info"
        style="font-size: 15px"
        >Loaded Pages {{ currentPage }}/{{ lastPage }}</span
      >
      <span
        class="mr-3 font-weight-bold badge badge-info"
        style="font-size: 15px"
        >All users count : {{ totalUsers }}</span
      >
      <span class="font-weight-bold badge badge-info" style="font-size: 15px"
        >All companies count : {{ totalCompanies }}</span
      >
    </div>
    <div v-if="!loadData">
      <div class="row mt-5" v-if="!fadeInfo">
        <div
          class="col-lg-3 mb-4 animate__animated animate__bounceIn"
          v-for="(a, i) in acc"
          :key="i"
        >
          <div
            @click="accountData(a.id)"
            class="dashboard__card text-center position-relative"
          >
            <h4 style="margin-top: 10px; font-weight: bold">
              {{ a.clientAdmin ? "Admin" : "User" }}
            </h4>
            <span
              class="position-absolute rounded-circle"
              :class="{ status: a.status, 'status status--offline': !a.status }"
            ></span>
            <img :src="a.imagePath" width="100" />
            <h4 style="margin-top: 10px">
              {{ a.companyName }}
            </h4>

            <h5
              class="
                dashboard__title
                mt-2
                d-flex
                align-items-center
                justify-content-center
              "
            >
              {{ a.displayName }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <clientInfo :id="id" @back="closeClientInfo()" v-if="fadeInfo"></clientInfo>

    <div v-if="acc.length" v-observe-visibility="visibilityChanged"></div>
  </div>
</template>

<style lang="scss" scoped>
.custom-select {
  height: 47px;
}
.dashboard {
  overflow: hidden;
  &__h1 {
    font-size: 25px;
    @include query(maxS) {
      font-size: 18px;
    }
  }
  &__card {
    padding: 20px;
    @include radius(2px);
    background: color(white);
    @include shadow(cardShadow);
    cursor: pointer;
    .status {
      width: 16px;
      height: 16px;
      background-color: color(green);
      right: 10px;
      top: 10px;
      &--offline {
        background-color: color(gray10);
      }
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 600;
    height: 50px;
  }
}
@for $i from 1 through 10 {
  .col-lg-3:nth-child(#{$i}) {
    -webkit-animation-delay: (#{$i * 0.1s});
    animation-delay: (#{$i * 0.1s});
  }
}
</style>
<script>
function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

import clientInfo from "../../components/admin/client-info.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      showActions: false,
      fadeInfo: false,
      loadData: true,
      searchPH: "Enter an username , email , id or name",
      searchValue: "",
      acc: [],
      searchParam: "A",
      sortParam: "REGNO",
      searchPattern: "A:",
      currentPage: 1,
      lastPage: 0,
      totalUsers: 0,
      totalCompanies: 0,
    };
  },
  components: {
    clientInfo,
  },
  methods: {
    getAccounts() {
      this.searchPattern = this.searchParam + ":" + this.searchValue;
      var apiurl = `api/AppAdmin/AppAccounts`;
      //preparing the request url
      apiurl += this.searchValue
        ? `?search=${this.searchPattern}&pagesize=20&pagenumber=${this.currentPage}`
        : `?pagesize=20&pagenumber=${this.currentPage}`;
      //adding the sorting algo
      apiurl += `&sortby=${this.sortParam}`;
      this.axios
        .get(apiurl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
          if (!this.searchValue || this.sortParam)
            this.acc.push(...res.data.data.data);
          else this.acc = res.data.data.data;

          this.lastPage = res.data.data.totalPages;
          this.totalUsers = res.data.data.totalRecords;
          this.loadData = false;
        });
    },
    accountData(ev) {
      this.fadeInfo = true;
      this.id = ev;
    },
    searchParamSelected(event) {
      this.searchParam = event.target.value;
      switch (this.searchParam) {
        case "ID":
          this.searchPH = "Enter an user ID";
          break;
        case "EMAIL":
          this.searchPH = "Enter an user email";
          break;
        case "CMP":
          this.searchPH = "Enter a company name";
          break;
        case "UN":
          this.searchPH = "Enter an username";
          break;
        case "FL":
          this.searchPH = "Enter an user first last";
          break;
        case "FN":
          this.searchPH = "Enter an user first name";
          break;
        default:
          this.searchPH = "Enter an username , email , id or name";
          break;
      }
    },
    sortParamSelected(event) {
      this.sortParam = event.target.value;
      this.currentPage = 1;
      this.acc = [];
      this.getAccounts();
    },
    visibilityChanged(isVisible) {
      if (!isVisible) return;
      if (this.currentPage >= this.lastPage) return;
      this.currentPage++;
      this.searchPattern = this.searchParam + ":" + this.searchValue;
      this.getAccounts(this.searchPattern, this.sortParam);
    },
    performSerch() {
      this.currentPage = 1;
      this.sortParam = "";
      this.getAccounts(this.sortParam);
    },
    getActiveCompanies() {
      this.axios
        .get("api/AppAdmin/AppAccounts/GetActiveCompanies", {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
          this.totalCompanies = res.data.data;
        });
      },
      closeClientInfo() {
          this.fadeInfo = false;
          this.getAccounts();
      }
  },
  mounted() {
    this.getAccounts();
    this.getActiveCompanies();
  },
};
</script>
