<template>
  <div class="row contact-row mb-3">
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Quantity</label>
      <input
        type="number"
        placeholder="0"
        class="quote__field form-control"
        v-model="val.quantity"
      />
    </div>
    <div class="col-md-6 mb-2 mb-mb-0">
      <label>Container Type</label>
      <select class="quote__field custom-select" v-model="val.fCLContainerType" style="height:45px;">
          <option disabled value="0" selected>Pick The Container Type</option>
          <option value="20 DRY CONTAINER">20 DRY CONTAINER</option>
          <option value="20 DRY VAN">20 DRY VAN</option>
          <option value="20 FLAT RACK COLLAPSIBLE">20 FLAT RACK COLLAPSIBLE</option>
          <option value="20 OPEN TOP">20 OPEN TOP</option>
          <option value="20 PW HC">20 PW HC</option>
          <option value="20 REEFER">20 REEFER</option>
          <option value="20 TANK">20 TANK</option>
          <option value="40 DRY CONTAINER">40 DRY CONTAINER</option>
          <option value="40 DRY VAN">40 DRY VAN</option>
          <option value="40 FLAT RACK COLLAPSIBLE">40 FLAT RACK COLLAPSIBLE</option>
          <option value="40 HIGH CUBE CONTAINER">40 HIGH CUBE CONTAINER</option>
          <option value="40 HIGH CUBE REEFER">40 HIGH CUBE REEFER</option>
          <option value="40 OPEN TOP">40 OPEN TOP</option>
          <option value="40 PW">40 PW</option>
          <option value="40 TANK">40 TANK</option>
          <option value="45 HIGH CUBE">45 HIGH CUBE</option>
          <option value="45 HIGH CUBE REEFER">45 HIGH CUBE REEFER</option>
      </select>
    </div>
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Cargo Weight(KG)</label>
      <input
        type="number"
        placeholder="KG"
        class="quote__field form-control"
        v-model="val.weight"
      />
    </div>
    <div class="col-md-2 d-flex align-items-center">
      <button
        class="btn btn-danger btn-block mt-4"
        @click="$emit('removeCargoDetail', index)"
      >
        <i class="far fa-trash-alt"></i>
        Delete
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["val", "index"],
  created(){
      this.val.fCLContainerType=0;
  }
};
</script>

<style>
.quote__field {
  color: #a9aaae;
  font-size: 17px;
  font-weight: bold;
  border: 1px solid #ddd;
}
.quote__field--require {
  border: 1px solid #e40464 !important;
}
</style>