<template>
  <div class="contact pageCon" :class="{ blured: $store.state.blured }">
    <div class="contact__wrapper">
      <div class="row">
        <div class="col-lg-8 order-2 order-md-1">
          <ul class="list-unstyled" v-if="load">
            <li
              v-for="(v, i) in new Array(4)"
              :key="i"
              class="load mb-3"
              style="width: 200px; height: 25px"
            ></li>
          </ul>
          <!--contact infos -->
          <ul class="list-unstyled contact__info"  v-if="!load">
            <li
              class="contact__item mb-1"
              v-for="(h, i) in randerObj.headers"
              :key="i"
            >
              {{ h }}
            </li>
          </ul>
          <!--/ contact infos -->
        </div>
        <div class="col-lg-4 order-1 order-md-2 mb-3 mb-md-0">
          <div class="load" style='width:200px; height:120px' v-if="load"></div>
          <img v-if="!load" :src="randerObj.img" class="w-100" />
        </div>
      </div>
      <!--/row-->

      <div class="row"  v-if="load">
        <div v-for="(v,i) in new Array(9)" :key="i" class="col-md-4 mb-3">
          <div class="load mb-3" style="height: 25px"></div>
        </div>
      </div>
      <!--start contact emails -->
      <div class="row mt-5"  v-if="!load">
        <div class="col-lg-12">
          <div
            class="d-flex flex-wrap mb-2 contact__email"
            v-for="(con, i) in randerObj.contactDetails"
            :key="i"
          >
            <span class="font-weight-bold d-block">{{ con.employeeName }}</span>
            <span class="d-block">{{ con.contact }}</span>
            <span class="d-block">{{ con.jobTitle }}</span>
          </div>
        </div>
      </div>
      <!--/end contact emails -->



      <div class="load" style='width:300px; height:40px;' v-if="load"></div>
      <!--start Soical media  -->
      <div class="contact__social mt-5"  v-if="!load">
        <ul class="list-unstyled d-flex mb-3 flex-wrap">
          <li class="mr-4">Find us on</li>
          <li v-for="(s, i) in randerObj.social" :key="i">
            <a
              target="_blank"
              v-if="s.display == true"
              :href="'https://' + s.itemLink"
              class="mr-2 d-block"
            >
              <img
                :src="require(`../../assets/imgs/social/${s.itemType}.png`)"
                width="30"
              />
            </a>
          </li>
        </ul>
        <p class="font-weight-bold text-uppercase">SACO SHIPPING EGYPT</p>
      </div>
      <!--End Social media-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact {
  &__wrapper {
    padding: 40px 60px;
    background: color(white);
    @include shadow(cardShadow);
    border: 1px solid color(gray4);
    @include radius(10px);
    @include query(maxS) {
      padding: 20px;
    }
  }
  &__item {
    font-weight: bold;
    font-size: 15px;
  }
  &__email {
    font-size: 15px;
    @include query(maxS) {
      font-size: 14px;
    }
    span:nth-of-type(1) {
      width: 150px;
    }
    span:nth-of-type(2) {
      text-align: left;
      width: 300px;
    }

    span:nth-of-type(3) {
      font-size: 13px;
    }

    span:nth-of-type(1) {
      color: #000;
    }
    span:nth-of-type(2) {
      text-decoration: underline;
    }
  }
  &__social {
    li:first-of-type {
      font-size: 20px;
      color: color(primary2);
      font-weight: bold;
      @include query(maxS) {
        width: 100%;
      }
    }
    p {
      font-size: 22px;
    }
  }
}
</style>
<script>
export default {
  name: "Contact",
  data() {
    return {
      load:true,
      randerObj: {
        img: "",
        social: [],
        headers: [],
        contactDetails: [],
      },
    };
  },
  mounted() {
    this.axios.get("/api/home/Contacts").then((res) => {
      this.load = false;
      this.randerObj.img = res.data.data.image;
      this.randerObj.headers = res.data.data.headers.split(",");
      this.randerObj.social = res.data.data.social;
      this.randerObj.contactDetails = res.data.data.contacts;
    })
  },
};
</script>
