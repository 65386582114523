<template>
  <div>
    <div ref="successalert" hidden></div>
    <DeleteUserPopup
      @close="deletePopup = false"
      v-if="deletePopup"
      :userID="id"
    ></DeleteUserPopup>
    <div
      class="clientInfo animate__animated animate__fadeInUpBig mt-3"
      :class="{ blured: deletePopup }"
    >
      <button
        @click="$emit('back')"
        class="btn back font-weight-bold text-uppercase"
      >
        <i class="fas fa-angle-left text-danger"></i> Back
      </button>
      <div class="clientInfo__wrapper">
        <div class="row" v-if="loadData">
          <div class="col-md-3">
            <div class="load" style="height: 150px"></div>
          </div>
          <div class="col-md">
            <div
              class="load mb-3"
              style="height: 40px"
              v-for="(a, i) in new Array(5)"
              :key="i"
            ></div>
          </div>
        </div>
        <!--Basic info -->
        <div class="basic" v-if="!loadData">
          <div class="d-flex mb-4 justify-content-between">
            <div>
              <span
                :class="{
                  status: status,
                  'status--danger': !status,
                }"
              >
                {{ status ? "online" : "offline" }}
              </span>
            </div>
            <div>
              <button
                class="btn btn-outline-danger border border-round ml-auto"
                @click="deleteUser(id)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn btn-outline-primary border border-round ml-2"
                @click="helpPopup"
              >
                <i class="fas fa-info"></i>
              </button>
            </div>
          </div>
          <div class="row mb-3">
              <div class="col-md-2 text-center text-md-left mb-5 mb-md-0">
                  <div class="d-inline-block">
                      <img width="150" :src="imagePath" />
                  </div>
              </div>
              <div class="border-left col-md">
                  <h2 class="font-weight-bold text-pink mb-3">User Details</h2>
                  <ul class="list-unstyled">
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>First Name :</span>
                          <span>
                              <EditableContent :value="firstname"
                                               class-name="text-editable"
                                               content-key="firstname"
                                               :on-change="onEdit"></EditableContent>
                          </span>
                      </li>
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Last Name:</span>
                          <span>
                              <EditableContent :value="lastname"
                                               class-name="text-editable"
                                               content-key="lastname"
                                               :on-change="onEdit"></EditableContent>
                          </span>
                      </li>
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Email :</span>
                          <span>
                              <EditableContent :value="email"
                                               class-name="text-editable"
                                               content-key="email"
                                               :on-change="onChangeEmail"></EditableContent>
                          </span>
                      </li>

                      <li class="mb-3 d-flex flex-sm-wrap">
                          <span>Password :</span>
                          <div class="text-lowercase" id="password">
                              <input type="password"
                                     class="form-input"
                                     placeholder="reset password"
                                     :readonly="!editPass"
                                     @dblclick="editPass = true"
                                     @focusout="
                        editPass = false;
                        password = '';
                      "
                                     v-model="password"
                                     @keydown.enter="updatePassword" />
                              <ul class="list-unstyled mt-2"
                                  style="font-size: 14px"
                                  v-if="editPass">
                                  <li class="mb-1">
                                      <span :class="[
                            /(?=.*[a-z])/.test(password)
                              ? 'text-success'
                              : 'text-danger',
                          ]">
                                          <span v-show="/(?=.*[a-z])/.test(password)">
                                              <i class="fas fa-check-circle"></i>
                                          </span>
                                          <span v-show="!/(?=.*[a-z])/.test(password)">
                                              <i class="fas fa-times-circle"></i>
                                          </span>
                                          1 small character at least
                                      </span>
                                  </li>

                                  <li class="mb-1">
                                      <span :class="[
                            /(?=.*[A-Z])/.test(password)
                              ? 'text-success'
                              : 'text-danger',
                          ]">
                                          <span v-show="/(?=.*[A-Z])/.test(password)">
                                              <i class="fas fa-check-circle"></i>
                                          </span>
                                          <span v-show="!/(?=.*[A-Z])/.test(password)">
                                              <i class="fas fa-times-circle"></i>
                                          </span>
                                          1 capital character at least
                                      </span>
                                  </li>

                                  <li class="mb-1">
                                      <span :class="[
                            /(?=.*\d)/.test(password)
                              ? 'text-success'
                              : 'text-danger',
                          ]">
                                          <span v-show="/(?=.*\d)/.test(password)">
                                              <i class="fas fa-check-circle"></i>
                                          </span>
                                          <span v-show="!/(?=.*\d)/.test(password)">
                                              <i class="fas fa-times-circle"></i>
                                          </span>
                                          one number at least
                                      </span>
                                  </li>

                                  <li class="mb-1">
                                      <span :class="[
                            /\W/g.test(password)
                              ? 'text-success'
                              : 'text-danger',
                          ]">
                                          <span v-show="/\W/g.test(password)">
                                              <i class="fas fa-check-circle"></i>
                                          </span>
                                          <span v-show="!/\W/g.test(password)">
                                              <i class="fas fa-times-circle"></i>
                                          </span>
                                          one Symbol
                                      </span>
                                  </li>

                                  <li class="mb-1">
                                      <span :class="[
                            regPass.test(password)
                              ? 'text-success'
                              : 'text-danger',
                          ]">
                                          <span v-show="regPass.test(password)">
                                              <i class="fas fa-check-circle"></i>
                                          </span>
                                          <span v-show="!regPass.test(password)">
                                              <i class="fas fa-times-circle"></i>
                                          </span>
                                          should contain at least 8 from the mentioned
                                          Characters
                                      </span>
                                  </li>
                              </ul>
                          </div>
                      </li>

                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Phone :</span>
                          <span>
                              <EditableContent :value="phoneNumber"
                                               class-name="text-editable"
                                               content-key="phoneNumber"
                                               :on-change="onEdit"></EditableContent>
                          </span>
                      </li>
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Address:</span>
                          <span>
                              <EditableContent :value="address"
                                               class-name="text-editable"
                                               content-key="address"
                                               :on-change="onEdit"></EditableContent>
                          </span>
                      </li>
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Position :</span>
                          <span>
                              <EditableContent :value="position"
                                               class-name="text-editable"
                                               content-key="position"
                                               :on-change="onEdit"></EditableContent>
                          </span>
                      </li>
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Role :</span>
                          <span>
                              <select id="role"
                                      class="un-style-select font-weight-bold"
                                      :class="selectStyle"
                                      @focus="selectStyle = 'custom-select'"
                                      @blur="selectStyle = ''"
                                      @change="editPermissoins">
                                  <option :selected="role" value="admin">Admin</option>
                                  <option :selected="!role" value="user">User</option>
                              </select>
                          </span>
                      </li>
                      <li class="mb-3" v-if="!role">
                          <div class="mb-2">
                              <div class="
                        manageUser__checkBox
                        d-flex
                        justify-content-between
                      ">
                                  <label for="full" class="manageUser__label">full access</label>
                                  <div class="custom-control custom-checkbox">
                                      <input type="checkbox"
                                             id="full"
                                             v-model="fullAccess"
                                             class="custom-control-input"
                                             @click="togglePermission('full')" />
                                      <label for="full"
                                             class="custom-control-label pl-4 pt-2"></label>
                                  </div>
                              </div>

                              <div class="
                        manageUser__checkBox
                        d-flex
                        justify-content-between
                      ">
                                  <label for="ship" class="manageUser__label">shipments</label>
                                  <div class="custom-control custom-checkbox">
                                      <input type="checkbox"
                                             id="ship"
                                             class="custom-control-input"
                                             v-model="shipments"
                                             @click="togglePermission('shipments')" /><label for="ship"
                                                                                             class="custom-control-label pl-4 pt-2"></label>
                                  </div>
                              </div>
                              <div class="
                        manageUser__checkBox
                        d-flex
                        justify-content-between
                      ">
                                  <label for="pay" class="manageUser__label">
                                      payments
                                  </label>
                                  <div class="custom-control custom-checkbox">
                                      <input type="checkbox"
                                             id="pay"
                                             class="custom-control-input"
                                             v-model="payment"
                                             @click="togglePermission('payment')" /><label for="pay"
                                                                                           class="custom-control-label pl-4 pt-2"></label>
                                  </div>
                              </div>
                              <div class="
                        manageUser__checkBox
                        d-flex
                        justify-content-between
                      ">
                                  <label for="booking" class="manageUser__label">
                                      booking
                                  </label>
                                  <div class="custom-control custom-checkbox">
                                      <input type="checkbox"
                                             id="booking"
                                             class="custom-control-input"
                                             v-model="booking"
                                             @click="togglePermission('booking')" /><label for="booking"
                                                                                           class="custom-control-label pl-4 pt-2"></label>
                                  </div>
                              </div>
                              <button class="btn btn-outline-info col-md-12 mt-2"
                                      @click="savePermissions">
                                  Save
                              </button>
                          </div>
                      </li>

                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>last online date :</span>
                          <span>
                              {{
                      lastOnline.slice(0, lastOnline.indexOf("T")) ==
                      "0001-01-01"
                        ? "Not logged in yet"
                        : lastOnline.slice(0, lastOnline.indexOf("T"))
                              }}
                          </span>
                      </li>

                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>online payment :</span>
                          <span :class="{
                      'text-danger': !payemntStatus,
                      'text-success': payemntStatus,
                    }">{{ payemntStatus ? "Active" : "Unactive" }}</span>
                      </li>

                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Creation Date :</span>
                          <span class="text-lowercase">
                              {{
                    creationDate.slice(0, creationDate.indexOf("T"))
                              }}
                          </span>
                      </li>
                  </ul>
              </div>
              <!--TRACKING COMPANIES Details-->
              <div class="border-left col-md">
                  <h2 class="font-weight-bold text-pink mb-3">
                      Tracking Companies
                  </h2>

                  <ul class="list-unstyled">
                      <li class="mb-3 d-flex align-items-center">
                          <span>Main Company :</span>
                          <multiselect v-model="mainCompany"
                                       :options="Companies"
                                       :multiple="false"
                                       :close-on-select="true"
                                       :clear-on-select="false"
                                       :preserve-search="true"
                                       placeholder="Pick Main Company"
                                       label="companyTitle"
                                       track-by="companyID"
                                       :preselect-first="false"
                                       @select="loadCompanies()">
                          </multiselect>
                      </li>
                      <li class="mb-3 d-flex align-items-center justify-content-between">
                          <span>Related Entities :</span>
                          <multiselect v-model="userEntities"
                                       :options="Companies"
                                       :multiple="true"
                                       :close-on-select="false"
                                       :clear-on-select="false"
                                       :preserve-search="true"
                                       placeholder="Pick Related Companies"
                                       label="companyTitle"
                                       track-by="companyID"
                                       :preselect-first="false"
                                       @select="loadCompanies()">
                          </multiselect>
                      </li>
                      <li >
                          <button class="btn btn-outline-info btn-block" @click="updateEntities()">
                              Save Tracking Companies
                          </button>
                      </li>
                  </ul>
              </div>
          </div>

          <div class="d-flex justify-content-end">
            <div>
              <button
                :disabled="spinner"
                v-on:click.prevent="loginAsUser"
                class="btn btn-primary btn-block"
                style="border-radius: 25px; width: 200px"
              >
                Login as
                <div
                  class="spinner-border text-secondary d-inline-block"
                  role="status"
                  style="width: 1rem; height: 1rem"
                  v-if="spinner"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <!--/Basic info -->
        <!--Shipments  -->
        <div class="shipments mt-5 d-none">
          <h2 class="shipments__title">Shipments</h2>

          <!--live -->
          <ul class="list-unstyled mt-5 live pb-2">
            <li class="d-flex align-items-center mb-3">
              <span class="d-inline-block mr-2">id</span>
              <p class="mr-4">454654564</p>
              <span class="status status--danger"></span>
              <h5 class="font-weight-bold">live</h5>
            </li>
            <li class="d-flex align-items-center mb-3">
              <span class="d-inline-block mr-2">id</span>
              <p class="mr-4">454654564</p>
              <span class="status status--danger"></span>
              <h5 class="font-weight-bold">live</h5>
            </li>
          </ul>
          <!--/live -->
          <!--delivered -->
          <ul class="list-unstyled delivered mt-4">
            <li class="d-flex align-items-center mb-3">
              <span class="d-inline-block mr-2">id</span>
              <p class="mr-4">454654564</p>
              <span class="status"></span>
              <h5 class="font-weight-bold">Delivered</h5>
            </li>
            <li class="d-flex align-items-center mb-3">
              <span class="d-inline-block mr-2">id</span>
              <p class="mr-4">454654564</p>
              <span class="status"></span>
              <h5 class="font-weight-bold">Delivered</h5>
            </li>
            <li class="d-flex align-items-center mb-3">
              <span class="d-inline-block mr-2">id</span>
              <p class="mr-4">454654564</p>
              <span class="status"></span>
              <h5 class="font-weight-bold">Delivered</h5>
            </li>
          </ul>
          <!--/ delivered -->

          <footer
            class="
              d-flex
              justify-content-center justify-content-md-between
              mt-5
              flex-wrap
            "
          >
            <button class="btn history text-primary mb-3 mb-md-0">
              shipments history
            </button>

            <button class="btn btn-p">Save</button>
          </footer>
        </div>
        <!-- / Shipments -->
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.info-list {
  padding: 0 10%;
  list-style: lower-roman;
  text-align: start;
  font-family: cursive;
}
.manageUser {
  &__passValid {
    font-size: 13px;
  }

  &__wrapper {
    width: 800px;
    margin: 50px auto 0;
    background: color(white);
    @include radius(10px);
    padding: 40px;
    @include shadow(cardShadow);

    @include query(maxL) {
      padding: 20px;
      width: 100%;
    }
  }

  &__input {
    background: color(body);
    border: 1px solid color(gray4);
    @include radius(30px);

    &--danger {
      border: 1px solid color(primary);
    }
  }

  &__btn:disabled {
    background-color: #333;
  }

  &__label {
    color: color(gray10);
    padding-left: 0;
  }

  &__checkBox {
    @include query(maxM) {
      width: calc(100% / 4);
    }

    @include query(maxS) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.custom-control-label::before,
.custom-control-label::after {
  background-color: color(body) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: color(body) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}

.un-style-select {
  border: none;
  appearance: none;
}

.text-size-20 {
  font-size: 20px;
}

.text-pink {
  color: #e40464;
}

.clientInfo {
  overflow: hidden;

  &__wrapper {
    background: color(white);
    padding: 40px;

    @include query(maxM) {
      padding: 20px;
    }
  }

  span.status {
    &:before {
      content: "";
      width: 13px;
      height: 13px;
      background: color(green);
      display: inline-block;
      @include radius(50%);
      margin-right: 4px;
      position: relative;
      top: 0px;
    }

    &--danger {
      &::before {
        content: "";
        width: 13px;
        height: 13px;
        display: inline-block;
        @include radius(50%);
        margin-right: 4px;
        position: relative;
        top: 0px;
        background: color(gray10);
      }
    }
  }

  .basic {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
    color: color(gray8);

    span {
      width: 50%;
    }

    span:first-of-type {
      color: color(text);
    }

    .uploadPic label {
      text-decoration: underline;
      color: color(text);
      cursor: pointer;
    }

    &__name {
      color: color(text);
      font-size: 18px;
    }

    input {
      width: 300px;
      background-color: color(body);
      border: none;

      @include query(maxL) {
        width: 100%;
      }
    }
  }
}

.shipments {
  &__title {
    font-weight: bold;
    text-transform: uppercase;
  }

  li {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;

    span {
      color: color(gray10);
    }

    p {
      color: color(gray8);
    }
  }

  .live {
    border-bottom: 2px solid color(borderGray2);
  }

  .delivered {
    margin-top: 25px;
  }

  .history {
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: underline;

    &:hover {
      color: color(text);
      text-decoration: none;
    }
  }
}
</style>

<script>
    import DeleteUserPopup from "./DeleteUserPopup.vue";
    import EditableContent from "../EditableContent.vue";

    import Vue from "vue";
    import Multiselect from "vue-multiselect";

    export default {
        props: ["id"],
        name: "clientInfo",
        data() {
            return {
                regPass:
                    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                editPass: false,
                password: "",
                Companies: [],
                userEntities: [],
                deletePopup: false,
                loadData: true,
                spinner: false,
                firstname: "",
                lastname: "",
                email: "",
                phoneNumber: "",
                address: "",
                position: "",
                status: true,
                username: "",
                payemntStatus: "",
                lastOnline: "",
                creationDate: "",
                imagePath: "",
                mainCompany: [],
                selectStyle: "",
                role: false,
                fullAccess: false,
                booking: false,
                shipments: false,
                payment: false,
            };
        },
        methods: {
            helpPopup() {
                this.$swal({
                    icon: "info",
                    title: "How to update the user details ?",
                    html: `<ul style=" padding: 0 10%;
    text-align: start;
    font-family: cursive;">

        <li>Double click a field's value <i class="fas fa-mouse ml-2 text-primary"></i> </li>
        <li>Edit the value <i class="fas fa-pen text-warning ml-2"></i></li>
        <li>Click the green checked button<i class="fas fa-check ml-2" style="color: green"></i></li>
        <li>Awesome ! <i class="fas fa-hand-peace ml-2"></i></li>
        
        </ul>`,
                });
            },
            onEdit(target, newVal) {
                this[target] = newVal;
                if (this.validateForm(target)) {
                    this.axios
                        .put(
                            `api/AppAdmin/AppAccounts/UpdateInfo?field=${target}&value=${newVal}&userid=${this.id}`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${this.$store.state.token}`,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 202) {
                                this.$swal({
                                    icon: "success",
                                    title: "Field Updated",
                                    text: `* ${target} * has updated successfully!`,
                                });
                            }
                        })
                        .catch((err) => {
                            let data = err.response.data;
                            this.$swal("Couldn't update the field", `${data.message}`, "error");
                        });
                }
            },
            endEdit(evt) {
                let src = evt.target.id;
                this.$el.querySelector(`#${src}`).blur();
            },
            onChangeEmail(target, newVal) {
                //pre
                let emailreg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                this.email = newVal;
                //checking
                if (!this.email || !emailreg.test(this.email)) {
                    this.formValid = false;
                    this.$swal("Error", "invalid email format", "error");
                } else {
                    //preparing the request
                    this.axios
                        .put(
                            `api/AppAdmin/AppAccounts/UpdateEmail?email=${this.email}&userid=${this.id}`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${this.$store.state.token}`,
                                },
                            }
                        )
                        .then((res) => {
                            //in success
                            if (res.status === 202) {
                                this.$swal(
                                    "Field Updated",
                                    `* email * has updated successfully!`,
                                    "success"
                                );
                            }
                        })
                        .catch((err) => {
                            //in error
                            let data = err.response.data;
                            this.$swal("Couldn't update the field", `${data.message}`, "error");
                        });
                }
            },
            updatePassword(evt) {
                if (this.regPass.test(this.password)) {
                    //preparing the request
                    this.axios
                        .put(
                            `api/AppAdmin/AppAccounts/ResetPassword?field=password&value=${this.password}&userid=${this.id}`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${this.$store.state.token}`,
                                },
                            }
                        )
                        .then((res) => {
                            //in success
                            if (res.status === 202) {
                                this.$swal(
                                    "Field Updated",
                                    `* password * has updated successfully!`,
                                    "success"
                                );
                            }
                        })
                        .catch((err) => {
                            //in error
                            let data = err.response.data;
                            this.$swal("Couldn't update the field", `${data.message}`, "error");
                        });
                    evt.target.blur();
                }
            },
            clientInfo() {
                this.axios
                    .get(`api/AppAdmin/AppAccounts?search=ID:${this.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        let infos = res.data.data.data;
                        //user data
                        this.firstname = infos[0].firstName;
                        this.lastname = infos[0].lastName;
                        this.email = infos[0].email;
                        this.phoneNumber = infos[0].phoneNumber;
                        this.address = infos[0].address;
                        this.position = infos[0].position;
                        this.imagePath = infos[0].imagePath;
                        this.creationDate = infos[0].creationDate;
                        this.lastOnline = infos[0].lastOnline;
                        this.payemntStatus = infos[0].payemntStatus;
                        this.username = infos[0].username;
                        this.status = infos[0].status;
                        //permissions data
                        this.role = infos[0].clientAdmin;
                        this.booking = infos[0].booking;
                        this.shipments = infos[0].shipments;
                        this.payment = infos[0].payment;
                        this.fullAccess = this.booking && this.shipments && this.payment;
                        //company data
                        this.mainCompany = { companyID: infos[0].companyID, companyTitle: infos[0].companyName };
                        this.userEntities = [];
                        infos[0].entities.forEach((cmp) => { this.userEntities.push({ companyID: cmp.companyID, companyTitle: cmp.companyName }); });
                        this.loadCompanies();
                        this.loadData = false;
                    });
            },
            loginAsUser() {
                this.spinner = true;
                this.axios
                    .post(
                        `/api/AppAdmin/AppAccounts/SignInAS?Email=${this.email}&Username=${this.username}&UserID=${this.id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.token}`,
                            },
                        }
                    )
                    .then((res) => {
                        setTimeout(() => {
                            localStorage.removeItem("token");
                            localStorage.removeItem("userObject");
                            localStorage.removeItem("roles");
                            localStorage.setItem("userObject", JSON.stringify(res.data));
                            localStorage.setItem("token", res.data.token);
                            localStorage.setItem("roles", res.data.roles[0]);
                            if (res.data.roles[0] == "AppAdmin") {
                                this.$router.push({ name: "AdminDashboard" });
                            } else {
                                this.$router.push({ name: "Home" });
                            }
                        }, 2000);
                    })
                    .catch((err) => {
                        if (err) {
                            console.log(err);
                        }
                    });
            },
            deleteUser(userid) {
                this.deletePopup = true;
            },
            loadCompanies() {
                this.axios
                    .get("api/AppAdmin/Companies", {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        const receivedcmps = res.data.data;
                        let filteredCompanies = []
                        if (this.userEntities.length != 0) {
                            const entitiesset = new Set(this.userEntities);
                            filteredCompanies = receivedcmps.filter((cmp) => !entitiesset.has(cmp.companyID));
                        }
                        else {
                            filteredCompanies = receivedcmps;
                        }
                        filteredCompanies = filteredCompanies.filter((cmp) => cmp.companyID != this.mainCompany.companyID);
                        this.Companies = filteredCompanies;
                    });
            },
            validateForm(field) {
                if (!this.loadData) {
                    //prereqs:
                    let errormsg = "";
                    let isvalid = true;
                    switch (field) {
                        case "firstname": //firstname
                            if (!this.firstname || this.firstname.length < 3) {
                                isvalid = false;
                                errormsg = "first name length must be more than 3 letters";
                            }
                            break; //lastname
                        case "lastname":
                            if (!this.lastname || this.lastname.length < 3) {
                                isvalid = false;
                                errormsg = "last name length must be more than 3 letters";
                            }
                            break;
                        case "phoneNumber": //phone
                            if (!this.phoneNumber || this.phoneNumber.length < 5) {
                                isvalid = false;
                                errormsg =
                                    "phone number is a required field & must be morethan 5 numbers";
                            }
                            break;
                        case "address": //address
                            if (this.address && this.address.length < 4) {
                                isvalid = false;
                                errormsg = "address should be at least 4 characters";
                            }
                            break;
                        case "position": //position
                            if (this.position && this.position.length < 2) {
                                isvalid = false;
                                errormsg = "position should be at least 2 characters";
                            }
                            break;
                    }
                    if (!isvalid) {
                        this.$swal("Error", errormsg, "error");
                    }

                    return isvalid;
                }
            },
            togglePermission(field) {
                if (field === "full") {
                    this.fullAccess = !this.fullAccess;
                } else this[field] = !this[field];
            },
            editPermissoins(evt) {
                let value = evt.target.value;
                this.role = value === "admin";
                this.booking = this.shipments = this.payment = this.role;
                if (this.role) this.savePermissions();
            },
            savePermissions() {
                let reqbody = {
                    userId: this.id,
                    isAdmin: this.role,
                    booking: this.booking,
                    shipments: this.shipments,
                    payment: this.payment,
                };
                this.axios
                    .put(`api/AppAdmin/AppAccounts/UpdateRoles`, reqbody, {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 202) {
                            this.$swal(
                                "Field Updated",
                                `* roles * has updated successfully!`,
                                "success"
                            );
                        }
                    })
                    .catch((err) => {
                        let data = err.response.data;
                        this.$swal("Couldn't update the field", `${data.message}`, "error");
                    });
            },
            scrollTop() {
                let successalert = this.$refs.successalert;
                window.scrollTo({ top: successalert.offsetTop, behavior: "smooth" });
            },
            addOption(Option) {
                this.userEntities.push(Option);
            },
            removeOption(Option) {
                this.userEntities.splice(this.userEntities.indexOf(Option), 1);
                if (!this.Companies.includes(Option)) this.Companies.push(Option);
            },
            updateEntities() {
                if (this.mainCompany) {
                    //preparing the request
                    this.axios
                        .put(
                            `api/AppAdmin/AppAccounts/UpdateEntities`,
                            {
                                userID: this.id,
                                mainCompany: this.mainCompany,
                                entities: this.userEntities
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${this.$store.state.token}`,
                                },
                            }
                        )
                        .then((res) => {
                            //in success
                            if (res.status === 202) {
                                this.clientInfo();
                                this.$swal(
                                    "Entities Updated",
                                    `* Entities * has updated successfully!`,
                                    "success"
                                );
                            }
                        })
                        .catch((err) => {
                            //in error
                            let data = err.response.data;
                            this.$swal("Couldn't update the entities", `${data.message}`, "error");
                        });
                }
            }
        },
        watch: {
            fullAccess() {
                this.booking = this.shipments = this.payment = this.fullAccess;
            },
        },
        components: {
            DeleteUserPopup,
            EditableContent,
        },
        created() {
            this.clientInfo();
        },
    };
</script>
