<template>
  <div class="row contact-row mb-3">
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Quantity</label>
      <input
        type="number"
        placeholder="0"
        class="quote__field form-control"
        v-model="val.quantity"
      />
    </div>
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Length(CM)</label>
      <input
        type="number"
        placeholder="CM"
        class="quote__field form-control"
        v-model="val.length"
      />
    </div>
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Width(CM)</label>
      <input
        type="number"
        placeholder="CM"
        class="quote__field form-control"
        v-model="val.width"
      />
    </div>
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Height(CM)</label>
      <input
        type="number"
        placeholder="CM"
        class="quote__field form-control"
        v-model="val.height"
      />
    </div>
    <div class="col-md-2 mb-2 mb-mb-0">
      <label>Weight(KG)</label>
      <input
        type="number"
        placeholder="KG"
        class="quote__field form-control"
        v-model="val.weight"
      />
    </div>
    <div class="col-md-2 d-flex align-items-center">
      <button
        class="btn btn-danger btn-block mt-4"
        @click="$emit('removeCargoDetail', index)"
      >
        <i class="far fa-trash-alt"></i>
        Delete
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["val", "index"],
};
</script>

<style>
.quote__field {
    color: #a9aaae;
    font-size: 17px;
    font-weight: bold;
    border: 1px solid #ddd;
}
.quote__field--require{
    border: 1px solid #e40464 !important;
}
</style>