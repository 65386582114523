<template>
  <div class="terms pageCon" :class="{ blured: $store.state.blured }">
    <div class="terms">
      <div class="terms__wrapper mb-4" id="privacy">
        <h3
          class="
            font-weight-bold
            d-flex
            justify-content-between
            align-items-center
            animate__animated animate__fadeIn
          "
          @click="(col1 = !col1), (col2 = false)"
        >
          <span>Privacy Policy</span>
          <span v-show="!col1">
            <i class="fas fa-plus"></i>
          </span>
          <span v-show="col1">
            <i class="fas fa-minus"></i>
          </span>
        </h3>
        <ul :class="{ open: col1 }" class="list-unstyled">
          <li>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about your privacy rights and how the law
            protects You
          </li>
          <li>
            We use Your Personal data to provide and improve the Service. By
            using the Service, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </li>
          <li>
            <h5 class="font-weight-bold">Interpretation and Definitions</h5>
            <p>Interpretation</p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </li>
          <li>
            <p>Definitions</p>
            For the purposes of this Privacy Policy:
          </li>
          <li>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </li>
          <li>
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to Saco Shipping Egypt, 8 Patrice Lumumba
            Street.
          </li>
          <li>
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </li>
          <li>Country refers to: Egypt</li>
          <li>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digitaltablet.
          </li>
          <li>
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </li>
          <li>
            <h5 class="font-weight-bold">Service refers to the Website.</h5>
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </li>
          <li>
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit)
          </li>
          <li>
            Website refers to Saco Shipping Egypt, accessible from
            https://portal.eg.sacoshipping.com/
          </li>
          <li>
            You mean the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </li>
          <li>
            <p>Collecting and Using Your Personal Data</p>
            <p>Types of Data Collected</p>
          </li>
          <li>
            <p>Personal Data</p>
            While using Our Service, we may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally, identifiable information may include, but
            is not limited to:
          </li>
          <li>
            <p>Email address</p>
            <p>First name and last name</p>
            <p>Phone number, Mobile number</p>
            <p>Address, State, Province, ZIP/Postal code, City</p>
            <p>Company Name, Position</p>
          </li>
          <li>
            <h5 class="font-weight-bold">Usage Data</h5>
            Usage Data is collected automatically when using the Service.
          </li>
          <li>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </li>
          <li>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </li>
          <li>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </li>
          <li>
            <p>Tracking Technologies and Cookies</p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </li>
          <li>
            <p>
              Cookies or Browser Cookies. A cookie is a small file placed on
              Your Device. You can instruct Your browser to refuse all Cookies
              or to indicate when a Cookie is being sent. However, if You do not
              accept Cookies, You may not be able to use some parts of our
              Service. Unless you have adjusted Your browser setting so that it
              will refuse Cookies, our Service may use Cookies.
            </p>
            <p>
              Flash Cookies. Certain features of our Service may use local
              stored objects (or Flash Cookies) to collect and store information
              about Your preferences or Your activity on our Service. Flash
              Cookies are not managed by the same browser settings as those used
              for Browser Cookies. For more information on how You can delete
              Flash Cookies, please read "Where can I change the settings for
              disabling, or deleting local shared objects?" available at
              https://helpx.adobe.com/flash-player/kb/disable-local-shared-objectsflash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_
              objects_
            </p>
            <p>
              Web Beacons. Certain sections of our Service and our emails may
              contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit
              the Company, for example, to count users who have visited those
              pages or opened an email and
            </p>
            <p>
              for other related website statistics (for example, recording the
              popularity of a certain section and verifying system and server
              integrity).
            </p>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. Learn more about cookies: What Are Cookies?.
            </p>
          </li>
          <li>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </li>
          <li>Necessary / Essential Cookies</li>
          <li>Type: Session Cookies</li>
          <li>Administered by: Us</li>
          <li>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </li>
          <li>Cookies Policy / Notice Acceptance Cookies</li>
          <li>Type: Persistent Cookies</li>
          <li>Administered by: Us</li>
          <li>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </li>
          <li>Functionality Cookies</li>
          <li>Type: Persistent Cookies</li>
          <li>Administered by: Us</li>
          <li>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </li>
          <li>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy
          </li>
          <li>
            <p>Use of Your Personal Data</p>
            The Company may use Personal Data for the following purposes:
          </li>
          <li>
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </li>
          <li>
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user
          </li>
          <li>
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </li>
          <li>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li>
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </li>
          <li>
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </li>
          <li>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your
            experience.
          </li>
          <li>
            We may share Your personal information in the following situations:
          </li>
          <li>
            <p>
              We may share Your personal information in the following
              situations:
            </p>
            <p>
              For business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of Our business to another company.
            </p>
            <p>
              With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </p>
            <p>
              With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
            </p>
            <p>
              With other users: when You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside.
            </p>
            <p>
              With Your consent: We may disclose Your personal information for
              any other purpose with Your consent.
            </p>
            <p>Retention of Your Personal Data</p>
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
          </li>
          <li>
            <p>Transfer of Your Personal Data</p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction
          </li>
          <li>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </li>
          <li>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </li>
          <li>
            <p>Disclosure of Your Personal Data</p>
            <p>Business Transactions</p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </li>
          <li>
            <p>Law enforcement</p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </li>
          <li>
            <p>Other legal requirements</p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </li>
          <li>
            <p>Comply with a legal obligation</p>
            <p>Protect and defend the rights or property of the Company</p>
            <p>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </p>
            <p>
              Protect the personal safety of Users of the Service or the public
            </p>
            <p>Protect against legal liability</p>
            <p>Security of Your Personal Data</p>
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
          </li>
          <li>
            <p>Children's Privacy</p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, we take steps to remove that information from Our servers.
          </li>
          <li>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, we may
            require Your parent's consent before We collect and use that
            information.
          </li>
          <li>
            <p>Links to Other Websites</p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </li>
          <li>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </li>
          <li>
            <p>Changes to this Privacy Policy</p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </li>
          <li>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </li>
          <li>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </li>
          <li>
            <p>Contact Us</p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </li>
          <li>By email: info@eg.sacoshipping.com</li>
          <li>
            By visiting this page on our website:
            https://portal.eg.sacoshipping.com/
          </li>
        </ul>
      </div>

      <div class="terms__wrapper" id="refund">
        <h3
          class="
            font-weight-bold
            d-flex
            justify-content-between
            align-items-center
            animate__animated animate__fadeIn
          "
          @click="(col2 = !col2), (col1 = false)"
        >
          <span>Refund policy</span>
          <span v-show="!col2">
            <i class="fas fa-plus"></i>
          </span>
          <span v-show="col2">
            <i class="fas fa-minus"></i>
          </span>
        </h3>
        <ul :class="{ open: col2 }" class="list-unstyled">
          <li>
            SACO Shipping Egypt Financial Department will not record, process or
            store sensitive cardholder data,
          </li>
          <li>
            Any personally identifiable data associated with the cardholder, to
            include account number, expiration date, name, address, card number,
            card service verification code, or any other data stored on the
            magnetic stripe of the payment card are only accessible to the
            Customer / Person who are responsible for processing or facilitating
            Payment card transactions.
          </li>
          <li>
            Insecure transmission of cardholder data is prohibited. Cardholder
            data can only be transmitted via approved encryption protocols and
            methods, which may change over time due to newly discovered security
            vulnerabilities.
          </li>
          <li>
            To place a refund, return or exchange request for an invoice paid at
            our portal You will need to contact SACO Shipping Egypt via email at
            epayment@eg.sacoshipping.com, or contact Accounts team by phone at
            03 3991050
          </li>
          <li>
            In most cases, no refund will be granted once the payment is done.
            However, exceptions may be made based on the following
            circumstances:
            <ol>
              <li>Multiple payments for same services.</li>
              <li>
                A Customer paid the Invoice online but did not get the
                confirmation due to connection failure and SACO Shipping Egypt
                is not able to provide softcopy of the Fee Invoice
              </li>
              <li class="mb-2">
                Any amount paid more than the service agreed rate to be refunded
                as per client request.
              </li>
              NB: Transaction fees charged by the bank would not be refunded/
              reversed for any refund or reversal of any transaction.
            </ol>
          </li>
          <li>
            <p class="font-weight-bold">NOTE</p>
            <p>
              - For online payments, it is important for candidates to note the
              Invoice no. for future reference and request for any type of
              Refund, for whatsoever reason, will be at the sole discretion of
              Saco Shipping Egypt
            </p>
            <p>
              - Online refunds will be processed as soon as they are approved
              via a credit to your credit card account based on bank refund
              regulations.
            </p>
            <p>
              Please have your invoice number available and provide a reason for
              your request in order to speed up your refund process. Your
              request will be reviewed within a 2 days standard response period
              and we will notify you via email of the results. We reserve the
              right to extend the standard response time in exceptional cases
              with a prior notice to you.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms {
  &__wrapper {
    background: color(white);
    border-radius: 10px;
    overflow: hidden;
  }
  h3 {
    background: color(primary);
    color: color(white);
    padding: 10px;

    cursor: pointer;
    span {
      font-size: 20px;
    }
    span:first-of-type {
      font-size: 20px;
    }
  }
  ul {
    height: 0;
    overflow: hidden;
    @include normalTrans;
    &.open {
      height: auto;
      padding: 20px;
    }
    h5 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
    }
    li:not(:last-of-type) {
      margin-bottom: 35px;
    }
  }
}
</style>

<script>
export default {
  name: "terms",
  data() {
    return {
      col1: false,
      col2: false,
      query: this.$route.query.page,
    };
  },
  watch: {
    $route(e) {
      if (e.query.page) {
        this.col1 = false;
        this.col2 = true;
      } else {
        this.col2 = false;
        this.col1 = true;
      }
    },
  },
  created() {
    if (this.query) {
      setTimeout(() => {
        this.col1 = false;
        this.col2 = true;
      }, 400);
    } else {
      this.col1 = true;
      this.col2 = false;
    }
  },
};
</script>
