<template>
  <div class="contact pageCon" :class="{ blured: $store.state.blured }">
    <div class="contact__wrapper animate__animated animate__fadeIn">
      <div ref="successalert" class="custom-alert" v-show="alert">
        Contact has been Saved <i class="fas fa-check"></i>
      </div>
      <!--start contact infos CP -->
      <div class="contact-info">
        <h2 class="mb-3 font-weight-bold text-uppercase">
          Contact Informations
        </h2>
        <div class="row">
          <div class="col-lg-6">
            <!--load -->
            <div v-if="loadData">
              <div
                v-for="(a, i) in new Array(3)"
                :key="i"
                class="load mb-3"
                style="height: 45px"
              ></div>
            </div>
            <!--/load-->

            <div class="contact-block" v-if="!loadData">
              <conFormGroup
                @removeConInfo="removeConInfo($event)"
                :ind="i"
                :val="conInfoRan"
                v-for="(val, i) in conInfoRan"
                :key="i"
              ></conFormGroup>
            </div>

            <button
              v-if="!loadData"
              @click="conInfoRan.push('')"
              class="btn btn-p-outline"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <!--load -->
            <div class="load" v-if="loadData" style="height: 120px"></div>
            <!--/load -->

            <div class="d-flex align-items-start" v-if="!loadData">
              <div class="upload-img d-inline-block">
                <label
                  for="upImg"
                  class="justify-content-center d-flex align-items-center mb-0"
                >
                  <div class="text-center">
                    <i class="fas fa-plus text-success mr-2"></i>
                    <p>Upload img</p>
                  </div>
                </label>
                <input
                  @change="uploadImg($event)"
                  ref="file"
                  type="file"
                  hidden
                  id="upImg"
                  accept="image/*"
                />
              </div>
              <img
                :src="img"
                class="d-inline-block ml-2"
                width="100
              0"
              />
            </div>
            <p class="text-danger font-weight-bold" v-if="imgValid">
              Maximum size (1MB)
            </p>
          </div>
        </div>
      </div>
      <!--/ End contact infos CP -->

      <!--start contact infos CP -->
      <div class="contacts-info mt-5">
        <h2 class="mb-3 font-weight-bold text-uppercase">contacts</h2>
        <!-- load -->
        <div class="row" v-if="loadData">
          <div class="col-md-4 mb-3" v-for="(v, i) in new Array(6)" :key="i">
            <div class="load" style="height: 45px"></div>
          </div>
        </div>
        <!--/load -->

        <div class="emils-block" v-if="!loadData">
          <conEmils
            :ind="i"
            :val="contactDetails"
            v-for="(val, i) in contactDetails"
            :key="i"
            @removcontact="removcontact($event)"
          ></conEmils>
        </div>
        <button
          v-if="!loadData"
          @click="addContact"
          class="btn btn-p-outline mt-3"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <!--End contact infos CP -->

      <!--statart social media -->
      <div class="social mt-5">
        <h2 class="mb-3 font-weight-bold text-uppercase">SOCIAL Media</h2>
            <div v-if="loadData">
                <div class='load mb-3' style='height:45px;' v-for="(v,i) in new Array(7)" :key="i"></div>
            </div>
        <div v-if="!loadData">
          <div
            class="input-group mb-3"
            v-for="(social, i) in socialMedia"
            :key="i"
          >
            <div class="input-group-append">
              <span class="input-group-text">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    :id="social.itemType"
                    class="custom-control-input"
                    v-model="social.display"
                  /><label
                    :for="social.itemType"
                    class="custom-control-label pl-4 pt-2"
                  ></label>
                </div>
              </span>
            </div>

            <input
              type="text"
              class="form-control social__input"
              placeholder="example: www.link.com"
              v-model="social.itemLink"
            />
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fab" :class="`fa-${social.itemType}`"></i
              ></span>
            </div>
          </div>
        </div>

        <!--/facebook -->
      </div>
      <!--end social  media -->

      <div class="text-right mt-4" v-if="!loadData">
        <button @click="save()" class="btn btn-p">Save</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contact {
  &__wrapper {
    padding: 20px;
    background: color(white);
    @include shadow(hShadow);
  }
}
.contact-info {
  h2 {
    font-size: 24px;
  }
  .upload-img label {
    width: 100px;
    height: 100px;
    border: 2px dashed color(gray8);
    font-size: 12px;
    padding: 10px;
    svg {
      font-size: 22px;
    }
  }
}
.contact-block {
  input {
    @include query(maxM) {
      padding-right:20px;
    }
  }
}
.social {
  width: 500px;
  @include query(maxS) {
    width:auto;
  }
  svg {
    font-size: 17px;
  }
  &__input {
    border: 1px solid color(gray4);
    background: color(body);
    @include radius(0!important);
    border-left: 0 !important;
    border-right: 0 !important;

  }
  .custom-control-label {
    top: -3px;
    right: 2px;
  }
  .custom-control-label[data-v-74cedab6]::before,
  .custom-control-label[data-v-74cedab6]::after {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 6px !important;
  }
  .input-group-text {
    width: 40px;
    background: color(body);
    border: none;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}
::v-deep {
  .removeCon {
    right: 0;
    top: 5px;
  }
  .removeEm {
    right: 20px;
    top: 36px;
  }
  .contact-row:not(:last-of-type) {
    @include query(maxM) {
      border-bottom: 2px solid color(gray10);
      padding-bottom: 20px;
    }
  }
  .form-control {
    border: none;
    background-color: color(body) !important;
  }
}
</style>
<script>
export default {
  name: "ContactCp",
  data() {
    return {
      loadData: true,
      alert: false,
      img: "",
      imgValid: false,
      conInfoRan: [],
      contactDetails: [],
      socialMedia: [],
    };
  },
  methods: {
    uploadImg() {
      let inp = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("image", inp);
      this.axios
        .post("/api/AppAdmin/Contacts/SetupHeaderImage", formData, {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((res) => {
          this.img = res.data.data.imagePath;
          this.imgValid = false;
        })
        .catch((err) => {
          if (err) {
            this.imgValid = true;
          }
        });
    },
    removeConInfo(ev) {
      this.conInfoRan.splice(ev, 1);
    },
    removcontact(ev) {
      this.contactDetails.splice(ev, 1);
    },
    save() {
      let obj = {
        headerContent: this.conInfoRan.toString(),
        footerContent: "SACO SHIPPING EGYPT",
        contactDetails: this.contactDetails,
        contactFooterDetails: this.socialMedia,
      };
      this.axios
        .post("/api/AppAdmin/Contacts/SetupContacts", obj, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
            if (res.status == 200) {
                let successalert = this.$refs.successalert;
                window.scrollTo({ top: successalert.offsetTop, behavior: 'smooth' });
                this.alert = true;
            }
        }).then(() => setTimeout(() => { this.alert = false; }, 2000));
      },
      addContact() {
          this.contactDetails.push({
              employeeName: '',
              contact: '',
              jobTitle: '',
              order: this.contactDetails.length+1
            })
      }
  },
  components: {
    conFormGroup: {
      props: ["val", "ind"],
      template: `<div class='form-group position-relative'>
      <span @click="$emit('removeConInfo', ind)" class='position-absolute btn removeCon'><i class="fas fa-times text-danger"></i></span>
             <input type="text" class="form-control" v-model="val[ind]" />
      </div>`,
    },
    conEmils: {
      props: ["val", "ind"],
      template: `
        <div class="row contact-row mb-3">
          <div class="col-md-4 mb-2 mb-mb-0">
            <label>Employee name</label>
            <input type="text" class="form-control" v-model="val[ind].employeeName" />
          </div>
          <div class="col-md-4 mb-2 mb-mb-0">
            <label>contact</label>
            <input type="text" class="form-control"  v-model="val[ind].contact" />
          </div>
          <div class="col-md-3 position-relative">
            <label>Job title</label>
            <input type="text" class="form-control"v-model="val[ind].jobTitle"  />
          </div>
          <div class="col-md-1 position-relative">
            <span @click="$emit('removcontact', ind)" class='position-absolute btn removeEm'><i class="fas fa-times text-danger"></i></span>
            <label>order</label>
            <input type="number" class="form-control"  v-model="val[ind].order" />
          </div>
        </div>
        
        `,
    },
  },
  mounted() {
    this.axios.get("/api/home/Contacts").then((res) => {
      this.img = res.data.data.image;
      this.conInfoRan = res.data.data.headers.split(",");
      this.socialMedia = res.data.data.social;
        this.contactDetails = res.data.data.contacts;
    }).then(()=> this.loadData = false);
  },
};
</script>
