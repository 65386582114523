<template>
  <div class="quote overflow-hidden">
    <button class="btn mb-2 font-weight-bold" @click="$emit('closeForm')">
      <i class="text-danger fas fa-angle-left align-middel"></i> Back
    </button>

    <div class="wrap animate__animated animate__fadeInRightBig">
      <!--- quote heade -->
      <header class="quote__header d-flex flex-wrap justify-content-between">
        <h4 class="quote__title text-uppercase">{{ type }} QUOTATION FORM</h4>
        <p class="quote__hint my-2 my-sm-0">
          fill out this form and we will contact you ({{
            $store.getters.loggedin
              ? $store.state.userObject.username
              : "GUEST"
          }})
        </p>
      </header>
      <section class="quote__body form-row">
        <!--label-->
        <label class="quote__label col-12 mb-4">shipments details</label>
        <!--Guest Email -->
        <div
          class="form-group mb-4 col-12"
          v-if="!this.$store.getters.loggedin"
        >
          <input
            v-model="requesterEmail"
            type="text"
            class="quote__field form-control"
            placeholder="Email"
            @blur="emptyVal($event)"
          />
        </div>

        <!--col import -->
        <div class="form-group mb-4 col-md-6">
          <select
            v-model="importtype"
            class="custom-select quote__field"
            @blur="emptyVal($event)"
          >
            <option value="" disabled>IncoTerms</option>
            <option value="1">IMPORT</option>
            <option value="2">EXPORT</option>
          </select>
        </div>

        <!--col DELIVERY TERMS -->
        <div class="form-group mb-4 col-md-6">
          <select
            v-model="deliveryTerms"
            class="custom-select quote__field"
            @blur="emptyVal($event)"
          >
            <option value="" disabled>DELIVERY TERMS</option>
            <option value="FOB">FOB</option>
            <option value="CIF">CIF</option>
            <option value="Ex-Works">Ex Works</option>
            <option value="Door-To-Door">Door to Door</option>
            <option value="DAP">DAP</option>
          </select>
        </div>

        <div class="form-group mb-4 col-md-6">
          <input
            @blur="emptyVal($event)"
            list="ports"
            id="browser"
            placeholder="POL"
            class="form-control quote__field"
            v-model="polPortName"
          />
          <datalist id="ports">
            <option
              v-for="(a, i) in ports"
              :key="i"
              :value="a.portTitle"
            ></option>
          </datalist>
        </div>

        <div class="form-group mb-4 col-md-6">
          <input
            @blur="emptyVal($event)"
            list="ports"
            id="browser"
            placeholder="POD"
            class="form-control quote__field"
            v-model="podPortName"
          />
          <datalist id="ports">
            <option
              v-for="(a, i) in ports"
              :key="i"
              :value="a.portTitle"
            ></option>
          </datalist>
        </div>

        <div class="form-group mb-4 col-12">
          <input
            v-model="pickupAddrees"
            v-if="deliveryTerms === 'Ex-Works'"
            type="text"
            class="quote__field form-control"
            placeholder="pick-up address"
            @blur="emptyVal($event)"
          />
        </div>
        <!--col label-->
        <label class="quote__label col-12 my-3">
          <span class="quote__label">cargo details</span>
          <span
            class="ml-3"
            @click="addCargoDetail"
            style="color: #e40464; cursor: pointer"
          >
            <i class="fas fa-plus"></i> </span
        ></label>

        <div class="col-12 my-3">
          <PackageCargoDetail
            v-for="(detail, i) in cargoDetails"
            :key="i"
            :val="detail"
            :index="i"
            @removeCargoDetail="removeCargoDetail(i)"
          />
          <span class="font-weight-bold"
            >Total Weight :
            <span
              :class="{
                'text-success': totalWeight > 0,
                'text-danger': totalWeight <= 0,
              }"
              >{{ totalWeight }} KG</span
            ></span
          >
        </div>

        <!--col COMMODITY-->
        <label class="quote__label col-12 my-3">COMMODITY</label>

        <!--col cargo COMMODITY-->
        <div class="form-group mb-0 col-12">
          <input
            v-model="commodity"
            type="text"
            class="quote__field form-control"
            placeholder="COMMODITY"
            @blur="emptyVal($event)"
          />
        </div>

        <!--col Notes-->
        <label class="quote__label col-12 my-3">Notes</label>

        <!--col cargo COMMODITY-->
        <div class="form-group mb-0 col-12">
          <textarea
            v-model="notes"
            class="form-control"
            placeholder="Notes"
            rows="5"
          />
        </div>
        <!--col checkbox -->
        <div class="form-group col-12 mt-4">
          <div class="custom-control custom-checkbox">
            <input
              v-model="dangerousCargo"
              type="checkbox"
              class="custom-control-input"
              id="lclCheck"
              @blur="emptyVal($event)"
            />
            <label class="custom-control-label pl-4 pt-0" for="lclCheck"
              >dangerous cargo</label
            >
          </div>
        </div>

        <!--col checkbox -->
        <div class="form-group col-12">
          <div class="custom-control custom-checkbox">
            <input
              v-model="temperatureControlled"
              type="checkbox"
              class="custom-control-input"
              id="temp"
              @blur="emptyVal($event)"
            />
            <label class="custom-control-label pl-4 pt-0" for="temp"
              >temperature Controlled</label
            >
          </div>
        </div>

        <!--col checkbox -->
        <div class="form-group col-12">
          <div class="custom-control custom-checkbox">
            <input
              v-model="stackable"
              type="checkbox"
              class="custom-control-input"
              id="stackable"
              @blur="emptyVal($event)"
            />
            <label class="custom-control-label pl-4 pt-0" for="stackable"
              >Stackable</label
            >
          </div>
        </div>
      </section>

      <!-- submit button -->
      <div class="text-right mr-4 mb-4">
        <button
          :disabled="spinner"
          class="btn btn-p quote__btn"
          @click="sendQuote()"
        >
          <div
            class="spinner-border text-secondary d-inline-block"
            role="status"
            style="width: 1rem; height: 1rem"
            v-if="spinner"
          >
            <span class="sr-only">Loading...</span>
          </div>
          Request your quote
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quote {
  .wrap {
    border: 15px solid color(white);
    @include query(maxS) {
      border-width: 8px;
    }
  }
  &__header {
    padding: 10px;
    background: color(white);
    @include query(maxS) {
      padding: 0;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    @include query(maxL) {
      font-size: 17px;
    }
  }
  &__hint {
    text-transform: uppercase;
    color: color(gray10);
    font-weight: bold;
    @include query(maxL) {
      font-size: 12px;
    }
  }
  &__label {
    font-size: 18px;
    color: #121626;
  }
  &__body {
    padding: 30px 30px 0;
    @include query(maxM) {
      padding: 20px 20px 0;
    }
    @include query(maxS) {
      padding: 15px 15px 0;
    }
  }

  &__field {
    color: color(gray10);
    font-size: 17px;
    font-weight: bold;
    border: 1px solid color(gray3);
    &::placeholder {
      font-size: 17px;
    }
    &--require {
      border: 1px solid color(primary) !important;
    }
  }
  &__btn:disabled {
    background: color(gray10) !important;
  }
}
.custom-control-label {
  color: color(gray8);
  font-weight: bold;
  padding-top: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png");
}
</style>
<script>
import PackageCargoDetail from "../PackageCargoDetail.vue";
export default {
  name: "quoteForms",
  props: ["type"],
  data() {
    return {
      spinner: false,
      ports: [],
      requesterEmail: this.$store.getters.loggedin
        ? this.$store.state.userObject.email
        : "",
      requesterUsername: this.$store.getters.loggedin
        ? this.$store.state.userObject.username
        : "guest",
      requesterPhone: this.$store.getters.loggedin
        ? this.$store.state.userObject.landLine == null
          ? "000000000"
          : this.$store.state.userObject.landLine
        : "000000000",
      requesterLandline: this.$store.getters.loggedin
        ? this.$store.state.userObject.landLine == null
          ? "000000000"
          : this.$store.state.userObject.landLine
        : "000000000",
      importtype: "",
      cargoDetails: [],
      deliveryTerms: "",
      podPortName: "",
      polPortName: "",
      pickupAddrees: "",
      commodity: "",
      notes: "",
      stackable: false,
      dangerousCargo: false,
      temperatureControlled: false,
    };
  },
  computed: {
    totalWeight() {
      let total = 0;
      for (let i = 0; i < this.cargoDetails.length; i++) {
        total += isNaN(parseFloat(this.cargoDetails[i].weight))
          ? 0
          : parseFloat(this.cargoDetails[i].weight);
      }
      return isNaN(parseFloat(total)) ? 0 : parseFloat(total);
    },
  },
  methods: {
    compareVal(ev, type) {
      let userPort = "";
      for (let i = 0; i < this.ports.length; i++) {
        if (this.ports[i].portTitle == ev.currentTarget.value) {
          userPort = this.ports[i].portID;
        }
      }
      if (type == "pol") {
        this.polPortName = ev.currentTarget.value;
        this.polPortName = ev.currentTarget.value;
        this.polPortID = userPort;
        this.polPortID = userPort;
      } else {
        this.podPortName = ev.currentTarget.value;
        this.podPortName = ev.currentTarget.value;
        this.PodPortID = userPort;
        this.PodPortID = userPort;
      }
    },
    sendQuote() {
      this.spinner = true;
      let url = "";
      if (this.type == "LCL") {
        url = "api/Quotations/LCLFreightQuote";
      } else if (this.type == "AIR") {
        url = "api/Quotations/AirFreightQuote";
      }
      let obj = {
        requesterEmail: this.requesterEmail,
        requesterUsername: this.requesterUsername,
        requesterPhone: this.requesterPhone,
        requesterLandline: this.requesterLandline,
        notes: "",
        incoTerms: this.importtype,
        deliveryTerms: this.deliveryTerms.replace("-", " "),
        podPortName: this.podPortName,
        polPortName: this.polPortName,
        pickupAddress: this.pickupAddrees,
        commodity: this.commodity,
        dangerousCargo: this.dangerousCargo,
        temperatureControlled: this.temperatureControlled,
        nonStackable: this.stackable,
        notes: this.notes,
        freightDetails: this.cargoDetails,
      };
      for (
        let i = 0;
        i < document.getElementsByClassName("quote__field").length;
        i++
      ) {
        if (document.getElementsByClassName("quote__field")[i].value == "") {
          document
            .getElementsByClassName("quote__field")
            [i].classList.add("quote__field--require");
        } else {
          document
            .getElementsByClassName("quote__field")
            [i].classList.remove("quote__field--require");
        }
      }
      if (
        document.getElementsByClassName("quote__field--require").length == 0 &&
        this.totalWeight >= 1
      ) {
        this.axios
          .post(url, obj)
          .then((res) => {
            this.spinner = false;
            this.$emit("closeForm");
            this.$emit("fadeAlert");
          })
          .catch((err) => {
            this.$swal(
              "Couldn't send the request ",
              `Kindly fill all required fields`,
              "error"
            );
            this.spinner = false;
          });
      } else {
        this.$swal(
          "Couldn't send the request ",
          `Kindly fill all required fields`,
          "error"
        );
        this.spinner = false;
      }
    },
    emptyVal(ev) {
      if (ev.currentTarget.value == "") {
        ev.currentTarget.classList.add("quote__field--require");
      } else {
        ev.currentTarget.classList.remove("quote__field--require");
      }
    },
    getPorts(port) {
      this.axios
        .get(`api/appadmin/settings/GetPorts?portname=${port}`)
        .then((res) => {
          this.ports = res.data;
        })
        .catch((err) => {});
    },
    addCargoDetail() {
      this.cargoDetails.push({});
    },
    removeCargoDetail(index) {
      this.cargoDetails.splice(index, 1);
    },
  },
  watch: {
    polPortName(val) {
      this.getPorts(val);
    },
    podPortName(val) {
      this.getPorts(val);
    },
  },
  components: {
    PackageCargoDetail,
  },
  created() {
    this.cargoDetails.push({});
  },
};
</script>
