<template>
  <div
    class="events pageCon overflow-hidden"
    :class="{ blured: $store.state.blured }"
  >
    <h2 class="events__title text-uppercase mb-3 text-center" v-if="alert">
      Add Events
    </h2>
    <div class="custom-alert text-center" v-if="alert">
      Your event has been saved <i class="fas fa-check text-success"></i>
    </div>
    <eventsForm
      :method="method"
      :eventId="eventId"
      @done="done()"
      @updateEv="allEvents()"
      @close="(eventForm = false), (animate = true)"
      v-if="eventForm"
    ></eventsForm>

    <div
      class="events__wrapper animate__animated"
      :class="{ animate__fadeInRightBig: animate }"
      v-if="!eventForm"
    >
      <header class="d-flex justify-content-end">
        <button
          v-if="resDone"
          @click="(eventForm = true), (method = 'create')"
          class="btn btn-p-outline mb-3 rounded-0"
        >
          New Event <i class="fas fa-plus"></i>
        </button>
        <div class="btn load mb-5" v-if="!resDone" style="width: 120px"></div>
      </header>
      <div class="events__wrapper">
        <ul
          class="list-unstyled d-flex justify-content-between mb-5"
          v-if="!resDone"
        >
          <li
            style="width: calc((100% / 5) - 1rem); height: 70px"
            class="load mr-2"
            v-for="(a, i) in new Array(5)"
            :key="i"
          ></li>
        </ul>

        <table class="table events__table text-center" v-if="resDone">
          <tbody>
            <tr v-for="(tb, i) in events" :key="i">
              <td class="events__td">{{ i + 1 }}</td>
              <td class="events__td"><img :src="tb.imageLink" width="50" /></td>
              <td class="events__td">{{ tb.eventTitle }}</td>
              <td class="events__td">
                <a class="text-primary" :href="tb.fileLink" target="_blank">{{
                  tb.fileDisplayName
                }}</a>
              </td>
              <td class="events__td">
                Display
                <span v-show="!tb.display">
                  <i class="fas fa-times text-danger"></i>
                </span>
                <span v-show="tb.display">
                  <i class="fas fa-check text-success"></i>
                </span>
              </td>
              <td class="events__td">
                <button
                  class="btn"
                  @click="
                    (eventForm = true),
                      (method = 'edit'),
                      (eventId = tb.eventDataID)
                  "
                >
                  <i class="fas fa-edit text-primary"></i>
                </button>
                <button class="btn" @click="deleteEvent(tb.eventDataID)">
                  <i class="fas fa-trash text-danger"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.events {
  &__wrapper {
    overflow-x: auto;
  }
  &__title {
    font-size: 24px;
    font-weight: bold;
  }
  &__table {
    background: color(white);
  }
  &__td {
    vertical-align: middle;
    color: color(gray8);
    font-weight: bold;
    white-space: nowrap;
  }
}
</style>

<script>
import eventsForm from "../../components/admin/EventsForm.vue";
export default {
  name: "EventsCp",
  data() {
    return {
      eventForm: false,
      animate: false,
      alert: false,
      eventId: null,
      method: "",
      events: [],
      resDone: false,
    };
  },
  methods: {
    done() {
      let vm = this;
      this.alert = true;
      setTimeout(() => {
        vm.alert = false;
      }, 2000);
    },
    deleteEvent(id) {
      this.$swal({
        icon: "question",
        title: "Delete this Event ?",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonColor: "red",
        cancelButtonColor: "gray",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((res) => {
        if (res.isConfirmed) {
          this.axios
            .delete(`/api/AppAdmin/Events/${id}`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`,
              },
            })
            .then((res) => {
              if (res.status == 204) {
                this.$swal({
                  icon:'success',
                  title:"Event Has Deleted!",

                })
                this.axios.get("/api/AppAdmin/Events/Get").then((res) => {
                  this.events = res.data.data;
                });
              }
            });
        }
      });
    },

    allEvents() {
      this.axios
        .get("/api/AppAdmin/Events/Get", {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.events = res.data.data;
            this.resDone = true;
          }
        });
    },
  },
  components: {
    eventsForm,
  },
  created() {
    this.allEvents();
  },
};
</script>
