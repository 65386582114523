<template>
<div>
  <SailingSchedulepopup @close="sSPopup=false;$store.state.blured=false" v-if="sSPopup"></SailingSchedulepopup>
  <div class="dashboard pageCon" :class="{ blured: $store.state.blured }">

    <h1
      class="
        text-center
        dashboard__h1
        mb-1
        animate__animated animate__fadeInUp
        mb-4
      "
    >
      Hi
      <span v-if="$store.getters.loggedin">{{
        $store.state.userObject.displayName
      }}</span>
      ! Welcome back to the Dashboard
    </h1>
    <!-- <p class="text-center dashboard__p animate__animated animate__fadeInUp">
    Check out your past searches and content Browsed in view last 
    </p> -->
    <div class="row">
      <!-- col track and trace -->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <router-link to="/Trace_trace" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.trace.img = imgs.trace.regImg"
            @mouseover="imgs.trace.img = imgs.trace.hover"
          >
            <img :src="imgs.trace.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">TRACK AND TRACE</h5>
          </div>
        </router-link>
      </div>

      <!-- col tSHIPMENTS -->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
        v-if="$store.getters.loggedin && $store.state.userObject.shipments"
      >
        <router-link to="/Shipments" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.ship.img = imgs.ship.regImg"
            @mouseover="imgs.ship.img = imgs.ship.hover"
          >
            <img :src="imgs.ship.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">SHIPMENTS</h5>
          </div>
        </router-link>
      </div>

      <!-- col REQUEST QUOTE-->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <router-link to="/Request_quote" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.quote.img = imgs.quote.regImg"
            @mouseover="imgs.quote.img = imgs.quote.hover"
          >
            <img :src="imgs.quote.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">REQUEST QUOTE</h5>
          </div>
        </router-link>
      </div>
      <!-- col Booking -->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
        v-if="$store.getters.loggedin && $store.state.userObject.booking"
      >
        <router-link to="/Booking" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.booking.img = imgs.booking.regImg"
            @mouseover="imgs.booking.img = imgs.booking.hover"
          >
            <img :src="imgs.booking.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">Booking</h5>
          </div>
        </router-link>
      </div>

      <!-- col ADMIN PANEL -->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
        v-if="$store.getters.loggedin && $store.state.userObject.isClientAdmin"
      >
        <router-link to="/Admin_Panel" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.panel.img = imgs.panel.regImg"
            @mouseover="imgs.panel.img = imgs.panel.hover"
          >
            <img :src="imgs.panel.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">ADMIN PANEL</h5>
          </div>
        </router-link>
      </div>

      <!-- col DOCUMENTS REQUEST -->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <router-link to="/Docs_Request" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.request.img = imgs.request.regImg"
            @mouseover="imgs.request.img = imgs.request.hover"
          >
            <img :src="imgs.request.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">DOCUMENTS REQUEST</h5>
          </div>
        </router-link>
      </div>

      <!-- col DATA-->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <router-link to="/About" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.data.img = imgs.data.regImg"
            @mouseover="imgs.data.img = imgs.data.hover"
          >
            <img :src="imgs.data.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">ABOUT US</h5>
          </div>
        </router-link>
      </div>

      <!-- col PAYMTNES-->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
        v-if="$store.getters.loggedin && $store.state.userObject.payment"
      >
        <router-link to="/Payments" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.payment.img = imgs.payment.regImg"
            @mouseover="imgs.payment.img = imgs.payment.hover"
          >
            <img :src="imgs.payment.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">PAYMENTS</h5>
          </div>
        </router-link>
      </div>

      <!-- col SALING SCHEDULE-->
      <div v-on:click="sSPopup=true;$store.state.blured=true"
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.saling.img = imgs.saling.regImg"
            @mouseover="imgs.saling.img = imgs.saling.hover"
          >
            <img :src="imgs.saling.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">Sailing Schedule</h5>
          </div>
      </div>

      <!-- col contact us -->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <router-link to="/contact" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.contact.img = imgs.contact.regImg"
            @mouseover="imgs.contact.img = imgs.contact.hover"
          >
            <img :src="imgs.contact.img" class="dashboard__icon" width="140" />
            <h5 class="dashboard__title mt-2">CONTACT US</h5>
          </div>
        </router-link>
      </div>

      <!-- col help-->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <router-link to="/help" class="d-block">
          <div
            class="dashboard__card text-center"
            @mouseleave="imgs.help.img = imgs.help.regImg"
            @mouseover="imgs.help.img = imgs.help.hover"
          >
            <img :src="imgs.help.img" class="dashboard__icon" width="140" />

            <h5 class="dashboard__title mt-2">HELP</h5>
          </div>
        </router-link>
      </div>

      <!-- col LOG OUT-->
      <div
        class="
          col-xl-3 col-lg-4 col-md-6
          mb-4
          animate__animated animate__bounceIn
        "
      >
        <div
          class="dashboard__card text-center"
          @mouseleave="imgs.logOut.img = imgs.logOut.regImg"
          @mouseover="imgs.logOut.img = imgs.logOut.hover"
          @click.prevent="dest()"
        >
          <img :src="imgs.logOut.img" class="dashboard__icon" width="140" />
          <h5 class="dashboard__title mt-2">LOG OUT</h5>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<style lang="scss" scoped>
.dashboard {
  &__h1 {
    font-size: 25px;
    font-weight: bold;
  }
  &__p {
    margin-bottom: 50px;
    animation-delay: 0.5rem;
  }
  &__card {
    padding: 40px 17px;
    @include radius(2px);
    background: color(white);
    @include shadow(cardShadow);
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      right: 0;
      top: 0;
      width: 14px;
      height: 0;
      position: absolute;
      background: transparent;
      @include normalTrans;
    }
    &:hover {
      &::before {
        background: color(primary);
        height: 44px;
      }
      .dashboard__icon {
        color: color(primary) !important;
      }
    }
  }
  &__icon {
    margin-bottom: 5px;
  }
  &__title {
    font-size: 17px;
    font-weight: 600;
    @include normalTrans;
    text-transform: uppercase;
  }
}
@for $i from 1 through 12 {
  .col-lg-4:nth-child(#{$i}) {
    -webkit-animation-delay: (#{$i * 0.1s});
    animation-delay: (#{$i * 0.1s});
  }
}
</style>
<script>
import SailingSchedulepopup from "../../components/SailingSchedulepopup.vue";

export default {
  name: "cDashboard",
  data() {
    return {
      sSPopup: false,
      imgs: {
        trace: {
          img: require(`../../assets/imgs/dashboard/trace.png`),
          regImg: require(`../../assets/imgs/dashboard/trace.png`),
          hover: require(`../../assets/imgs/dashboard/traceHover.png`),
        },
        ship: {
          img: require(`../../assets/imgs/dashboard/ship.png`),
          regImg: require(`../../assets/imgs/dashboard/ship.png`),
          hover: require(`../../assets/imgs/dashboard/shipHover.png`),
        },
        quote: {
          img: require(`../../assets/imgs/dashboard/quote.png`),
          regImg: require(`../../assets/imgs/dashboard/quote.png`),
          hover: require(`../../assets/imgs/dashboard/quoteHover.png`),
        },
        booking: {
          img: require(`../../assets/imgs/dashboard/booking.png`),
          regImg: require(`../../assets/imgs/dashboard/booking.png`),
          hover: require(`../../assets/imgs/dashboard/bookingHover.png`),
        },
        panel: {
          img: require(`../../assets/imgs/dashboard/panel.png`),
          regImg: require(`../../assets/imgs/dashboard/panel.png`),
          hover: require(`../../assets/imgs/dashboard/panelHover.png`),
        },
        request: {
          img: require(`../../assets/imgs/dashboard/docs.png`),
          regImg: require(`../../assets/imgs/dashboard/docs.png`),
          hover: require(`../../assets/imgs/dashboard/docsHover.png`),
        },
        data: {
          img: require(`../../assets/imgs/dashboard/data.png`),
          regImg: require(`../../assets/imgs/dashboard/data.png`),
          hover: require(`../../assets/imgs/dashboard/dataHover.png`),
        },
        payment: {
          img: require(`../../assets/imgs/dashboard/payment.png`),
          regImg: require(`../../assets/imgs/dashboard/payment.png`),
          hover: require(`../../assets/imgs/dashboard/paymentHover.png`),
        },
        saling: {
          img: require(`../../assets/imgs/dashboard/SALING.png`),
          regImg: require(`../../assets/imgs/dashboard/SALING.png`),
          hover: require(`../../assets/imgs/dashboard/SALINGhover.png`),
        },
        contact: {
          img: require(`../../assets/imgs/dashboard/contact.png`),
          regImg: require(`../../assets/imgs/dashboard/contact.png`),
          hover: require(`../../assets/imgs/dashboard/contactHover.png`),
        },
        help: {
          img: require(`../../assets/imgs/dashboard/help.png`),
          regImg: require(`../../assets/imgs/dashboard/help.png`),
          hover: require(`../../assets/imgs/dashboard/helpHover.png`),
        },
        logOut: {
          img: require(`../../assets/imgs/dashboard/logout.png`),
          regImg: require(`../../assets/imgs/dashboard/logout.png`),
          hover: require(`../../assets/imgs/dashboard/logoutHover.png`),
        },
      },
    };
  },
  methods: {
    dest() {
      this.$store.dispatch("destLogin").then((res) => {
        this.$store.state.loading = true;
        this.$router.push({ name: "Home" });
      });
    },
  },
  components: { SailingSchedulepopup },
};
</script>
