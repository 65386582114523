import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
export default {
    install(Vue) {
        //init
        const connection = new HubConnectionBuilder()
            .withUrl(`${Vue.prototype.$baseurl}newshub`)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build()
        //registration
        const newshub = new Vue();
        Vue.prototype.$newshub = newshub;
        //events
        connection.on("UpdateUnSeeEventsCount", () => {
            newshub.$emit('unsee-count-changed')
        });

        connection.on("ShowNotification", (title, body, urls) => {
            newshub.$emit('new-notification-added', title, body, urls)
        });

        connection.start().then(() => {
            // var myClientId = connection.connectionId;
            // alert(myClientId);
            // setCookie("srconnectionid", myClientId);
        })

        function setCookie(cName, value, exdays) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + exdays);
            var c_value =
              escape(value) + (exdays == null ? "" : "; expires=" + exdate.toUTCString());
            document.cookie = cName + "=" + c_value;
          }
    }
}
