import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
Vue.use(Vuex);

//---------- Start Admin Views
import Dashboard from "../views/admin/Dashboard.vue";
import ContactCp from "../views/admin/Contact.vue";
import EventsCp from "../views/admin/Events.vue";
import pending from "../views/admin/Pending_accounts.vue";
import cSettings from "../views/admin/Settings.vue";
import SignUp from "../views/SignUp.vue";
import EditAccount from "../views/admin/EditAccount.vue";
//---------- End Admin views

//-------- Start clients views
import cDashboard from "../views/Clients/Dashboard.vue";
import ReqQuote from "../views/Clients/Request_Quote.vue";
import Events from "../views/Clients/Events.vue";
import Shipments from "../views/Clients/Shipments.vue";
import DocumentRequest from "../views/Clients/Document_Request.vue";
import AdminPanel from "../views/Clients/Admin_Panel.vue";
import Payments from "../views/Clients/Payments.vue";
import PreveiousPayments from "../views/Clients/PreveiousPayments.vue";
import Booking from "../views/Clients/Booking.vue";
import Settings from "../views/Clients/Settings.vue";
import Help from "../views/Clients/Help.vue";
import Contact from "../views/Clients/Contact.vue";
import Notifications from "../views/Notifications.vue";

// -------- End Clients views

//---------- Start Public pages
import Home from "../views/Home.vue";
import notfound from "../views/notFound.vue";
import store from "../store";
import terms from "../views/Clients/terms-privacy.vue";
import About from "../views/Clients/About.vue";
import trackTrace from "../views/Clients/Track_trace.vue";

//---------- End Public pages

Vue.use(VueRouter);
const routes = [
  // --- Admin objects ----//
  {
    name: "AdminDashboard",
    path: "/Cpanel",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Dashboard",
    },
  },
  {
    name: "ContactCp",
    path: "/ContactCp",
    component: ContactCp,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Contacts panel",
    },
  },
  {
    name: "EventsCp",
    path: "/EventsCp",
    component: EventsCp,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Evenet panel",
    },
  },
  {
    name: "pending",
    path: "/Pending_accounts",
    component: pending,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Pending accounts",
    },
  },
  {
    name: "cSettings",
    path: "/Settings",
    component: cSettings,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Admin Settings",
    },
  },
  {
    name: "editaccount",
    path: "/EditAccount",
    component: EditAccount,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Edit Account",
    },
  },
  // --- Clients  objects ----//
  {
    name: "ClientDashboard",
    path: "/Dashboard",
    component: cDashboard,
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title: "Dashboard",
    },
  },
  {
    name: "Notifications",
    path: "/Notifications",
    component: Notifications,
    meta: {
      requiresAuth: true,
      isAdmin: null,
      title: "Notifications",
    },
  },
  {
    name: "ReqQuote",
    path: "/Request_quote",
    component: ReqQuote,
    meta: {
      title: "Reqiest quote",
    },
  },
  {
    name: "Events",
    path: "/Events",
    component: Events,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      title: "Events",
    },
  },
  {
    name: "Shipments",
    path: "/Shipments",
    component: Shipments,
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title: "Shipments",
    },
  },
  {
    name: "DocumentRequest",
    path: "/Docs_Request",
    component: DocumentRequest,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      title: "Documents Request",
    },
  },
  {
    name: "Admin_Panel",
    path: "/Admin_Panel",
    component: AdminPanel,
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title: "Admin Panel",
    },
  },
  {
    name: "Payments",
    path: "/Payments",
    component: Payments,
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title: "Payments",
    },
  },
  {
    name: "PreveiousPayments",
    path: "/PreveiousPayments",
    component: PreveiousPayments,
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title: "Preveious Payments",
    },
  },
  {
    name: "Booking",
    path: "/Booking",
    component: Booking,
    meta: {
      requiresAuth: true,
      isAdmin: null,
      title: "Booking",
    },
  },
  {
    name: "Settings",
    path: "/Account_management",
    component: Settings,
    meta: {
      requiresAuth: true,
      isAdmin: null,
      title: "Account managemen",
    },
  },
  {
    name: "trackTrace",
    path: "/Trace_trace",
    component: trackTrace,
    meta: {
      title: "Trace trace",
    },
  },
  {
    name: "Help",
    path: "/Help",
    component: Help,
    meta: {
      title: "Help",
    },
  },
  {
    name: "Contact",
    path: "/Contact",
    component: Contact,
    meta: {
      title: "Contacts",
    },
  },
  {
    name: "About",
    path: "/About",
    component: About,
    meta: {
      title: "About",
    },
  },

  {
    name: "terms",
    path: "/Terms-policies",
    component: terms,
    meta: {
      title: "Terms policies",
    },
  },

  // --- Public objects ----//

  {
    name: "SignUp",
    path: "/SignUp",
    component: SignUp,
    meta: {
      requiresVistor: true,
      title: "SignUp",
    },
  },
  {
    name: "Home",
    path: "/",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    name: "notfound",
    path: "/notfound",
    component: notfound,
    meta: {
      title: "404 Not found",
      requiresAuth: true,
      isAdmin: store.getters.roles,
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "Home",
      });
    } else {
      if (store.getters.roles && to.meta.isAdmin) {
        next();
      } else if (!store.getters.roles && !to.meta.isAdmin) {
        if (store.state.userObject.isClientAdmin) {
          next();
        } else if (!store.state.userObject.isClientAdmin) {
          if (
            store.state.userObject.payment &&
            store.state.userObject.shipments &&
            store.state.userObject.booking &&
            to.name != "Admin_Panel"
          ) {
            next();
          } else if (to.name == "Admin_Panel") {
            next({
              name: "notfound",
            });
          } else if (to.name == "Payments" && !store.state.userObject.payment) {
            next({
              name: "notfound",
            });
          } else if (to.name == "Booking" && !store.state.userObject.booking) {
            next({
              name: "notfound",
            });
          } else if (
            to.name == "Shipments" &&
            !store.state.userObject.shipments
          ) {
            next({
              name: "notfound",
            });
          } else {
            next();
          }
        }
      } else if (to.meta.isAdmin == null) {
        next();
      } else {
        next({
          name: "notfound",
        });
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresVistor)) {
    if (store.getters.loggedin) {
      if (store.getters.roles) {
        next({
          name: "AdminDashboard",
        });
      }
    } else {
      next();
    }
  } else if (!to.matched.some((record) => record.name)) {
    next({
      name: "notfound",
    });
  } else {
    next();
  }
});

const DEFAULT_TITLE = "Saco";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  Vue.prototype.$tidioChatApi && Vue.prototype.$tidioChatApi.display(true);
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
