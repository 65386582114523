import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueNiceScrollbar from "vue-nice-scrollbar";
import moment from "moment";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import VueObserveVisibility from "vue-observe-visibility";
import ShipmentsHub from "./Hubs/shipments-hub";
import NewsHub from "./Hubs/news-hub";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueHtmlToPaper from "vue-html-to-paper";
import "./registerServiceWorker";
import SortedTablePlugin from "vue-sorted-table";
import VueTidio from "vue-tidio";
import Tabs from "vue-material-tabs";
//VueObserveVisibility
Vue.use(VueObserveVisibility);

//axios
Vue.use(VueAxios, axios);

//nice scrollbar
Vue.use(vueNiceScrollbar);

//sorted table
Vue.use(SortedTablePlugin);

//VueSweetalert2
Vue.use(VueSweetalert2);

Vue.use(Tabs);

//filters
Vue.filter("DefaultDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY");
  }
});

Vue.filter("FullDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY hh:mmA");
  }
});

//VueHtmlToPaper
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://portal.eg.sacoshipping.com/Resources/WebsittDataFiles/style.css",
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

//----axios configurations start----
Vue.prototype.$baseurl = axios.defaults.baseURL =
  "https://portal.eg.sacoshipping.com/";

//axios.defaults.baseURL = "https://localhost:5001/" "https://portal.eg.sacoshipping.com/";
axios.defaults.withCredentials = true;
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401 && $store.getters.loggedin) {
      Vue.swal({
        icon: "warning",
        title: "Your Session has been expired",
        text: `you have to login again!`,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Login Again!",
        cancelButtonText: "Stay Here",
      }).then((res) => {
        if (res.isConfirmed) {
          store.dispatch("destLogin").then((res) => {
            store.state.loading = true;
            router.push({ name: "Home" });
          });
        }
      });
    } else if (error.response.status == 503) {
      console.log("Error 503");
    }
    return Promise.reject(error);
  }
);
//----axios configurations end----

//hubs
//Vue.use(ShipmentsHub);
Vue.use(NewsHub);

//tidio
Vue.use(VueTidio, { appKey: "c1qha5hcqjziuv05fwx8qg60n7do7k2u" });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
