
import axios from "axios";
import VueAxios from "vue-axios";
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(VueAxios, axios);
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin:true,
    loading:true,
    asideOpen: false,
    blured:false,
    log:false,
    guestEmail:"",
    userObject: JSON.parse(localStorage.getItem("userObject")) || null,
    userId:localStorage.getItem("userId") || null,
    token:localStorage.getItem("token") || null,
    roles:localStorage.getItem("roles") || null,
    homeLink:localStorage.getItem("roles") == 'AppAdmin' ? '/cpanel' : "/Dashboard",
  },
  getters:{
    loggedin(state){
      return state.token != null
    },
    roles(state){
      return state.roles  == "AppAdmin"
    }
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    userObj(state, obj) {
      state.userObject = obj;
    },
    roles(state,roles) {
      state.roles = roles;
    },
    destLogin(state) {
      state.token = null;
      state.roles = null;
      state.userId = null;
      state.userObject = null;
      document.cookie = "Saco-token=; expires= Thu, 21 Aug 2014 20:00:00 UTC";
      setTimeout(() => {
        state.loading = false;       
      }, 2000);
    },
    
  },
  actions: {
    destLogin(context){
      if(context.getters.loggedin) {
        return new Promise((resolove, reject)=> {
          context.commit("destLogin");
          localStorage.removeItem("token");
          localStorage.removeItem("userObject");
          localStorage.removeItem("roles");
          resolove('logout');
        })
      }
    },
    login(context, credentials){
      return new Promise((resolve, reject)=> {
      axios.post("/api/Accounts/Login", {
        username : credentials.username,
        password: credentials.password,
      }).then(res=> {
        const token = res.data.token;
        const roles = res.data.roles[0];
          setTimeout(()=> {
            localStorage.setItem("token", token);
            localStorage.setItem("roles", roles);
            context.commit("login", token);
            context.commit("roles", roles);
            context.commit("userObj", res.data);
          },2000)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
    }
  },
  modules: {
  }
})
