<template>
  <div class="forget d-flex align-items-center justify-content-center">
    <div
      class="
        forget__wrapper
        text-center
        position-relative
        animate__animated animate__bounceInUp
      "
    >
      <button @click="$emit('close')" class="btn forget__close">
        <i class="fas fa-times font-weight-bold"></i>
      </button>
      <h2 class="forget__title mb-3 text-uppercase">
        Which Sailing Schedule you want
      </h2>
      <a
        v-bind:href="exportLink"
        download
        :disabled="spinner"
        class="btn btn-p mt-3 w-100 forget__btn"
      >
        export sailing Schedule
      </a>
      <a
        v-bind:href="importLink"
        download
        :disabled="spinner"
        class="btn btn-p mt-3 w-100 forget__btn"
      >
        import sailing Schedule
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.forget {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;

  &__input {
    background: color(body);
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
  }
  &__wrapper {
    background: color(white);
    padding: 60px 40px;
    width: 450px;
    @include shadow(blockShadow);
    @include query(maxS) {
      width: 92%;
    }
  }
  &__close {
    right: -10px;
    top: -10px;
    position: absolute;
    background: color(white);
    width: 34px;
    height: 34px;
    color: color(primary);
    @include shadow(blockShadow);
    &:hover {
      background: color(gray7);
    }
  }
  .input-group.notValid {
    border: 1px solid crimson;
  }
  &__btn:disabled {
    background: #333;
  }
}
</style>

<script>
export default {
  name: "SailingSchedulepopup",
  data() {
    return { importLink: "", exportLink: "" };
  },
  methods: {
    getFiles() {
      this.axios
        .get(`/api/AppAdmin/settings/ServerFile?filename=import_sailing`)
        .then((res) => {
          this.importLink = res.data.data.fileLink;
        });
      this.axios
        .get(`/api/AppAdmin/settings/ServerFile?filename=export_sailing`)
        .then((res) => {
          this.exportLink = res.data.data.fileLink;
        });
    },
  },
  mounted() {
    this.getFiles();
  },
};
</script>
