<template>
  <header
    class="
      header
      d-flex
      align-items-center
      justify-content-between justify-content-lg-end
    "
  >
    <button
      @click="
        ($store.state.asideOpen = !$store.state.asideOpen),
          ($store.state.blured = !$store.state.blured)
      "
      class="btn d-lg-none header__toggler"
    >
      <i class="fas fa-bars"></i>
    </button>
    <router-link to="/Notifications" v-if="$store.getters.loggedin" class="btn">
      <img src="../assets/imgs/menuIcons/Notification.png" width="28" />
      <span v-if="notificationsExists" class="notification-badge"></span>
    </router-link>
  </header>
</template>

<style lang="scss" scoped>
.notification-badge {
  position: absolute;
  top: 10px;
  right: 20px;
  height: 5px;
  widows: 5;
  padding: 3px;
  border-radius: 50%;
  background: #e40464;
  color: white;
}

.btn {
  position: relative;
}

.header {
  background: color(white);
  min-height: 61px;
  max-height: 61px;
  width: calc(100% - 250px);
  margin-left: 250px;
  @include shadow(headerShadow);
  position: relative;
  z-index: 49;
  padding: 0 20px;
  border-bottom: 1px solid color(gray4);
  position: fixed;

  left: 0;
  @include query(maxL) {
    width: 100%;
    margin: 0;
    padding: 0 15px;
    z-index: 20;
  }
}
</style>
<script>
export default {
  name: "Header",
  data() {
    return {
      notificationsExists: false,
    };
  },
  methods: {
    checkUnReadNotifications() {
      this.axios
        .get("api/Notifications/CheckNewNotifications", {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        })
        .then((res) => {
          this.notificationsExists = res.data.data;
        });
    },
    notifyUp(title, body, urls) {
      var audio = new Audio(require('../assets/sounds/notifications-sound.wav'));
      audio.play();
      this.$swal({
        icon: "info",
        title: title,
        text: body,
        toast: true,
        position: "bottom-end",
        showConfirmButton: true,
        confirmButtonText: "Take a Look !",
        confirmButtonColor: "#e40464",
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$router.push(urls[0]);
        }
      });
      this.notificationsExists = true;
    },
    toggleNotifications() {
      this.notificationsExists = !this.notificationsExists;
    },
    toggleAside() {
      alert();
    },
  },
  created() {
    this.$root.$on("updateNotifications", () =>
      this.checkUnReadNotifications()
    );
    this.$newshub.$on("new-notification-added", this.notifyUp);
    this.checkUnReadNotifications();
  },
};
</script>
