<template>
  <div class="shipments pageCon" :class="{ blured: $store.state.blured }">
    <header class="shipments__headers text-center text-uppercase mb-4">
      <h1>your shipments</h1>
      <p>track your shipment from here</p>
    </header>

    <!--start search -->
    <div class="shipments__search" v-if="!loadData">
      <div class="row">
        <div class="col-md-4 mb-3 mb-md-0">
          <label>search by</label>
          <select class="custom-select">
            <option>Work Order</option>
          </select>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label>reference</label>
            <input
              placeholder="Write Down your Work Order number"
              type="search"
              class="form-control"
              v-model="searchInp"
            />
          </div>
          <div class="text-right">
            <button
              :disabled="spinner || searchInp == ''"
              @click="search()"
              class="btn btn-p"
            >
              <div
                class="spinner-border text-secondary d-inline-block"
                role="status"
                style="width: 1rem; height: 1rem"
                v-if="spinner"
              >
                <span class="sr-only">Loading...</span>
              </div>
              submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--/ end search -->

    <!--start loading before load data -->
    <div v-if="loadData" class="row">
      <div class="col-md-4 mb-3 mb-md-0">
        <div class="load" style="height: 38px"></div>
      </div>
      <div class="col-md-8">
        <div class="load" style="height: 38px"></div>
      </div>
    </div>
    <!--End loading before load data -->

    <!-- start search result -->
    <div
      class="result animate__animated animate__fadeInUp"
      v-if="searchResult.length != 0"
    >
      <h4 class="result__title mb-3">Search result :</h4>
      <div class="shipments__wrapper">
        <div class="shipments__body">
          <ul class="list-unstyled">
            <li v-for="(ship, is) in searchResult" :key="is">
              <!--Start shipment (Live ) -->
              <div class="shipment" :class="{ 'shipment--live': ship.live }">
                <div class="row align-items-center">
                  <div class="col-sm-6 mb-2 mb-sm-0">
                    <h4
                      class="shipment__title"
                      :class="{ 'shipment__title--live': ship.live }"
                    >
                      {{ ship.live ? "Live" : "delivered" }}
                    </h4>
                  </div>
                  <div class="col-sm-6 d-flex justify-content-sm-end">
                    <div>
                      <p class="mb-0 shipment__id">
                        <span class="font-weight-bold">Work Order Num :</span>
                        {{ ship.workOrderNumber }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 mt-3 mb-4">
                    <div
                      class="
                        d-flex
                        justify-content-between
                        shipment__st-end-title
                        flex-wrap
                      "
                    >
                      <span>{{ ship.pod }}</span>
                      <span class="mb-2">{{ ship.pol }}</span>
                    </div>
                    <ul class="list-unstyled mt-3 shipment__track d-flex">
                      <li
                        v-for="(ev, i) in ship.events"
                        :key="i"
                        :data-current="ev.title"
                        :class="{
                          active: ev.closed && ship.live,
                          last: ev.current && ship.live,
                        }"
                      ></li>
                    </ul>
                  </div>
                  <div
                    class="
                      col-12
                      px-1
                      d-flex
                      justify-content-center justify-content-sm-between
                      mt-5 mt-lg-4
                      flex-wrap
                    "
                  >
                    <div class="showDe order-2 order-sm-1">
                      <button
                        @click="ship.details = !ship.details"
                        class="btn text-primary"
                      >
                        Show details
                        <span v-show="!ship.details">
                          <i class="fas fa-angle-down"></i
                        ></span>
                        <span v-show="ship.details">
                          <i class="fas fa-angle-up"></i
                        ></span>
                      </button>
                    </div>

                    <div
                      class="
                        order-1 order-sm-2
                        mb-2 mb-md-0
                        text-center text-md-right
                      "
                    >
                    <router-link
                      to="/Payments"
                      :class="{ 'btn-p': ship.live, 'btn-s': !ship.live }"
                      class="btn shipment__btn mr-1 mr-sm-3 mb-2 mb-sm-0 ships-btn"
                    >
                      Invoice
                    </router-link>
                    <a
                      :class="{ 'btn-p': ship.live, 'btn-s': !ship.live }"
                      :href="ship.dataDisplayLink"
                      class="btn shipment__btn ships-btn mr-1 mr-sm-3 mb-2 mb-sm-0"
                      target="_blank"
                    >
                      Shipment Data
                    </a>
                    <a 
                    :href="getBL(ship.blDocLInk)" 
                    v-if="ship.blDocLInk"
                     :class="{'btn-p': ship.live, 'btn-s': !ship.live }" 
                     class="btn ships-btn mr-1 mr-sm-3 mb-2 mb-sm-0">B/L</a>
                    </div>
                  </div>
                </div>
              </div>
              <!--End shipment (Live )  -->

              <shipDetails
                @close="ship.details = false"
                v-if="ship.details"
                :events="ship.events"
              ></shipDetails>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ENd search result -->

    <!-- start paginationBtns -->
    <div
      class="
        page
        d-flex
        flex-wrap
        align-items-center
        justify-content-center justify-content-sm-between
        my-4
      "
    >
      <span class="order-2 order-sm-1 mt-3 mt-sm-0 page__details">
        (Page: {{ pageNumber }} of {{ totalPages }}) (Showing: 20) (Total
        shipments: {{ totalRecords }})
      </span>
      <div class="order-1 order-sm-2">
        <button
          :disabled="loadData"
          class="btn page__btn page__btn--first"
          @click="paginationBtns('first')"
        >
          First
        </button>
        <button
          :disabled="loadData || pageNumber == 1"
          class="btn page__btn ml-2"
          @click="paginationBtns('Prev')"
        >
          <i class="fas fa-angle-left"></i>
        </button>
        <input
          @change="selectPage($event)"
          :value="pageNumber"
          min="1"
          type="number"
          :disabled="loadData"
          class="form-control text-center page__input d-inline-block ml-2"
        />
        <button
          :disabled="loadData"
          class="btn page__btn ml-2"
          @click="paginationBtns('Next')"
        >
          <i class="fas fa-angle-right"></i>
        </button>
        <button
          @click="paginationBtns('Last')"
          :disabled="loadData"
          class="btn page__btn page__btn--last ml-2"
        >
          Last
        </button>
      </div>
    </div>
    <!-- End paginationBtns -->
    <div class="shipments__wrapper" v-if="!loadData">
      <div class="shipments__body">
        <ul class="list-unstyled">
          <li v-for="(ship, is) in shipments.data" :key="is">
            <!--Start shipment (Live ) -->
            <div class="shipment" :class="{ 'shipment--live': ship.live }">
              <div class="row align-items-center">
                <div class="col-sm-6 mb-2 mb-sm-0">
                  <h4
                    class="shipment__title"
                    :class="{ 'shipment__title--live': ship.live }"
                  >
                    {{ ship.live ? "Live" : "delivered" }}
                  </h4>
                </div>
                <div class="col-sm-6 d-flex justify-content-sm-end">
                  <div>
                    <p class="mb-0 shipment__id">
                      <span class="font-weight-bold">Work Order Num :</span>
                      {{ ship.workOrderNumber }}
                    </p>
                  </div>
                </div>
                <div class="col-12 mt-3 mb-4">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      shipment__st-end-title
                      flex-wrap
                    "
                  >
                    <span>{{ ship.pod }}</span>
                    <span class="mb-2">{{ ship.pol }}</span>
                  </div>
                  <ul class="list-unstyled mt-3 shipment__track d-flex">
                    <li
                      v-for="(ev, i) in ship.events"
                      :key="i"
                      :data-current="ev.title"
                      :class="{
                        active: ev.closed && ship.live,
                        last: ev.current && ship.live,
                      }"
                    ></li>
                  </ul>
                </div>
                <div
                  class="
                    col-12
                    px-1
                    d-flex
                    justify-content-center justify-content-sm-between
                    mt-5 mt-lg-4
                    flex-wrap
                  "
                >
                  <div class="showDe order-2 order-sm-1">
                    <button
                      @click="ship.details = !ship.details"
                      class="btn text-primary"
                    >
                      Show details
                      <span v-show="!ship.details">
                        <i class="fas fa-angle-down"></i
                      ></span>
                      <span v-show="ship.details">
                        <i class="fas fa-angle-up"></i
                      ></span>
                    </button>
                  </div>

                  <div
                    class="
                      order-1 order-sm-2
                      mb-2 mb-md-0
                      text-center text-md-right
                    "
                  >
                    <router-link
                      to="/Payments"
                      :class="{ 'btn-p': ship.live, 'btn-s': !ship.live }"
                      class="btn shipment__btn mr-1 mr-sm-3 mb-2 mb-sm-0 ships-btn"
                    >
                      Invoice
                    </router-link>
                    <a
                      :class="{ 'btn-p': ship.live, 'btn-s': !ship.live }"
                      :href="ship.dataDisplayLink"
                      class="btn shipment__btn ships-btn mr-1 mr-sm-3 mb-2 mb-sm-0"
                      target="_blank"
                    >
                      Shipment Data
                    </a>
                    <a 
                    @click="getBL(ship.blDocLInk,`BL-${ship.workOrderNumber}.pdf`)" 
                    v-if="ship.blDocLInk"
                     :class="{'btn-p': ship.live, 'btn-s': !ship.live }" 
                     class="btn ships-btn mr-1 mr-sm-3 mb-2 mb-sm-0">
                     B/L
                     </a>
                  </div>
                </div>
              </div>
            </div>
            <!--End shipment (Live )  -->

            <shipDetails
              @close="ship.details = false"
              v-if="ship.details"
              :events="ship.events"
            ></shipDetails>
          </li>
        </ul>
      </div>
    </div>
    <!--/ wrapper -->

    <!--start loading before load data -->
    <div v-if="loadData" class="my-3">
      <div
        class="load mb-3"
        v-for="(v, i) in new Array(5)"
        :key="i"
        style="height: 300px"
      ></div>
    </div>
    <!--End loading before load data -->

    <!-- start paginationBtns -->
    <div
      class="
        page
        d-flex
        flex-wrap
        align-items-center
        justify-content-center justify-content-sm-between
        my-4
      "
    >
      <span class="order-2 order-sm-1 mt-3 mt-sm-0 page__details">
        (Page: {{ pageNumber }} of {{ totalPages }}) (Showing: 20) (Total
        shipments: {{ totalRecords }})
      </span>
      <div class="order-1 order-sm-2">
        <button
          :disabled="loadData"
          class="btn page__btn page__btn--first"
          @click="paginationBtns('first')"
        >
          First
        </button>
        <button
          :disabled="loadData || pageNumber == 1"
          class="btn page__btn ml-2"
          @click="paginationBtns('Prev')"
        >
          <i class="fas fa-angle-left"></i>
        </button>
        <input
          @change="selectPage($event)"
          :value="pageNumber"
          min="1"
          type="number"
          :disabled="loadData"
          class="form-control text-center page__input d-inline-block ml-2"
        />
        <button
          :disabled="loadData"
          class="btn page__btn ml-2"
          @click="paginationBtns('Next')"
        >
          <i class="fas fa-angle-right"></i>
        </button>
        <button
          @click="paginationBtns('Last')"
          :disabled="loadData"
          class="btn page__btn page__btn--last ml-2"
        >
          Last
        </button>
      </div>
    </div>
    <!-- End paginationBtns -->
  </div>
</template>

<style scoped lang="scss">
.ships-btn{
  padding-right: 15px;
  padding-left:15px ;
  margin-right: 0.3rem!important;
  font-size: 14px;
}
.shipments {
  overflow: hidden;
  &__search {
    input {
      height: 38px;
    }
    .btn-p:disabled {
      background-color: #333 !important;
    }
  }
  &__wrapper {
    background: color(white);
    padding: 20px;
    @include shadow(cardShadow);
    @include radius(10px);
  }
  &__btn {
    font-size: 20px;
    @include radius(0);
    &--active {
      border-bottom: 2px solid color(text);
    }
  }
  .shipment {
    border: 3px solid color(borderGray);
    padding: 20px;
    @include query(maxS) {
      padding: 15px;
    }
    @include radius(8px);
    &__st-end-title {
      font-size: 18px;
      span {
        text-transform: capitalize;
      }
      @include query(maxS) {
        font-size: 14px;
        font-weight: bold;
      }
    }
    &__title {
      color: color(text);
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      &--live {
        &:before {
          content: "";
          width: 14px;
          height: 14px;
          top: 20px;
          @include radius(50%);
          background: color(primary);
          display: inline-block;
          margin-right: 4px;
          position: relative;
          top: -1px;
        }
      }
    }
    &__date,
    &__id {
      font-size: 18px;
      color:color(text) span {
        color: color(borderGray);
        text-transform: uppercase;
      }
    }
    &--live {
      border: 3px solid color(primary);
    }
    &__track {
      width: 100%;
      li {
        background: color(borderGray);
        width: 100%;
        height: 3px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 17px;
          height: 17px;
          background: color(gray);
          @include radius(50%);
          left: -1px;
          top: -9px;
          display: none;
        }
        &:first-of-type {
          &:before {
            display: block;
          }
          &.active:before {
            background: color(primary);
          }
        }
        &:last-of-type {
          &:before {
            display: block;
            left: auto;
            right: -1px;
          }
          &.active:before {
            background: color(primary);
          }
        }
        &.active {
          background: color(primary);
        }
        &.active.last {
          cursor: pointer;
          &:after {
            content: "";
            background: color(primary);
            width: 17px;
            height: 17px;
            position: absolute;
            font-weight: bold;
            right: -1px;
            top: -9px;
            font-size: 18px;
            @include normalTrans;
            @include radius(50%);
            @include query(maxS) {
              font-size: 12px;
              bottom: -24px;
            }
          }
          &:before {
            content: attr(data-current);
            top: 9px;
            right: -28px;
            position: absolute;
            display: inline-block;
            left: auto;
            background: none;
            width: auto;
            height: auto;
            font-size: 14px;
            font-weight: bold;
            white-space: pre-wrap;
            line-height: 1;
            min-width: 65px;
            @include radius(0);
            @include query(maxS) {
              top: 10px;
            }
          }
          &.active.last:last-of-type {
            &:before {
              display: none !important;
            }
          }
          &.active.last:first-of-type {
            &:before {
              right: auto;
              left: -1px;
            }
          }
          span {
            right: -10px;
            top: -40px;
            font-weight: bold;
            font-size: 22px;
            display: none;
          }
          &:hover {
            span {
              display: block;
            }
          }
        }
      }
    }
    &__btn {
      font-size: 14px;
      @include query(maxS) {
        font-size: 12px;
      }
    }
  }
  &__body > ul > li:not(:last-of-type) {
    border-bottom: 1px solid color(borderGray2);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.page {
  &__details {
    @include query(maxS) {
      font-size: 13px;
    }
  }
  &__btn {
    background: color(primary2);
    color: color(white);
    &:hover {
      background-color: color(gray10);
    }
    &--first,
    &--last {
      background-color: color(white);
      color: color(primary);
      font-weight: bold;
      text-transform: uppercase;
    }
    &:disabled {
      background: color(gray10);
      color: color(white);
    }
  }
  &__input {
    width: 55px;
    height: 38px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.result {
  &__title {
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    color: color(text);
  }
}
::v-deep {
  .details {
    background: color(white);
    padding: 20px 20px 0;
    margin-top: 30px;
    &__ul {
      display: flex;
      flex-wrap: wrap;
      @include query(maxS) {
        display: block;
      }
    }
    &__item {
      margin-bottom: 30px;
      margin-right: 8px;
      @include query(maxS) {
        width: 100%;
        border-bottom: 1px solid color(gray4);
        padding-bottom: 20px;
        margin-right: 0;
      }
    }
    &__status {
      background: color(gray);
      display: inline-block;
      width: 15px;
      height: 15px;
      @include radius(50%);
      position: relative;
      top: 2px;

      &--active {
        background: color(primary);
      }
      &--loading {
        background: color(primary2);
      }
    }
    &__place {
      font-size: 19px;
      @include query(maxS) {
        font-size: 16px;
      }
    }
    &__date {
      position: absolute;
      color: color(gray8);
      font-size: 14px;
      top: -14px;
      font-weight: bold;
      left: 18px;
      @include query(maxS) {
        font-size: 12px;
      }
    }
    &__btn {
      background-color: color(gray10);
      color: color(white);
      @include radius(40px);
      padding-right: 2.4em;
      padding-left: 2.4em;
      text-transform: uppercase;
      @include normalTrans;
      &:hover {
        background: color(gray);
        color: color(white);
      }
    }
  }
}
</style>

<script>
    export default {
        name: "Shipments",
        data() {
            return {
                totalRecords: 0,
                spinner: false,
                loadData: true,
                shipments: [],
                searchResult: [],
                searchInp: "",
                today: new Date().toISOString(),
                totalPages: 0,
                pageSize: 0,
                pageNumber: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
            };
        },
        watch: {
            pageNumber(e) {
                this.$router.push({ path: "Shipments", query: { page: `${e}` } });
            },
            searchInp(val) {
                var nonNumReg = /[^0-9]/g;
                this.searchInp = val.replace(nonNumReg, '');
            }
        },
        methods: {
            paginationBtns(btn) {
                if (!this.loadData) {
                    if (btn == "first") {
                        if (this.pageNumber != 1) {
                            this.loadData = true;
                            this.pageNumber = 1;
                            this.getShipments();
                        }
                    } else if (btn == "Last") {
                        if (this.pageNumber != this.totalPages) {
                            this.loadData = true;
                            this.pageNumber = this.totalPages;
                            this.getShipments();
                        }
                    } else if (btn == "Next") {
                        if (this.totalPages != this.pageNumber) {
                            this.loadData = true;
                            this.pageNumber += 1;
                            this.getShipments();
                        }
                    } else {
                        if (this.pageNumber != 1) {
                            this.pageNumber--;
                            this.loadData = true;
                            this.getShipments();
                        }
                    }
                }
            },
            selectPage(ev) {
                if (ev.target.value <= 0) {
                    this.pageNumber = 1;
                } else if (ev.target.value >= 10) {
                    this.pageNumber = this.totalPages;
                } else {
                    this.pageNumber = parseInt(ev.target.value);
                }
                this.loadData = true;
                this.getShipments();
            },
            getShipments() {
                this.axios
                    .get(`api/Shipments?pagesize=20&pagenumber=${this.pageNumber}`, {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.token}`,
                        },
                    })
                    .then((res) => {
                        this.shipments = res.data.data;
                        this.totalPages = res.data.data.totalPages;
                        this.totalRecords = res.data.data.totalRecords;
                        this.loadData = false;
                    });
            },
            search() {
                if (this.searchInp != "" || this.searchInp != null) {
                    this.spinner = true;
                    this.axios
                        .get(`api/Shipments?Search=${this.searchInp}`, {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.token}`,
                            },
                        })
                        .then((res) => {
                            this.searchResult = [];
                            setTimeout(() => {
                                this.searchResult.push(res.data.data.data[0]);
                            }, 500);
                            this.spinner = false;
                        })
                        .catch((err) => {
                            this.spinner = false;
                        });
                }
            },
            getBL(blLink,label) {
                this.axios.get(blLink, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`,
                        responseType: 'blob'
                    },
                }).then((res) => {
                  const blob = new Blob([res.data], { type: "application/pdf" });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = label;
                  link.click();
                  URL.revokeObjectURL(link.href);
                }).catch((err) => {
                    this.$swal("Couldn't download the file", `Unable to download the file for authorization issues , please relogin and try again`, "error");
                });
            }
        },
        components: {
            shipDetails: {
                props: ["events"],
                template: `
        <div class="details overflow-hidden">
          <ul class="list-unstyled details__ul">
            <li class="details__item position-relative" v-for="(v,i) in events" :key="i" v-show="v.eventDate != '9999-12-31T23:59:59.9999999'">
              <span class="details__status mr-1" :class="{'details__status--active' : v.closed}"></span>
              <span class="details__date">
                  {{
                    v.eventDate.slice(0,v.eventDate.indexOf('T'))
                  }}
              </span>
              <span class="font-weight-bold details__place"
                >{{v.title}}</span
              >
            </li>
          </ul>
          <button class='btn details__btn float-right' @click="$emit('close')">Close <i class='fas fa-times'></i></button>
        </div>
      `,
            },
        },
        mounted() {
            this.getShipments();
        },
    };
</script>
