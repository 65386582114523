<template>
    <div class='reqSent mt-5'>
        <h1 class='reqSent__title text-uppercase font-weight-bold mb-5'> <span class='d-inline-block'>thank you</span></h1>

        <i class='fas fa-check-circle '></i>
        <h5 class='font-weight-bold reqSent__outline mt-5 text-uppercase'>your booking has been sent </h5>
        <p class='reqSent__text  text-uppercase'>our operation will contact you soon </p>

        <button @click="$emit('back')" class='btn btn-p mt-5'> Back to booking</button>
    </div>
</template>


<style lang="scss" scoped>
.reqSent{
    background:color(white);
    padding:60px;
    width:700px;
    margin: 50px auto;
    text-align: center;
    @include query(maxS) {
        padding:20px;
    }
    &__title {
        font-size:30px;
        padding-bottom:5px;
        span {
         border-bottom:5px solid color(primary);
        }
        &:after {
            content:"!";
            display: inline-block;
            margin-left:5px;
        }
    }
    svg {
        color:color(primary);
        font-size:100px;
    }
    &__outline {
        font-size:22px;
    }
    &__text {
        font-size:17px;
    }
}
    
</style>
<script>
export default {
    name:"reqSent"
}
</script>