<template>
  <div class="quote overflow-hidden">
    <button class="btn mb-2 font-weight-bold" @click="$emit('closeForm')">
      <i class="text-danger fas fa-angle-left align-middel"></i> Back
    </button>
    <div class="wrap animate__animated animate__fadeInRightBig">
      <!--- quote heade -->
      <header class="quote__header d-flex flex-wrap justify-content-between">
        <h4 class="quote__title text-uppercase">{{ type }} QUOTATION FORM</h4>
        <p class="quote__hint my-2 my-sm-0">
          fill out this form and we will contact you ({{
            $store.getters.loggedin
              ? $store.state.userObject.username
              : "GUEST"
          }})
        </p>
      </header>
      <!--Header End-->
      <!--Main Content Start-->
      <section class="quote__body form-row">
        <div
          class="form-group mb-4 col-12"
          v-if="!this.$store.getters.loggedin"
        >
          <input
            v-model="requesterEmail"
            type="text"
            class="quote__field form-control"
            placeholder="Email"
            @blur="emptyVal($event)"
          />
        </div>
        <!--label-->
        <label class="quote__label col-12 mb-2 mb-md-3"
          >request description</label
        >
        <!--col city -->
        <div class="form-group mb-4 col-12">
          <textarea
            v-model="requestDescription"
            class="quote__field form-control"
            rows="10"
            @blur="emptyVal($event)"
          ></textarea>
        </div>
      </section>
      <!--Main Content end-->
      <!-- submit button -->
      <div class="text-right mr-4 mb-4">
        <button
          :disabled="spinner"
          class="btn btn-p quote__btn"
          @click="sendQuote()"
        >
          <div
            class="spinner-border text-secondary d-inline-block"
            role="status"
            style="width: 1rem; height: 1rem"
            v-if="spinner"
          >
            <span class="sr-only">Loading...</span>
          </div>
          Request your quote
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["frmMode"],
  data() {
    return {
      type: "",
      spinner: false,
      requesterEmail: this.$store.getters.loggedin
        ? this.$store.state.userObject.email
        : "",
      requesterUsername: this.$store.getters.loggedin
        ? this.$store.state.userObject.username
        : "guest",
      requesterPhone: this.$store.getters.loggedin
        ? this.$store.state.userObject.landLine == null
          ? "000000000"
          : this.$store.state.userObject.landLine
        : "000000000",
      requesterLandline: this.$store.getters.loggedin
        ? this.$store.state.userObject.landLine == null
          ? "000000000"
          : this.$store.state.userObject.landLine
        : "000000000",
      requestURL: "",
      requestDescription: "",
    };
  },
  methods: {
    sendQuote() {
      this.spinner = true;
      let obj = {
        requesterEmail: this.requesterEmail,
        requesterUsername: this.requesterUsername,
        requesterPhone: this.requesterPhone,
        requesterLandline: this.requesterLandline,
        requestDescription: this.requestDescription,
      };
      if (this.requestDescription) {
        this.axios
          .post(this.requestURL, obj)
          .then((res) => {
            this.spinner = false;
            this.$emit("closeForm");
            this.$emit("fadeAlert");
          })
          .catch((err) => {
            this.$swal(
              "Couldn't send the request ",
              `Kindly fill all required fields`,
              "error"
            );
            this.spinner = false;
          });
      } else {
        this.$swal(
          "Couldn't send the request ",
          `Kindly fill all required fields`,
          "error"
        );
        this.spinner = false;
      }
    },
  },
  created() {
    this.type = this.frmMode == "CC" ? "CUSTOM CLEARANCE" : "WAREHOUSING";
    this.requestURL =
      this.frmMode == "CC"
        ? "api/Quotations/CCQuote"
        : "api/Quotations/WarehousingQuote";
  },
};
</script>


<style lang="scss" scoped>
.quote {
  .wrap {
    border: 15px solid color(white);
    @include query(maxS) {
      border-width: 8px;
    }
  }
  &__header {
    padding: 10px;
    background: color(white);
    @include query(maxS) {
      padding: 0;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    @include query(maxL) {
      font-size: 17px;
    }
  }
  &__hint {
    text-transform: uppercase;
    color: color(gray10);
    font-weight: bold;
    @include query(maxL) {
      font-size: 12px;
    }
  }
  &__label {
    font-size: 18px;
    color: #121626;
  }
  &__body {
    padding: 30px 30px 0;
    @include query(maxM) {
      padding: 20px 20px 0;
    }
    @include query(maxS) {
      padding: 15px 15px 0;
    }
  }

  &__field {
    color: color(gray10);
    font-size: 17px;
    font-weight: bold;
    border: 1px solid color(gray3);
    &::placeholder {
      font-size: 17px;
    }
    &--require {
      border: 1px solid color(primary) !important;
    }
  }
  &__btn:disabled {
    background: color(gray10) !important;
  }
}
.custom-control-label {
  color: color(gray8);
  font-weight: bold;
  padding-top: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png");
}
</style>