<template lang="html">
    <section class="pageCon notifications">
       <div v-if="notifications" v-for="notification in notifications" :key="notification.notificationID" @click="onClick(notification.notificationID)"
        :class="notification.notified ? 'notifications__read-item' : ''"  class="notifications__new-item">
            <span class="notifications__new-item__title ">
                {{notification.body}} 
            </span>
            <span class="notifications__new-item__date">
                {{notification.notificationDate | FullDate}} 
            </span>
        </div>
        <div class="text-center" v-if="notifications.length===0">
           <img src="../assets/imgs/no-notifications.svg" style="max-height:500px;"/>
           <h2>No Notifications Yet!</h2>
        </div>
    </section>
</template>
 
<script lang="js" >
  export default  {
    name: 'notifications',
    props: [],
    mounted() {
        this.getNotifications();
    },
    data() {
      return {
          notifications:[],
          loading:false,

      }
    },
    methods: {
        getNotifications(){
            this.loading=true;
            this.axios.get("api/Notifications", {
                headers: {Authorization: `Bearer ${this.$store.state.token}`, },})
                .then((res) => {
                    this.notifications = res.data.data;
                    this.loading = false;
                })
                .catch((err) => {
                    if (err.response.status == 400) {
                         this.loading = false;
                    }
                });
        },
        updateNotification(notificationID){
             this.axios.put("api/Notifications",[notificationID], {
                headers: {Authorization: `Bearer ${this.$store.state.token}`, },})
                .then((res) => {
                    this.$root.$emit('updateNotifications');
                })
                .catch((err) => {
                    if (err.response.status == 400) {
                    }
                });
        },
        onClick(notificationID){
            //update the notification status
            let notify=this.notifications.find(n=>n.notificationID==notificationID);
            this.updateNotification(notificationID);
            notify.notified=true;
            //routing to the notification link
            this.$router.push(`${notify.urls}`);
        }
    },
    computed: {
 
    },
    created(){
    this.$newshub.$on("new-notification-added", this.getNotifications);
    }
}
</script>
 
<style scoped lang="scss">
.notifications {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 18px;
  height: 100px;
  &__new-item {
    cursor: pointer;
    background: white;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    color: black;
    font-weight: bold;
    &__title {
      overflow: hidden;
      text-transform: capitalize;
      display: inline-block;
      width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__date {
      display: inline-block;
      color: #a4aaae;
    }
  }
  &__read-item {
    background: #e7ebee;
  }
  @media (max-width: 1400px) {
    .notifications__new-item__title {
      width: 50%;
    }
  }
}
</style>