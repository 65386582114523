<template>
  <div
    class="payments overflow-hidden pageCon"
    :class="{ blured: $store.state.blured }"
  >
      <h1 class="payments__title text-center">Paid invoices</h1>
    <p class="payments__text text-center">manage your Paid invoices</p>
    <router-link to="/Payments" class="font-weight-bold"
      ><i class="fas fa-angle-left text-danger"></i> Back
    </router-link>
    <div class="payments__wrapper mt-4">
      <div class="table__wrapper">
        <div v-if="!loadData" class="p-3">
          <div
            class="load mb-2"
            style="height: 50px; width: 100%"
            v-for="(a, i) in new Array(5)"
            :key="i"
          ></div>
        </div>
        <!--table -->
        <table v-if="loadData" class="table table-striped mb-0">
          <thead class="table__thead">
            <tr>
              <th class="table__th">Work Order ID</th>
              <th class="table__th">Inovice No.</th>
              <th class="table__th">b/l num.</th>
              <th class="table__th">Payment Reference</th>
              <th class="table__th">invoice date</th>
              <th class="table__th">Payment date</th>
              <th class="table__th">type</th>
              <th class="table__th" colspan="3">amount</th>
              <th class="table__th">Print</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, i) in invoices" :key="i">
              <td class="table__td">
                <button class="btn text-primary">
                  {{ invoice.workOrderID }}
                </button>
              </td>
              <td class="table__td">
                {{ invoice.invoiceID }}
              </td>
              <td class="table__td">{{ invoice.bl }}</td>
              <td class="table__td">{{ invoice.paymentReference }}</td>
              <td class="table__td">
                {{
                  invoice.invoiceDate.slice(
                    invoice.invoiceDate,
                    invoice.invoiceDate.indexOf("T")
                  )
                }}
              </td>
              <td class="table__td">
                {{
                  invoice.paymentDate.slice(
                    invoice.paymentDate,
                    invoice.paymentDate.indexOf("T")
                  )
                }}
              </td>
              <td class="table__td">{{ invoice.type }}</td>
              <td class="table__td text-primary">{{ invoice.amounts[0] }}</td>
              <td class="table__td text-primary">{{ invoice.amounts[1] }}</td>
              <td class="table__td text-primary">{{ invoice.amounts[2] }}</td>
              <td class="table__td">
                <button class="btn btn-p" @click="printInvoices(invoice)">
                  Print <i class="fas fa-print"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!--/tabler -->
      </div>
    </div>

    <print :invoice="invoice" v-if="print"></print>
  </div>
</template>

<style lang="scss" scoped>
// @import  "../../styles/buttons";

.payments {
  &__title {
    font-size: 28px;
    font-weight: bold;
  }
  &__text {
    text-transform: capitalize;
    font-size: 18px;
  }
  &__wrapper {
    overflow: hidden;
    background: color(white);
    @include radius(10px);
    @include shadow(cardShadow);
  }
  &__btn:disabled {
    background: color(gray10);
  }
  .table {
    text-align: center;
    border-collapse: separate;
    border-spacing: 0em 1em;
    background: color(white);
    padding: 20px;
    @include query(maxL) {
      padding: 10px;
    }
    &__wrapper {
      @include query(maxL) {
        overflow-x: auto;
      }
    }
    &__td,
    &__th {
      white-space: nowrap;
      padding: 0rem 0.5rem;
      border: none;
      text-transform: uppercase;
      vertical-align: middle;
      font-size: 13px;
      button {
        text-transform: uppercase;
        font-weight: bold;
        color: color(primary);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
        @include query(maxS) {
          font-size: 12px;
        }
      }
      .btn-p {
        color: color(white);
        padding: 0.3rem 0.8rem;
        font-size: 13px;
        text-decoration: none;
      }
      @include query(maxS) {
        font-size: 12px;
      }
    }
    &__th {
      padding-bottom: 20px;
    }
  }
  .totals {
    padding: 20px;
    margin-top: 50px;
    hr {
      border-bottom: 2px solid color(borderGray2);
    }
    &__wrapper {
      width: 400px;
      @include query(maxM) {
        width: 50%;
      }
      @include query(maxS) {
        width: 100%;
      }
    }
    &__label {
      position: absolute;
      left: 10px;
      top: 12px;
    }
  }
  &__link {
    text-decoration: underline !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      text-decoration: none !important;
    }
  }
}

.custom-control-label {
  position: relative;
  top: -5px;
  left: 15px;
  @include query(maxS) {
    top: 0;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  background-image: url("../../assets/imgs/check.png") !important;
}
</style>

<script>
import print from "../../components/Print.vue";
export default {
  name: "PreveiousPayments",
  data() {
    return {
      popup: false,
      blured: false,
      invoices: [],
      loadData: false,
      orderObj: [],
      spinner: false,
      print: false,
      invoice:[],
    };
  },
  methods: {
    printInvoices(invoice) {
      this.invoice = invoice;
      this.print = true;
      setTimeout(() => {
        this.$htmlToPaper("printMe");
        this.print = false;
      });
    },
  },
  components: {
    print,
  },
  mounted() {
    this.axios
      .get("/api/Payment/GetPaidInvoices", {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`,
        },
      })
      .then((res) => {
        this.invoices = res.data.data.data;
        this.loadData = true;
      });
  },
};
</script>
