<template>
  <div class="quote pageCon" :class="{ blured: $store.state.blured }">
    <!-- Start quote services wrapper -->
    <section
      class="quote__wrap overflow-hidden"
      v-if="
        !fclFormShow &&
        !truckingShow &&
        !CCFormShow &&
        !WHFormShow &&
        !lclShow &&
        !airShow
      "
    >
      <h1
        class="text-center quote__h1 mb-1 animate__animated animate__fadeInUp"
      >
        REQUEST QUOTATION
      </h1>
      <p class="text-center quote__p animate__animated animate__fadeInUp">
        CHOOSE THE SERVICE
      </p>
      <div class="custom-alert mt-0" v-if="alert">
        Request Has been sent <i class="fas fa-check text-success"></i>
      </div>
      <div class="row">
        <!-- col track and trace -->
        <div class="col-lg-4 col-md-6 mb-4 animate__animated animate__bounceIn">
          <div
            @click="fadeLclAirForm('lcl')"
            @mouseover="imgs.lcl.img = imgs.lcl.hover"
            @mouseleave="imgs.lcl.img = imgs.lcl.regImg"
          >
            <div class="quote__card text-center">
              <img :src="imgs.lcl.img" class="quote__icon" width="140" />
              <h5 class="quote__title mt-2">LCL (SEA FREIGHT)</h5>
            </div>
          </div>
        </div>
        <!-- col tSHIPMENTS -->
        <div class="col-lg-4 col-md-6 mb-4 animate__animated animate__bounceIn">
          <div
            @click="fadeFclForm()"
            @mouseover="imgs.fcl.img = imgs.fcl.hover"
            @mouseleave="imgs.fcl.img = imgs.fcl.regImg"
          >
            <div class="quote__card text-center">
              <img :src="imgs.fcl.img" class="quote__icon" width="140" />
              <h5 class="quote__title mt-2">FCL (SEA FREIGHT)</h5>
            </div>
          </div>
        </div>
        <!-- col REQUEST QUOTE-->
        <div class="col-lg-4 col-md-6 mb-4 animate__animated animate__bounceIn">
          <div
            @click="fadeLclAirForm('air')"
            @mouseover="imgs.air.img = imgs.air.hover"
            @mouseleave="imgs.air.img = imgs.air.regImg"
          >
            <div class="quote__card text-center">
              <img :src="imgs.air.img" class="quote__icon" width="140" />
              <h5 class="quote__title mt-2">AIR FREIGHT</h5>
            </div>
          </div>
        </div>
        <!-- col Booking -->
        <div class="col-lg-4 col-md-6 mb-4 animate__animated animate__bounceIn">
          <div
            @click="fadeTrucking()"
            @mouseover="imgs.trucking.img = imgs.trucking.hover"
            @mouseleave="imgs.trucking.img = imgs.trucking.regImg"
          >
            <div class="quote__card text-center">
              <img :src="imgs.trucking.img" class="quote__icon" width="140" />
              <h5 class="quote__title mt-2">TRUCKING</h5>
            </div>
          </div>
        </div>

        <!-- col DATA-->
        <div class="col-lg-4 col-md-6 mb-4 animate__animated animate__bounceIn">
          <div
            @click="fadeFreeTyping('CC')"
            @mouseover="imgs.custom.img = imgs.custom.hover"
            @mouseleave="imgs.custom.img = imgs.custom.regImg"
          >
            <div class="quote__card text-center">
              <img :src="imgs.custom.img" class="quote__icon" width="140" />
              <h5 class="quote__title mt-2">CUSTOM CLEARANCE</h5>
            </div>
          </div>
        </div>

        <!-- col PAYMTNES-->
        <div class="col-lg-4 col-md-6 mb-4 animate__animated animate__bounceIn">
          <div
            @click="fadeFreeTyping('WH')"
            @mouseover="imgs.house.img = imgs.house.hover"
            @mouseleave="imgs.house.img = imgs.house.regImg"
          >
            <div class="quote__card text-center">
              <img :src="imgs.house.img" class="quote__icon" width="140" />
              <h5 class="quote__title mt-2">WAREHOUSING</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End quote services wrapper -->
    <!--LCL Form-->
    <lclAirForm
      @closeForm="lclShow = false"
      v-if="lclShow"
      type="LCL"
      @fadeAlert="fadeAlert()"
    ></lclAirForm>
    <!--Air Form-->
    <lclAirForm
      @closeForm="airShow = false"
      v-if="airShow"
      type="AIR"
      @fadeAlert="fadeAlert()"
    ></lclAirForm>
    <!--FCL Form-->
    <fclForm
      @closeForm="fclFormShow = false"
      v-if="fclFormShow"
      @fadeAlert="fadeAlert()"
    >
    </fclForm>
    <!--Trucking Form-->

    <truckingForm
      @closeForm="truckingShow = false"
      v-if="truckingShow"
      @fadeAlert="fadeAlert()"
    >
    </truckingForm>
    <!--CC & Warehousing Form-->
    <freeTypingForm
      @closeForm="CCFormShow = false"
      v-if="CCFormShow"
      frmMode="CC"
      @fadeAlert="fadeAlert()"
    >
    </freeTypingForm>

    <freeTypingForm
      @closeForm="WHFormShow = false"
      v-if="WHFormShow"
      frmMode="WH"
      @fadeAlert="fadeAlert()"
    >
    </freeTypingForm>
  </div>
</template>

<style lang="scss" scoped>
.quote {
  &__h1 {
    font-size: 25px;
  }
  &__p {
    margin-bottom: 50px;
    animation-delay: 0.5rem;
  }
  &__card {
    cursor: pointer;
    padding: 40px 20px;
    @include radius(2px);
    background: color(white);
    @include shadow(cardShadow);
    position: relative;
    &::before {
      content: "";
      right: 0;
      top: 0;
      width: 14px;
      height: 0;
      position: absolute;
      background: transparent;
      @include normalTrans;
    }
    &:hover {
      &::before {
        background: color(primary);
        height: 44px;
      }
      .quote__icon {
        color: color(primary) !important;
      }
    }
  }
  &__icon {
    font-size: 80px;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 20px;
    font-weight: 600;
    @include normalTrans;
    @include query(maxXL) {
      font-size: 18px;
    }
  }
}
@for $i from 1 through 6 {
  .col-lg-4:nth-child(#{$i}) {
    -webkit-animation-delay: (#{$i * 0.1s});
    animation-delay: (#{$i * 0.1s});
  }
}
</style>
<script>
import lclAirForm from "../../components/clients/Lcl_Air_Quotation_Form.vue";
import fclForm from "../../components/clients/Fcl_Quotation_Form.vue";
import truckingForm from "../../components/clients/Trucking_Quotation_Form.vue";
import freeTypingForm from "../../components/clients/FreeTyping_Quotation_Form.vue";

export default {
  name: "ReqQuote",
  data() {
    return {
      lclShow: false,
      airShow: false,
      CCFormShow: false,
      WHFormShow: false,
      truckingShow: false,
      fclFormShow: false,
      alert: false,
      imgs: {
        lcl: {
          img: require("../../assets/imgs/quotation/LCL.png"),
          regImg: require("../../assets/imgs/quotation/LCL.png"),
          hover: require("../../assets/imgs/quotation/LCLH.png"),
        },
        fcl: {
          img: require("../../assets/imgs/quotation/FCL.png"),
          regImg: require("../../assets/imgs/quotation/FCL.png"),
          hover: require("../../assets/imgs/quotation/FCLH.png"),
        },
        air: {
          img: require("../../assets/imgs/quotation/Airfrieght.png"),
          regImg: require("../../assets/imgs/quotation/Airfrieght.png"),
          hover: require("../../assets/imgs/quotation/AirfrieghtH.png"),
        },
        trucking: {
          img: require("../../assets/imgs/quotation/Trucking.png"),
          regImg: require("../../assets/imgs/quotation/Trucking.png"),
          hover: require("../../assets/imgs/quotation/TruckingH.png"),
        },
        custom: {
          img: require("../../assets/imgs/quotation/CustomsClearance.png"),
          regImg: require("../../assets/imgs/quotation/CustomsClearance.png"),
          hover: require("../../assets/imgs/quotation/CustomsClearanceH.png"),
        },
        house: {
          img: require("../../assets/imgs/quotation/Warehousing.png"),
          regImg: require("../../assets/imgs/quotation/Warehousing.png"),
          hover: require("../../assets/imgs/quotation/WarehousingH.png"),
        },
      },
    };
  },
  components: {
    lclAirForm,
    fclForm,
    truckingForm,
    freeTypingForm,
  },
  methods: {
    fadeFreeTyping(mode) {
      switch (mode) {
        case "CC":
          this.CCFormShow = true;
          this.WHFormShow = false;
          break;
        case "WH":
          this.CCFormShow = false;
          this.WHFormShow = true;
          break;
      }
      this.truckingShow = false;
      this.lclShow = false;
      this.airShow = false;
      this.fclFormShow = false;
    },
    fadeTrucking() {
      this.truckingShow = true;
      this.fclFormShow = false;
      this.CCFormShow = false;
      this.WHFormShow = false;
      this.lclShow = false;
      this.airShow = false;
    },
    fadeFclForm() {
      this.fclFormShow = true;
      this.truckingShow = false;
      this.CCFormShow = false;
      this.WHFormShow = false;
      this.lclShow = false;
      this.airShow = false;
    },
    fadeLclAirForm(mode) {
      switch (mode) {
        case "lcl":
          this.lclShow = true;
          this.airShow = false;
          break;
        case "air":
          this.lclShow = false;
          this.airShow = true;
          break;
      }
      this.fclFormShow = false;
      this.truckingShow = false;
      this.CCFormShow = false;
      this.WHFormShow = false;
    },
    fadeAlert() {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
  },
};
</script>
