<template>
  <div id="printMe" class="print">
    <header class="px-4">
      <img src='../assets/imgs/print-invoice.png' class='w-100'>
    </header>
    <ul class="list-unstyled p-4">
      <li class="print__li">
        <div class="d-flex justify-content-between mb-3">
          <div class="print__col">
            <h4 class="print__h4 text-uppercase text-p font-weight-bold">
              work order id
            </h4>
            <p>{{invoice.workOrderID}}</p>
          </div>
          <div class="print__col">
            <h4 class="print__h4 text-uppercase text-p font-weight-bold">
              type
            </h4>
            <p>{{invoice.type}}</p>
          </div>
          <div class="print__col">
            <h4 class="print__h4 text-uppercase text-p font-weight-bold">
              B/L num.
            </h4>
            <p>{{invoice.bl}}</p>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <div class="print__col">
            <h4 class="print__h4 text-uppercase text-p font-weight-bold">
              invoice no.
            </h4>
            <p>483213219</p>
          </div>
          <div class="print__col">
            <h4 class="print__h4 text-uppercase text-p font-weight-bold">
              invoice date
            </h4>
            <p>{{invoice.invoiceDate.slice(0,invoice.invoiceDate.indexOf('T'))}}</p>
          </div>
          <div class="print__col">
            <h4 class="print__h4 text-uppercase text-p font-weight-bold">
              payment date
            </h4>
            <p>{{invoice.paymentDate.slice(0,invoice.paymentDate.indexOf('T'))}}</p>
          </div>
        </div>
        <div class="print__total d-flex justify-content-between">
          <div class="print__totalCol text-p text-uppercase">
            <p>Amount</p>
          </div>
          <div class="print__totalCol">
            <ul class="list-unstyled">
              <li>{{invoice.amounts[0]}}</li>
              <li>{{invoice.amounts[0]}}</li>
              <li>{{invoice.amounts[0]}}</li>
            </ul>
          </div>
          <div>
            <div class="print__totalLE text-uppercase mb-2 mt-4">
              total amount in l.e
            </div>
            <p class="pl-3">{{invoice.total}} L.E</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.print {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2000;
  background: color(white);
  padding: 40px;
  font-size: 22px;
  font-weight: bold;
  display:none;
  &__col {
    width: calc(100% / 5);
  }
  &__totalCol {
    width: 40%;
    &:first-of-type {
      width: 20%;
    }
  }
  &__li {
    &:not(:last-of-type) {
      border-bottom: 3px solid color(primary);
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }
  &__totalCol {
    li {
      padding-bottom: 8px;
      font-weight: bold;
      &:not(:last-of-type) {
        border-bottom: 3px solid color(primary);
      }
    }
  }
  &__totalLE {
    background-color: color(body);
    padding: 5px 10px;
    @include radius(5px);
  }
}
</style>
<script>
export default {
  name: "print",
  props:['invoice'],
  data() {
    return {};
  },
  methods: {

  },
};
</script>
