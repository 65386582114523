<template>
  <div class="main-container">
    <div
      class="h-100 d-flex align-items-center justify-content-center bg-white px-3"
    >
      <!-- Login Form -->
      <form
        @submit.prevent="sendLogin"
        class="d-flex flex-column align-items-center"
        v-if="activeForm === 0"
      >
        <router-link to="/">
          <img src="../assets/imgs/logo.svg" width="164" />
        </router-link>
        <p style="opacity: 70%">Welcome ! Please login to your account.</p>
        <div
          class="custom-alert"
          v-if="loginAlertFail && !spinner"
          style="
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-bottom: 0;
          "
        >
          username or password not correct
          <span style="height: 20px"
            ><i class="fas fa-times text-danger"></i
          ></span>
        </div>
        <div class="w-100">
          <input
            @blur="mail()"
            v-model="form.email"
            type="text"
            placeholder="Email"
            :class="{ notValid: email }"
          />
          <div class="text-p valid">{{ mailText }}</div>
        </div>
        <div class="w-100 position-relative">
          <input
            @blur="pass()"
            :class="{ notValid: password }"
            type="password"
            placeholder="Password"
            v-model="form.password"
          />
          <div class="text-p valid">{{ passwordText }}</div>
        </div>
        <div class="button-container row w-100">
          <router-link to="/SignUp" class="col sign-up">Sign up</router-link>
          <button
            type="submit"
            class="col login home__loginBtn btn"
            :disabled="spinner"
          >
            <span v-if="!spinner">Login</span>
            <div
              class="spinner-border text-secondary d-inline-block"
              role="status"
              style="width: 1rem; height: 1rem"
              v-if="spinner"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
        <button
          type="button"
          class="align-self-end forget"
          @click="activeForm = 1"
          :disabled="spinner"
        >
          Forget Password
        </button>
      </form>

      <!-- Forget Password Form -->
      <form
        @submit.prevent="sendForget"
        v-if="activeForm === 1"
        class="d-flex flex-column align-items-center"
      >
        <router-link to="/">
          <img src="../assets/imgs/logo.svg" width="164" />
        </router-link>
        <p>Enter your email and we will send you a new password</p>
        <div
          class="custom-alert"
          v-if="forgetAlertFail && !spinner"
          style="
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-bottom: 0;
          "
        >
          Couldn't find this email in our database!
          <span style="height: 20px"
            ><i class="fas fa-times text-danger"></i
          ></span>
        </div>
        <div class="w-100">
          <input
            @blur="mail()"
            v-model="form.email"
            type="text"
            placeholder="Email"
            :class="{ notValid: email }"
          />
          <div class="text-p valid">{{ mailText }}</div>
        </div>
        <div class="button-container row w-100">
          <button
            type="button"
            class="col sign-up cancel"
            @click="activeForm = 0"
            :disabled="spinner"
          >
            Cancel
          </button>
          <button
            :disabled="spinner"
            type="submit"
            class="col login home__loginBtn btn"
          >
            <span v-if="!spinner">Confirm</span>
            <div
              class="spinner-border text-secondary d-inline-block"
              role="status"
              style="width: 1rem; height: 1rem"
              v-if="spinner"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </form>

      <div
        v-if="activeForm === 2"
        style="gap: 20px"
        class="d-flex flex-column align-items-center"
      >
        <router-link to="/">
          <img src="../assets/imgs/logo.svg" width="164" />
        </router-link>
        <img src="../assets/imgs/Check Mark.svg" width="100" />
        <p
          style="
            color: #707070;
            max-width: 400px;
            text-wrap: pretty;
            text-align: center;
          "
        >
          Please check your E-mail for a confirmation link after confirming we
          will send you a new password
        </p>
        <button
          style="
            background-color: #ba1e67;
            padding: 10px 0;
            width: 100%;
            border: none;
            color: white;
            border-radius: 4px;
          "
          @click="activeForm = 0"
        >
          Back to login
        </button>
      </div>
    </div>
    <div class="h-100 d-flex align-items-center justify-content-center">
      <div style="width: 733px">
        <p class="quest" style="margin-bottom: 34px; padding: 0 20px">
          As a quest you can choose an option below , or
          <router-link
            to="/SignUp"
            style="text-decoration: underline !important"
            >signup</router-link
          >
          for more
        </p>
        <div class="card-container">
          <router-link to="/Trace_trace" class="card">
            <img src="../assets/imgs/track.svg" width="60" height="60" />
            <p>Track & Trace</p>
          </router-link>
          <router-link to="/Request_quote" class="card">
            <img src="../assets/imgs/request-quote.svg" height="60" />
            <p>Request Quote</p>
          </router-link>
          <router-link to="/Docs_Request" class="card">
            <img src="../assets/imgs/doc-2.svg" width="45" height="60" />
            <p>Request Documents</p>
          </router-link>
          <a download :href="files[0] ? files[0].fileLink : ''" class="card">
            <img src="../assets/imgs/company.svg" height="60" />
            <p>Company Profile</p>
          </a>
          <div v-if="!showSailing" class="card" @click="showSailing = true">
            <img src="../assets/imgs/sailing.svg" height="60" />
            <p>Sailing Schedules</p>
          </div>
          <div
            v-else
            class="card"
            style="
              padding-top: 17px;
              display: flex;
              flex-direction: column;
              gap: 5px;
              cursor: default;
              overflow: hidden;
            "
          >
            <p style="font-weight: normal">Choose one</p>
            <a
              download
              :href="files[2] ? files[2].fileLink : ''"
              style="
                flex-grow: 1;
                width: 100%;
                background-color: white;
                border: none;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Export
            </a>
            <a
              download
              :href="files[1] ? files[1].fileLink : ''"
              style="
                flex-grow: 1;
                width: 100%;
                background-color: #e30463;
                border: none;
                font-weight: bold;
                color: white;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              Import
            </a>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 6px 0;
            "
          >
            <router-link to="/Contact" class="sub-card">Contact us</router-link>
            <router-link to="/About" href="#" class="sub-card"
              >About Us</router-link
            >
            <router-link to="/Terms-policies" href="#" class="sub-card"
              >Policies & Terms</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-container {
  width: 100svw;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  & > div {
    width: 50%;
  }
  form {
    width: 422px;
    gap: 20px;
    color: #43425d;
    input {
      width: 100%;
      border: none;
      border-bottom: 2px solid #e9e9f0;
      transition: border-bottom-color 200ms;
      padding: 10px;
      font-size: 16px;
      &:focus {
        outline: none;
        border-bottom-color: #ba1e67;
      }
      &::placeholder {
        text-transform: none;
      }
      &.notValid {
        border-bottom-color: color(primary);
      }
    }
    .button-container {
      gap: 20px;
      margin-top: 20px;
      button,
      a {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button.login {
        background-color: #ba1e67;
        color: #fff;
        border: none;
      }
      a.sign-up,
      button.cancel {
        background-color: #fff;
        border: 1px solid #43425d;
      }
    }
    .forget {
      background-color: transparent;
      border: none;
    }
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 214px);
    row-gap: 20px;
    column-gap: 20px;
    padding: 0 20px;
    justify-content: center;
    .card {
      width: 214px;
      height: 188px;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0px 3px 6px #272d3b33;
      color: #43425d;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      flex-direction: column;
      cursor: pointer;
      p {
        font-weight: bold;
        color: #272d3b;
      }
    }
    .sub-card {
      width: 209px;
      height: 48px;
      box-shadow: 0px 3px 6px #272d3b33;
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }
}

@media (min-width: 1600px) {
  .main-container {
    .card-container {
      row-gap: 57px;
      column-gap: 45px;
    }
  }
}

@media (max-width: 1450px) {
  .main-container {
    & > div:first-child {
      width: 40%;
    }
    & > div:last-child {
      width: 60%;
    }
  }
}

@media (max-width: 1200px) {
  .main-container {
    .card-container {
      grid-template-columns: repeat(2, 214px);
    }
    .quest {
      text-align: center;
    }
  }
  .main-container {
    & > div:first-child {
      width: 50%;
    }
    & > div:last-child {
      width: 50%;
    }
  }
}

@media (max-width: 992px) {
  .main-container {
    .card-container {
      grid-template-columns: repeat(2, 141px);
      .card {
        width: 141px;
        height: 124px;
        font-size: 14px;
      }
      .sub-card {
        width: 141px;
        height: 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: unset;
    form {
      width: 100%;
      p {
        text-align: center;
      }
      a {
        padding: 10px 0;
      }
    }
    & > div:first-child {
      width: 100%;
      height: auto !important;
      padding-top: 60px;
      padding-bottom: 20px;
      .forget {
        align-self: center !important;
      }
      .button-container {
        flex-direction: column;
        gap: 15px;
        button {
          width: 100%;
          height: auto !important;
          padding: 10px 0;
          flex-shrink: 0;
        }
      }
    }
    & > div:last-child {
      width: 100%;
      height: auto !important;
      padding: 20px;
      & > div {
        width: 100% !important;
      }
    }
  }
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      spinner: false,
      loginAlertFail: false,
      forgetAlertFail: false,
      files: [],
      email: false,
      mailText: "",
      password: false,
      passwordText: "",
      activeForm: 0,
      showSailing: false,
      isSendForget: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    getFiles() {
      let arr = ["company_profile", "import_sailing", "export_sailing"];
      for (let i of arr) {
        this.axios
          .get(`/api/AppAdmin/settings/ServerFile?filename=${i}`)
          .then((res) => {
            this.files.push(res.data.data);
            if (res.data.data.fileLink.includes("company_profile")) {
              this.files[0] = res.data.data;
            } else if (res.data.data.fileLink.includes("import_sailing")) {
              this.files[1] = res.data.data;
            } else if (res.data.data.fileLink.includes("export_sailing")) {
              this.files[2] = res.data.data;
            }
          });
      }
    },
    mail() {
      if (!!!this.form.email) {
        this.email = true;
        this.mailText = "Email or Username not valid";
      } else {
        this.email = false;
        this.mailText = "";
      }
    },
    pass() {
      if (this.form.password.length < 8) {
        this.password = true;
        this.passwordText = "Password must be at least 8 characters!";
      } else {
        this.password = false;
        this.passwordText = "";
      }
    },
    sendLogin() {
      this.mail();
      this.pass();
      if (!this.email && !this.password) {
        console.log(" submit login : ", this.form.email, this.form.password);
        this.login();
      }
    },
    login() {
      this.spinner = true;
      this.loginAlertFail = false;
      this.$store
        .dispatch("login", {
          username: this.form.email,
          password: this.form.password,
        })
        .then((res) => {
          setTimeout(() => {
            localStorage.setItem("userObject", JSON.stringify(res.data));
            if (res.data.roles[0] == "AppAdmin") {
              this.$router.push({ name: "AdminDashboard" });
            } else {
              this.$router.push({ name: "ClientDashboard" });
            }
          }, 2000);
        })
        .catch((err) => {
          if (err) {
            this.loginAlertFail = true;
            this.spinner = false;
          }
        });
    },
    sendForget() {
      this.mail();
      if (!this.email) {
        console.log(" submit forget : ", this.form.email);
        this.forget();
      }
    },
    forget() {
      this.spinner = true;
      this.forgetAlertFail = false;
      this.axios
        .post(`api/Accounts/ForgetPassword?email=${this.form.email}`)
        .then(() => {
          this.spinner = false;
          this.activeForm = 2;
        })
        .catch(() => {
          this.spinner = false;
          this.forgetAlertFail = true;
        });
    },
  },
  mounted() {
    this.getFiles();
  },
};
</script>
