<template>
  <div class="penDetails">
    <div class="penDetails__wrapper animate__animated animate__fadeInUp">
      <div v-if="loadData">
        <div
          class="form-group load"
          style="height: 45px"
          v-for="(v, i) in new Array(10)"
          :key="i"
        ></div>
      </div>

      <div v-if="!loadData">
          <!--User Data Start-->
          <h2 class="text-center font-weight-bold text-pink">
              User Details
          </h2>
          <div class="form-group">
              <label>User First Name</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.firstName" />
          </div>

          <div class="form-group">
              <label>User Last Name</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.lastName" />
          </div>

          <div class="form-group">
              <label>Position</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.position" />
          </div>

          <div class="form-group">
              <label>User Email</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.requestEmail" />
          </div>

          <div class="form-group">
              <label>User Phone</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.phoneNumber" />
          </div>

          <div class="form-check">
              <input type="checkbox"
                     class="form-check-input penDetails__field"
                     v-model="details.isAdmin" />
              <label>Admin</label>
          </div>
          <!--User Data End-->
          <!--company data starts -->
          <h2 class="text-center font-weight-bold text-pink">
              Company Details
          </h2>
          <div class="form-group">
              <label>company name</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.companyName" />
          </div>

          <div class="form-group">
              <label>Company Website</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.companyWebsite" />
          </div>

          <div class="form-group">
              <label>Company Phone</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.companyPhone" />
          </div>

          <div class="form-group">
              <label>City</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.city" />
          </div>

          <div class="form-group">
              <label>Address</label>
              <input type="text"
                     class="form-control penDetails__field"
                     v-model="details.address" />
          </div>
          <!--company data end -->
          <!-- choose company   -->
          <label>Tracking Companies</label>
          <div class="form-group">
              <multiselect v-model="companyEntities" :options="Companies" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Companies" label="companyTitle" track-by="companyID" :preselect-first="true">
              </multiselect>
          </div>
          <div class="form-group">
              <span class="text-primary">Main Company : </span>
              <span class="badge bg-success text-white p-2">{{selectionResults}}</span>
          </div>
          <div class="form-group">
              <span class="text-primary">Related Entities : </span>
              <span class="badge bg-dark text-white m-xl-1 p-2" v-for="entity in displayedEntities">{{entity.companyTitle}}</span>
          </div>

          <!--btns -->
          <div class="form-group text-right">
              <button class="btn btn-s mr-1" @click="$emit('close')">Close</button>
              <button class="btn btn-p penDetails__btn"
                      :disabled="!mainCompany|| spinner"
                      @click="saveUser()">
                  <div class="spinner-border text-secondary d-inline-block"
                       role="status"
                       style="width: 1rem; height: 1rem"
                       v-if="spinner">
                      <span class="sr-only">Loading...</span>
                  </div>
                  Save
              </button>
          </div>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
    .text-size-20{
        font-size:20px;
    }
    .text-pink {
        color: #e40464;
    }
.penDetails {
  &__btn:disabled {
    background-color:#333;
  }
  &__wrapper {
    background: color(white);
    padding: 20px;
    @include radius(10px);
    @include shadow(cardShadow);
  }
  &__field {
    background-color: color(body) !important;
    &:disabled {
      background: color(gray7) !important;
    }
  }
}
</style>

<script>
    import Vue from 'vue';
    import Multiselect from 'vue-multiselect'
    Vue.component('multiselect', Multiselect)
    export default {
        props: ["id"],
        name: "pendingDetails",

        data() {
            return {
                myValue: "",
                details: {},
                spinner: false,
                loadData: true,
                Companies: [],
                mainCompany: {},
                companyEntities: [],
                displayedEntities:[],
                results:''
            };
        },
        component: {
            Multiselect
        },
        methods: {
            saveUser() {
                if (this.mainCompany) {
                    this.details.companyID = this.mainCompany.companyID;
                    this.details.entities = this.displayedEntities.map(v => v.companyID);
                    this.spinner = true;
                    this.axios
                        .post("/api/AppAdmin/PendingUsers/AcceptAccount", this.details, {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.token}`,
                            },
                        })
                        .then((res) => {
                            this.$emit("close");
                            this.$emit("updateUser");
                        })
                        .catch((res) => {

                        });
                }
            },
        },
        mounted() {
            // Get single user
            this.axios
                .get(`api/AppAdmin/PendingUsers/${this.id}`, {
                    headers: { Authorization: `Bearer ${this.$store.state.token}` },
                })
                .then((res) => {
                    this.details = res.data.data;
                })
                .then(() => (this.loadData = false));

            // Get all compaines
            this.axios
                .get("api/AppAdmin/Companies", {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`,
                    },
                })
                .then((res) => {
                    this.Companies = res.data.data;
                });
        },
        computed: {
            selectionResults() {
                this.mainCompany = this.companyEntities[0];
                this.displayedEntities = this.companyEntities.filter(i => i.companyID != this.mainCompany.companyID);
                return this.mainCompany ? this.mainCompany.companyTitle : 'Not Selected';
            }
        }
    };
</script>
