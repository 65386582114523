<template>
  <div id="app" class="position-relative">
    <Header v-if="shouldRenderHeader"></Header>
    <Aside v-if="shouldRenderAside"></Aside>

    <div class="loadApp text-center" v-if="$store.state.loading">
      <div id="container">
        <svg viewBox="0 0 100 100">
          <defs>
            <filter id="shadow">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="1.5"
                flood-color="#fc6767"
              />
            </filter>
          </defs>
          <circle
            id="spinner"
            style="
              fill: transparent;
              stroke: #dd2476;
              stroke-width: 7px;
              stroke-linecap: round;
              filter: url(#shadow);
            "
            cx="50"
            cy="50"
            r="45"
          />
        </svg>
      </div>
    </div>

    <div
      @click="
        ($store.state.asideOpen = !$store.state.asideOpen),
          ($store.state.blured = !$store.state.blured)
      "
      v-if="$store.state.blured"
      class="overLayer position-absolute"
    ></div>
    <!-- <NotificationsPopUp ref="notificationpopup" ></NotificationsPopUp> -->
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-weight: 400;
}
.overLayer {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: transparent;
}
.loadApp {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1000;
  text-align: center;
  line-height: calc(100vh);

  > div {
    width: 100px;
    line-height: 0;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #container {
    width: 200px;
    height: 200px;
  }

  @keyframes animation {
    0% {
      stroke-dasharray: 1 98;
      stroke-dashoffset: -105;
    }
    50% {
      stroke-dasharray: 80 10;
      stroke-dashoffset: -160;
    }
    100% {
      stroke-dasharray: 1 98;
      stroke-dashoffset: -300;
    }
  }

  #spinner {
    transform-origin: center;
    animation-name: animation;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier;
    animation-iteration-count: infinite;
  }
}
@include keyframes(opa) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>
import Header from "@/components/Header.vue";
import Aside from "@/components/Aside.vue";
import NotificationsPopUp from "@/components/NotificationPopUp.vue";

export default {
  name: "App",
  computed: {
    shouldRenderHeader() {
      // Determine if Header should be rendered based on route name
      return !["Home", "SignUp"].includes(this.$route.name);
    },
    shouldRenderAside() {
      // Determine if Aside should be rendered based on route name
      return !["Home", "SignUp"].includes(this.$route.name);
    },
  },
  data() {
    return {
      fixed: false,
      rou: "",
      showCon: false,
      active: false,
    };
  },
  methods: {},
  components: {
    Header,
    Aside,
    NotificationsPopUp,
    // Footer,
  },
  created() {
    setTimeout(() => {
      this.$store.state.loading = false;
    }, 2000);
  },
};
</script>
