<template>
  <div class="vw-100 vh-100 main-container position-relative">
    <div
      class="w-50 h-100 d-flex align-items-center justify-content-center bg-white bg-effect"
    ></div>
    <div
      class="w-50 h-100 d-flex align-items-center justify-content-center bg-effect"
    ></div>
    <form v-if="!signupSuccess" @submit.prevent="send" class="signup-container">
      <div
        class="custom-alert"
        id="alert-pc-screens"
        v-if="error && !spinner"
        style="
          font-size: 16px;
          margin-bottom: -30px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-direction: row;
        "
      >
        {{ error }}
        <span style="height: 20px"
          ><i class="fas fa-times text-danger"></i
        ></span>
      </div>
      <div style="max-width: 1250px" class="form-container">
        <router-link to="/">
          <img src="../assets/imgs/logo.svg" width="164" />
        </router-link>
        <div
          class="custom-alert"
          id="alert-mob-tab-screens"
          v-if="error && !spinner"
          style="
            font-size: 16px;
            display: none;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-direction: row;
          "
        >
          {{ error }}
          <span style="height: 20px"
            ><i class="fas fa-times text-danger"></i
          ></span>
        </div>
        <div>
          <div style="row-gap: 20px" class="row">
            <div class="col-md">
              <input
                type="text"
                placeholder="First Name"
                class="w-100"
                v-model="form.firstName"
                @blur="firstNameValidate()"
                :class="{ notValid: firstName }"
              />
              <div style="white-space: nowrap" class="text-p valid">
                {{ firstNameText }}
              </div>
            </div>
            <div class="col-md">
              <input
                type="text"
                placeholder="Last Name"
                class="w-100"
                v-model="form.lastName"
                @blur="lastNameValidate()"
                :class="{ notValid: lastName }"
              />
              <div style="white-space: nowrap" class="text-p valid">
                {{ lastNameText }}
              </div>
            </div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Position"
              class="w-100"
              v-model="form.position"
              @blur="position()"
              :class="{ notValid: pos }"
            />
            <div class="text-p valid">{{ posText }}</div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Your Email"
              class="w-100"
              v-model="form.email"
              @blur="mail()"
              :class="{ notValid: email }"
            />
            <div class="text-p valid">{{ mailText }}</div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Your Phone"
              class="w-100"
              v-model="form.phoneNumber"
              @blur="mobileNum()"
              :class="{ notValid: mobNum }"
            />
            <div class="text-p valid">{{ mobText }}</div>
          </div>
        </div>
        <div>
          <div>
            <input
              type="text"
              placeholder="Company Name"
              class="w-100"
              v-model="form.companyName"
              @blur="companyName()"
              :class="{ notValid: cName }"
            />
            <div class="text-p valid">{{ cNameText }}</div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Company Number"
              class="w-100"
              v-model="form.companyPhone"
              @blur="comPhone()"
              :class="{ notValid: cPhone }"
            />
            <div class="text-p valid">{{ cPhoneText }}</div>
          </div>
          <div style="row-gap: 20px" class="row">
            <div class="col-md-4">
              <input
                type="text"
                placeholder="Company City"
                class="w-100"
                v-model="form.city"
                @blur="citys()"
                :class="{ notValid: city }"
              />
              <div style="white-space: nowrap" class="text-p valid">
                {{ cityText }}
              </div>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                placeholder="Company Address"
                class="w-100"
                v-model="form.address"
                @blur="addressValidate()"
                :class="{ notValid: address }"
              />
              <div class="text-p valid">{{ addressText }}</div>
            </div>
          </div>
          <div>
            <input
              type="text"
              placeholder="Company Website"
              class="w-100"
              v-model="form.companyWebsite"
              @blur="webSite()"
              :class="{ notValid: web }"
            />
            <div class="text-p valid">{{ webText }}</div>
          </div>
        </div>
      </div>
      <div style="max-width: 1250px" class="button-container">
        <router-link to="/" class="cancel btn" :disabled="spinner"
          >Cancel</router-link
        >
        <button type="submit" class="confirm btn" :disabled="spinner">
          <span v-if="!spinner">Confirm</span>
          <div
            class="spinner-border text-secondary d-inline-block"
            role="status"
            style="width: 1rem; height: 1rem"
            v-if="spinner"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </form>
    <div
      v-else
      class="signup-container success-signup"
      style="
        flex-direction: row;
        align-items: center;
        max-width: 1250px;
        flex-wrap: wrap;
        align-content: center;
      "
    >
      <img src="../assets/imgs/Check Mark -colored.svg" width="100" />
      <h1 style="max-width: 160px; font-size: 25px; color: #707070">
        We'll review your info
      </h1>
      <p
        style="
          max-width: 412px;
          font-size: 20px;
          color: #707070;
          text-wrap: balance;
        "
      >
        if we can confirm it, you'll get a confirmation email with your password
        to access your account within approximately 48 hours, stay tuned!
      </p>
      <router-link to="/" class="confirm">Confirm</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  button,
  a {
    width: 185px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button.confirm,
  a.confirm {
    background-color: #ba1e67;
    color: #fff;
    border: none;
  }
  a.cancel {
    background-color: #fff;
    border: 1px solid #43425d;
  }
  .signup-container {
    position: absolute;
    background-color: white;
    height: 600px;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
    }
    & .button-container {
      display: flex;
      justify-content: end;
      gap: 20px;
    }
    & > div > div {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 400px;
    }
    input {
      width: 100%;
      border: none;
      border-bottom: 2px solid #e9e9f0;
      transition: border-bottom-color 200ms;
      padding: 10px;
      font-size: 16px;
      &:focus {
        outline: none;
        border-bottom-color: #ba1e67;
      }
      &::placeholder {
        text-transform: none;
      }
      &.notValid {
        border-bottom-color: color(primary);
      }
    }
  }
}

@media (min-width: 1600px) {
  .main-container {
    .signup-container {
      width: 80%;
    }
  }
}

@media (max-width: 1450px) {
  .main-container {
    .signup-container {
      width: 90%;
      padding: 0 60px;
    }
  }
}

@media (max-width: 1200px) {
  .main-container {
    .signup-container {
      width: 80%;
      min-width: 950px;
      padding: 0 50px;
      #alert-mob-tab-screens {
        display: flex !important;
        margin: -20px 0;
        width: 100%;
      }
      #alert-pc-screens {
        display: none !important;
      }
      .form-container {
        a {
          width: 100%;
        }
        flex-wrap: wrap;
      }
    }
  }
}
@media (max-width: 992px) {
  .main-container {
    .signup-container {
      height: auto;
      min-width: auto;
      padding: 50px !important;
      width: 80%;
      padding: 0 50px;
      #alert-mob-tab-screens {
        margin: 0;
      }
      .form-container {
        flex-direction: column;
        gap: 20px;
      }
      .button-container {
        justify-content: center;
        button,
        a {
          width: 100%;
        }
      }
      & > div > div {
        width: 100%;
      }
      &.success-signup {
        flex-direction: column !important;
        gap: 20px;
        p {
          text-align: center;
        }
        h1 {
          max-width: 100% !important;
          font-weight: bold;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .main-container {
    height: auto !important;
    width: auto !important;

    .bg-effect {
      display: none !important;
    }
    .signup-container {
      min-height: 100svh;
      position: relative !important;
      height: auto;
      min-width: auto;
      width: 100% !important;
      padding: 30px 16px !important;
      width: 100% !important;
      padding: 0 50px;
      gap: 30px;
      & > div > div {
        width: 100%;
      }

      .form-container {
        flex-direction: column;
        gap: 20px;
      }
      .button-container {
        justify-content: center;
      }
    }
  }
}
</style>
<script>
export default {
  name: "SignUp",
  data() {
    return {
      spinner: false,
      error: false,
      signupSuccess: false,
      firstName: false,
      firstNameText: "",
      lastName: false,
      lastNameText: "",
      pos: false,
      posText: "",
      email: false,
      mailText: "",
      regMail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      mobNum: false,
      mobText: "",
      cName: false,
      cNameText: "",
      cPhone: false,
      cPhoneText: "",
      city: false,
      cityText: "",
      address: false,
      addressText: "",
      web: false,
      webText: "",
      form: {
        firstName: "",
        lastName: "",
        position: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        companyWebsite: "",
        companyPhone: "",
        city: "",
        address: "",
      },
    };
  },
  methods: {
    firstNameValidate() {
      if (this.form.firstName.length < 3) {
        this.firstName = true;
        this.firstNameText = "Should be 3 letters at least";
      } else {
        this.firstName = false;
        this.firstNameText = "";
      }
    },
    lastNameValidate() {
      if (this.form.lastName.length < 3) {
        this.lastName = true;
        this.lastNameText = "Should be 3 letters at least";
      } else {
        this.lastName = false;
        this.lastNameText = "";
      }
    },
    companyName() {
      if (this.form.companyName.length < 4) {
        this.cName = true;
        this.cNameText = "Should be 4 letters at least";
      } else {
        this.cName = false;
        this.cNameText = "";
      }
    },
    position() {
      if (this.form.position.length < 3) {
        this.pos = true;
        this.posText = "Should be 3 letters at least";
      } else {
        this.pos = false;
        this.posText = "";
      }
    },
    mail() {
      if (!this.regMail.test(this.form.email)) {
        this.email = true;
        this.mailText = "Email not vaild !";
      } else {
        this.email = false;
        this.mailText = "";
      }
    },
    webSite() {
      if (this.form.companyWebsite.length < 5) {
        this.web = true;
        this.webText = "Should be 5 letters at least";
      } else {
        this.web = false;
        this.webText = "";
      }
    },
    comPhone() {
      if (this.form.companyPhone.length < 7) {
        this.cPhone = true;
        this.cPhoneText = "Phone Not valid";
      } else {
        this.cPhone = false;
        this.cPhoneText = "";
      }
    },
    mobileNum() {
      if (this.form.phoneNumber.length < 7) {
        this.mobNum = true;
        this.mobText = "Phone Not valid";
      } else {
        this.mobNum = false;
        this.mobText = "";
      }
    },
    citys() {
      if (this.form.city == "") {
        this.city = true;
        this.cityText = "Can't be empty";
      } else {
        this.city = false;
        this.cityText = "";
      }
    },
    addressValidate() {
      if (this.form.address == "") {
        this.address = true;
        this.addressText = "Can't be empty";
      } else {
        this.address = false;
        this.addressText = "";
      }
    },
    send() {
      this.firstNameValidate();
      this.lastNameValidate();
      this.companyName();
      this.position();
      this.mail();
      this.webSite();
      this.comPhone();
      this.mobileNum();
      this.citys();
      this.addressValidate();
      if (
        !this.firstName &&
        !this.lastName &&
        !this.pos &&
        !this.email &&
        !this.mobNum &&
        !this.cName &&
        !this.cPhone &&
        !this.city &&
        !this.address &&
        !this.web
      ) {
        console.log(" submit signup : ", this.form);
        this.spinner = true;
        this.error = false;
        this.axios
          .post("api/Accounts/SignUp", this.form)
          .then((res) => {
            if (res.status == 200) {
              this.signupSuccess = true;
              this.error = false;
              for (let i in this.form) {
                this.form[i] = "";
              }
            }
          })
          .catch((err) => {
            this.spinner = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (err?.response?.data?.message == "this email is already taken") {
              this.error = "This email is already taken";
            } else {
              this.error = "Server error";
            }
          });
      }
    },
  },
};
</script>
